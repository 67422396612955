.ui-widget-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(9, 23, 37, 0.8);
}

.ui-dialog {
	padding: 0;
    background-color: rgba(9, 23, 37, 0.8);
    z-index: 9999;
    outline: none;
    border-radius: 9px;
    border: 1px solid #0395b9;
    overflow: hidden;
    padding: 10px 20px;
    color: @active-blue;

	.ui-dialog-buttonpane {
		text-align: center;
	}

	.ui-dialog-buttonset {
		float: none;
		display: inline-block;
	}

	.ui-dialog-title {
		color: currentcolor;
	}

	.ui-dialog-titlebar  {
		cursor: move;
		text-transform: uppercase;
		position: relative;
		margin-bottom: 20px;
		margin-top: 10px;

		.ui-button {
			width: 26px;
			height: 26px;
			position: absolute;
			right: 0;
			top: 50%;
			margin-top: -13px;
			margin-right: -2px;
			font-size: 0;

			span {
				display: none;
			}

			&:after, &:before {
				position: absolute;
				top: 50%;
				height: 2px;
				width: 100%;
				background-color: currentcolor;
				left: 0;
				content: '';
				display: block;
				transition: transform .15s ease;
			}

			&:after {
				transform: rotate(-45deg);
			}
			&:before {
				transform: rotate(45deg);
			}

			&:hover {
				&:after {
					transform: rotate(-35deg);
				}
				&:before {
					transform: rotate(35deg);
				}
			}
		}
	}

	.ui-dialog-content {
		border-top: 1px solid #06607d;
		min-height: 60px !important;
		padding: 20px 0;

		* {
			color: #fff;
			font-size: 15px;
			.f-avn-m;
			word-break: break-word;
		}
	}

	.ui-dialog-buttonpane {
		margin-top: 3px;
		margin-bottom: 8px; 

		.ui-dialog-buttonset {
			width: 100%;
		}

		.ui-button {
			border: 1px solid #22345c;
			background-color: #1b2949;
			padding: 15px 0;
			border-radius: 0;
			width: 47%;
			transition: background-color .25s ease;
			border-radius: 2px;
			color: #fff;

			&:first-child {
				float: left;
			}

			&:last-child {
				float: right;
			}

			&:hover {
				background-color: @active-blue;
			}
		}

		.ui-button-text {
			color: #fff;
			font-size: 16px;
		}
	}

	&.error-dialog {
		color: @red;

		.ui-dialog-titlebar .ui-button {
			span, &:after, &:before {
				display: none;
			}

			&:before {
				display: block;
				width: 100%;
				height: 100%;
				border: 2px solid currentcolor;
				border-radius: 50%;
				content: '!';
				top: 0;
				background-color: transparent;
				transform: none;
				font-size: 20px;
				line-height: 23px;
			}
		}
	}
}

.lightbox {
    border: 1px solid #0395b9;
    border-radius: 9px;
    overflow: hidden;
    padding: 0 20px;
	min-width: 350px;
	max-width: 90%;
    color: @active-blue;
    background-color: rgba(9,23,37,.8);
    position: relative;
    display: inline-block;
    text-align: left;

    &.error-dialog {
    	color: @red;
    	border-color: currentcolor;

    	// .ui-dialog-titlebar .ui-button {
    	// 	span, &:after, &:before {
    	// 		display: none;
    	// 	}
    }

    &__wrapper {
    	position: relative;
    	display: table-cell;
    	vertical-align: middle;
    	text-align: center;
    }

    &__main-wrapper {
    	position: absolute;
    	top: 0;
    	left: 0;
    	display: table;
	    z-index: 999;
	    height: 100%;
	    width: 100%;
		font-size: 0;
    }

	&__overlay {
		z-index: 998;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(9, 23, 37, 0.8);
	}

	&__header {
		border-bottom: 1px solid #06607d;
		padding: 15px 0;
	}

	&__close {
		position: absolute;
		width: 26px;
		height: 26px;
		right: 0;
		top: 50%;
		margin-top: -13px;

		&:after, &:before {
			content: '';
			width: 100%;
			height: 2px;
			background-color: currentcolor;
			position: absolute;
			top: 50%;
			left: 0;
			transform-origin: center center;
			transition: transform .15s ease;
		}

		&:after {transform: rotate(-45deg);}
		&:before {transform: rotate(45deg);}

		&:hover {
			&:before {
				animation: flip .2s @easing;
			}
			&:after {
				animation: backFlip .2s @easing;
			}	
		}
	}

	&__title {
		color: currentcolor;
		text-transform: uppercase;
		font-size: 15px;
		padding-right: 30px;
	}

	&__text-wrapper {
		padding: 20px 0;
	}

	&__text {
		color: #fff;
		font-size: 15px;
		font-family: 'Avenir Next';
		font-weight: 500;
		word-break: break-word;
	}

	&__buttons {
		padding-bottom: 15px;
		margin: 0 -10px;
	}

	&__button {
		border: 1px solid #22345c;
		background-color: #1b2949;
		padding: 15px 0;
		transition: background-color .25s ease;
		border-radius: 2px;
		color: #fff;
		font-size: 15px;
		text-align: center;
		width: 100%;

		&-wrapper {
			width: 50%;
			padding: 0 10px;
			display: inline-block;

			&:first-child:last-child {
				width: 100%;
			}
		}

		&:hover {
			background-color: @active-blue;
		}

		&.is-disabled {
			cursor: default;
			opacity: 0.5;
			pointer-events: none;
		}

	}

	&__actions {
		display: flex;
		justify-content: space-around;
	}
}

.lightbox--fixed {
	.lightbox__overlay, .lightbox__main-wrapper {
		position: fixed;
	}

	.lightbox__title {
		line-height: 1;
	}

	.lightbox__field {
		font-size: 15px;
		line-height: 1.5;
		padding: 5px;
		width: 100%;
		background-color: transparent;
		box-shadow: 0 0 5px 1px #06607d;
		color: #fff;
	}
}


// Lightbox select
.lightbox {
	&__list {
		margin: 10px 0;
		padding-right: 10px;
		margin-right: -10px;
		min-width: 100px;
	}

	&__list-item {
		font-size: 15px;
		padding: 5px 10px;
		border-radius: 4px;
		background-color: #06607d;
		color: #fff;
		margin: 5px 0;
		cursor: pointer;
		transition: box-shadow @duration @easing;

		&:hover {
			box-shadow: 0 0 3px 1px #06607d;
		}

		&.is-active {
			background-color: @active-blue;
		}
	}
}