@import "../../assets/variables.scss";

// variables
$xs-base-padding: $base-padding/3;

//inhertance
%flex-column-centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

//scss
.log {
  height: 100vh;
  width: 100vw;
  @extend %flex-column-centered;
  justify-content: space-around;

  .div {
    @extend %flex-column-centered;

    .img {
      width: 75px;
      height: auto;
      margin-bottom: $base-padding;
    }
    .h1 {
      @extend %heading;
      margin-bottom: $base-padding;
      font-family: $font-headings;
    }
    .h2 {
      font-size: 10pt;
      font-family: $font-headings;
    }
    .form {
      margin-top: $base-padding;
      border: none;
      @extend %flex-column-centered;

      .label {
        margin-top: $xs-base-padding;
      }
      .input {
        margin-bottom: $base-padding;
        color: $secondary-text-color;
        height: 2em;
        @extend %focus-light;
      }
    }
    .div {
      margin-top: $base-padding;
      height: 40px;
    }
    p {
      font-size: 10pt;
      margin-top: $base-padding;
      color: $primary-text-color;
    }
  }
  .button {
    margin: 0px;
    height: 40px;
    width: 150px;
    border-color: $primary-color-5;
    color: $primary-text-color;
    background-color: transparent;
    border-radius: $border-radius-button;
    border-width: $border-radius-weight;

    &:hover {
      cursor: pointer;
      background-color: $primary-color-5;
      color: white;
    }
    &:active {
      background-color: $primary-color-4;
      min-width: 150px;
      height: 40px;
    }
    @extend %focus-light;
  }
}
