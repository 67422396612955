@import "../../assets/variables.scss";

// variables
$xs-base-padding: $base-padding/3;

//inhertance
%flex-column-centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

//scss
.log {
  // * {
  //   margin: 0;
  //   padding: 0;
  //   box-sizing: border-box;
  //   font-size: 12pt;
  //   font-family: "arial";
  //   color: $primary-text-color;
  // }

  height: 100vh;
  width: 100vw;
  @extend %flex-column-centered;
  justify-content: space-around;

  .div {
    @extend %flex-column-centered;

    .img {
      width: 75px;
      height: auto;
      margin-bottom: $base-padding;
    }
    .h1 {
      @extend %heading;
      margin-bottom: $base-padding;
      font-family: $font-headings;
    }
    .h2 {
      font-size: 10pt;
      font-family: $font-headings;
    }
    .form {
      margin-top: $base-padding;
      border: none;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      section {
        @extend %flex-column-centered;
      }

      .label {
        margin-top: $xs-base-padding;
      }
    }
    .div {
      margin-top: $base-padding;
      height: 40px;
    }
    p {
      font-size: 10pt;
      margin-top: $base-padding;
      color: white;
    }
  }
}
