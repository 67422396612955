.progressCounter {
  font-size: 10pt;
  margin-top: 5px;
  margin-left: 10px;
  padding-top: 10px;
}

@media only screen and (min-width: 1000px) {
  .progressCounter {
    font-size: 20pt;
  }
}
