// @import (less) 'lib/// .css';
// @import (inline) 'lib/video-js.min.css';
@import (less) 'lib/fontello.css';
@import (less) 'lib/tooltipster.css';
@import 'spare/_font.less';

h1, h2, h3, h4, h5, h6, p, figure {
	margin: 0;
	font-weight: 400;
}

ul, ol {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

img {
	font-size: 0;
}

button, select, [type=submit], [type=checkbox], [type=color], [type=date], [type=datetime-local], [type=datetime], [type=email], [type=month], [type=number], [type=password], [type=search], [type=tel], [type=text], [type=time], [type=url], [type=week], textarea {
	outline: none;
	border: 0;
	border-radius: 0;
	box-shadow: none;
	margin: 0;
	width: auto;
}

a {text-decoration: none;}

html {font-size: 16px;}
body { 
	font-family: 'Avenir Next';
	color: #fff;
	// overflow: hidden;
}

html, body {
	position: relative;
	height: 100%;
}

section[role="main"] {
	max-width: 100%;
	overflow: hidden;
	font-size: 0;
}

.page-bg {
	position: fixed;
	z-index: 0;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 100%;
	background-size: cover;
}

.page-wrapper {
	position: fixed;
	z-index: 10;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
}

.svg-sprite {
	height: 0;
	width: 0;
	position: absolute;
	left: 0;
	top: 0;
	z-index: -1;
}

.app-main {
	float: left;
	padding: 30px 40px;
	padding-top: 80px;
}


#space-bg canvas {
	position: absolute;
	top: 0;
	left: 0;
}

.content-wrapper {
	background-color: hsla(0, 0%, 100%, 0.001);
}

.page-bg {
	background-image: url('../images/main-bg.jpg');
}
