@import 'theme/perfect-scrollbar.css';
@font-face {
  font-family: 'fontello';
  src: url('../fonts/fontello.eot?95139539');
  src: url('../fonts/fontello.eot?95139539#iefix') format('embedded-opentype'), url('../fonts/fontello.woff?95139539') format('woff'), url('../fonts/fontello.ttf?95139539') format('truetype'), url('../fonts/fontello.svg?95139539#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?95139539#fontello') format('svg');
  }
}
*/
[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
.icon-twitter:before {
  content: '\e801';
}
/* '' */
.icon-linkedin:before {
  content: '\e802';
}
/* '' */
/* This is the default Tooltipster theme (feel free to modify or duplicate and create multiple themes!): */
.tooltipster-default {
  border-radius: 5px;
  border: 2px solid #000;
  background: #4c4c4c;
  color: #fff;
}
/* Use this next selector to style things like font-size and line-height: */
.tooltipster-default .tooltipster-content {
  font-family: Arial, sans-serif;
  font-size: 14px;
  line-height: 16px;
  padding: 8px 10px;
  overflow: hidden;
}
/* This next selector defines the color of the border on the outside of the arrow. This will automatically match the color and size of the border set on the main tooltip styles. Set display: none; if you would like a border around the tooltip but no border around the arrow */
.tooltipster-default .tooltipster-arrow .tooltipster-arrow-border {
  /* border-color: ... !important; */
}
/* If you're using the icon option, use this next selector to style them */
.tooltipster-icon {
  cursor: help;
  margin-left: 4px;
}
/* This is the base styling required to make all Tooltipsters work */
.tooltipster-base {
  padding: 0;
  font-size: 0;
  line-height: 0;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9999999;
  pointer-events: none;
  width: auto;
  overflow: visible;
}
.tooltipster-base .tooltipster-content {
  overflow: hidden;
}
/* These next classes handle the styles for the little arrow attached to the tooltip. By default, the arrow will inherit the same colors and border as what is set on the main tooltip itself. */
.tooltipster-arrow {
  display: block;
  text-align: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.tooltipster-arrow span,
.tooltipster-arrow-border {
  display: block;
  width: 0;
  height: 0;
  position: absolute;
}
.tooltipster-arrow-top span,
.tooltipster-arrow-top-right span,
.tooltipster-arrow-top-left span {
  border-left: 8px solid transparent !important;
  border-right: 8px solid transparent !important;
  border-top: 8px solid;
  bottom: -7px;
}
.tooltipster-arrow-top .tooltipster-arrow-border,
.tooltipster-arrow-top-right .tooltipster-arrow-border,
.tooltipster-arrow-top-left .tooltipster-arrow-border {
  border-left: 9px solid transparent !important;
  border-right: 9px solid transparent !important;
  border-top: 9px solid;
  bottom: -7px;
}
.tooltipster-arrow-bottom span,
.tooltipster-arrow-bottom-right span,
.tooltipster-arrow-bottom-left span {
  border-left: 8px solid transparent !important;
  border-right: 8px solid transparent !important;
  border-bottom: 8px solid;
  top: -7px;
}
.tooltipster-arrow-bottom .tooltipster-arrow-border,
.tooltipster-arrow-bottom-right .tooltipster-arrow-border,
.tooltipster-arrow-bottom-left .tooltipster-arrow-border {
  border-left: 9px solid transparent !important;
  border-right: 9px solid transparent !important;
  border-bottom: 9px solid;
  top: -7px;
}
.tooltipster-arrow-top span,
.tooltipster-arrow-top .tooltipster-arrow-border,
.tooltipster-arrow-bottom span,
.tooltipster-arrow-bottom .tooltipster-arrow-border {
  left: 0;
  right: 0;
  margin: 0 auto;
}
.tooltipster-arrow-top-left span,
.tooltipster-arrow-bottom-left span {
  left: 6px;
}
.tooltipster-arrow-top-left .tooltipster-arrow-border,
.tooltipster-arrow-bottom-left .tooltipster-arrow-border {
  left: 5px;
}
.tooltipster-arrow-top-right span,
.tooltipster-arrow-bottom-right span {
  right: 6px;
}
.tooltipster-arrow-top-right .tooltipster-arrow-border,
.tooltipster-arrow-bottom-right .tooltipster-arrow-border {
  right: 5px;
}
.tooltipster-arrow-left span,
.tooltipster-arrow-left .tooltipster-arrow-border {
  border-top: 8px solid transparent !important;
  border-bottom: 8px solid transparent !important;
  border-left: 8px solid;
  top: 50%;
  margin-top: -7px;
  right: -7px;
}
.tooltipster-arrow-left .tooltipster-arrow-border {
  border-top: 9px solid transparent !important;
  border-bottom: 9px solid transparent !important;
  border-left: 9px solid;
  margin-top: -8px;
}
.tooltipster-arrow-right span,
.tooltipster-arrow-right .tooltipster-arrow-border {
  border-top: 8px solid transparent !important;
  border-bottom: 8px solid transparent !important;
  border-right: 8px solid;
  top: 50%;
  margin-top: -7px;
  left: -7px;
}
.tooltipster-arrow-right .tooltipster-arrow-border {
  border-top: 9px solid transparent !important;
  border-bottom: 9px solid transparent !important;
  border-right: 9px solid;
  margin-top: -8px;
}
/* Some CSS magic for the awesome animations - feel free to make your own custom animations and reference it in your Tooltipster settings! */
.tooltipster-fade {
  opacity: 0;
  -webkit-transition-property: opacity;
  -moz-transition-property: opacity;
  -o-transition-property: opacity;
  -ms-transition-property: opacity;
  transition-property: opacity;
}
.tooltipster-fade-show {
  opacity: 1;
}
.tooltipster-grow {
  -webkit-transform: scale(0, 0);
  -moz-transform: scale(0, 0);
  -o-transform: scale(0, 0);
  -ms-transform: scale(0, 0);
  transform: scale(0, 0);
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  -ms-transition-property: -ms-transform;
  transition-property: transform;
  -webkit-backface-visibility: hidden;
}
.tooltipster-grow-show {
  -webkit-transform: scale(1, 1);
  -moz-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  transform: scale(1, 1);
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
  -moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
  -ms-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
  -o-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
}
.tooltipster-swing {
  opacity: 0;
  -webkit-transform: rotateZ(4deg);
  -moz-transform: rotateZ(4deg);
  -o-transform: rotateZ(4deg);
  -ms-transform: rotateZ(4deg);
  transform: rotateZ(4deg);
  -webkit-transition-property: -webkit-transform, opacity;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  -ms-transition-property: -ms-transform;
  transition-property: transform;
}
.tooltipster-swing-show {
  opacity: 1;
  -webkit-transform: rotateZ(0deg);
  -moz-transform: rotateZ(0deg);
  -o-transform: rotateZ(0deg);
  -ms-transform: rotateZ(0deg);
  transform: rotateZ(0deg);
  -webkit-transition-timing-function: cubic-bezier(0.23, 0.635, 0.495, 1);
  -webkit-transition-timing-function: cubic-bezier(0.23, 0.635, 0.495, 2.4);
  -moz-transition-timing-function: cubic-bezier(0.23, 0.635, 0.495, 2.4);
  -ms-transition-timing-function: cubic-bezier(0.23, 0.635, 0.495, 2.4);
  -o-transition-timing-function: cubic-bezier(0.23, 0.635, 0.495, 2.4);
  transition-timing-function: cubic-bezier(0.23, 0.635, 0.495, 2.4);
}
.tooltipster-fall {
  top: 0;
  -webkit-transition-property: top;
  -moz-transition-property: top;
  -o-transition-property: top;
  -ms-transition-property: top;
  transition-property: top;
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
  -moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
  -ms-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
  -o-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
}
.tooltipster-fall.tooltipster-dying {
  -webkit-transition-property: all;
  -moz-transition-property: all;
  -o-transition-property: all;
  -ms-transition-property: all;
  transition-property: all;
  top: 0px !important;
  opacity: 0;
}
.tooltipster-slide {
  left: -40px;
  -webkit-transition-property: left;
  -moz-transition-property: left;
  -o-transition-property: left;
  -ms-transition-property: left;
  transition-property: left;
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
  -moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
  -ms-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
  -o-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
}
.tooltipster-slide.tooltipster-dying {
  -webkit-transition-property: all;
  -moz-transition-property: all;
  -o-transition-property: all;
  -ms-transition-property: all;
  transition-property: all;
  left: 0px !important;
  opacity: 0;
}
/* CSS transition for when contenting is changing in a tooltip that is still open. The only properties that will NOT transition are: width, height, top, and left */
.tooltipster-content-changing {
  opacity: 0.5;
  -webkit-transform: scale(1.1, 1.1);
  -moz-transform: scale(1.1, 1.1);
  -o-transform: scale(1.1, 1.1);
  -ms-transform: scale(1.1, 1.1);
  transform: scale(1.1, 1.1);
}
@font-face {
  font-family: 'Avenir Next';
  src: url('../fonts/avenir/AvenirNext-Heavy.woff2') format('woff2'), url('../fonts/avenir/AvenirNext-Heavy.woff') format('woff'), url('../fonts/avenir/AvenirNext-Heavy.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Avenir Next';
  src: url('../fonts/avenir/AvenirNext-Italic.woff2') format('woff2'), url('../fonts/avenir/AvenirNext-Italic.woff') format('woff'), url('../fonts/avenir/AvenirNext-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'Avenir Next';
  src: url('../fonts/avenir/AvenirNext-Regular.woff2') format('woff2'), url('../fonts/avenir/AvenirNext-Regular.woff') format('woff'), url('../fonts/avenir/AvenirNext-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Avenir Next Demi';
  src: url('../fonts/avenir/AvenirNext-DemiBoldItalic.woff2') format('woff2'), url('../fonts/avenir/AvenirNext-DemiBoldItalic.woff') format('woff'), url('../fonts/avenir/AvenirNext-DemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: 'Avenir Next';
  src: url('../fonts/avenir/AvenirNext-DemiBold.woff2') format('woff2'), url('../fonts/avenir/AvenirNext-DemiBold.woff') format('woff'), url('../fonts/avenir/AvenirNext-DemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Avenir Next Ultra';
  src: url('../fonts/avenir/AvenirNext-UltraLightItalic.woff2') format('woff2'), url('../fonts/avenir/AvenirNext-UltraLightItalic.woff') format('woff'), url('../fonts/avenir/AvenirNext-UltraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: 'Avenir Next';
  src: url('../fonts/avenir/AvenirNext-HeavyItalic.woff2') format('woff2'), url('../fonts/avenir/AvenirNext-HeavyItalic.woff') format('woff'), url('../fonts/avenir/AvenirNext-HeavyItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: 'Avenir Next';
  src: url('../fonts/avenir/AvenirNext-BoldItalic.woff2') format('woff2'), url('../fonts/avenir/AvenirNext-BoldItalic.woff') format('woff'), url('../fonts/avenir/AvenirNext-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: 'Avenir Next';
  src: url('../fonts/avenir/AvenirNext-Medium.woff2') format('woff2'), url('../fonts/avenir/AvenirNext-Medium.woff') format('woff'), url('../fonts/avenir/AvenirNext-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Avenir Next Ultra';
  src: url('../fonts/avenir/AvenirNext-UltraLight.woff2') format('woff2'), url('../fonts/avenir/AvenirNext-UltraLight.woff') format('woff'), url('../fonts/avenir/AvenirNext-UltraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'Avenir Next';
  src: url('../fonts/avenir/AvenirNext-BoldItalic_1.woff2') format('woff2'), url('../fonts/avenir/AvenirNext-BoldItalic_1.woff') format('woff'), url('../fonts/avenir/AvenirNext-BoldItalic_1.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: 'Avenir Next';
  src: url('../fonts/avenir/AvenirNext-MediumItalic.woff2') format('woff2'), url('../fonts/avenir/AvenirNext-MediumItalic.woff') format('woff'), url('../fonts/avenir/AvenirNext-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'Avenir Next';
  src: url('../fonts/avenir/AvenirNext-Bold.woff2') format('woff2'), url('../fonts/avenir/AvenirNext-Bold.woff') format('woff'), url('../fonts/avenir/AvenirNext-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Avenir Next Ultra';
  src: url('../fonts/avenir/AvenirNext-UltraLightItalic_1.woff2') format('woff2'), url('../fonts/avenir/AvenirNext-UltraLightItalic_1.woff') format('woff'), url('../fonts/avenir/AvenirNext-UltraLightItalic_1.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: 'Avenir Next Demi';
  src: url('../fonts/avenir/AvenirNext-DemiBold_1.woff2') format('woff2'), url('../fonts/avenir/AvenirNext-DemiBold_1.woff') format('woff'), url('../fonts/avenir/AvenirNext-DemiBold_1.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Avenir Next';
  src: url('../fonts/avenir/AvenirNext-HeavyItalic_1.woff2') format('woff2'), url('../fonts/avenir/AvenirNext-HeavyItalic_1.woff') format('woff'), url('../fonts/avenir/AvenirNext-HeavyItalic_1.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: 'Avenir Next';
  src: url('../fonts/avenir/AvenirNext-Regular_1.woff2') format('woff2'), url('../fonts/avenir/AvenirNext-Regular_1.woff') format('woff'), url('../fonts/avenir/AvenirNext-Regular_1.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Avenir Next';
  src: url('../fonts/avenir/AvenirNext-Medium_1.woff2') format('woff2'), url('../fonts/avenir/AvenirNext-Medium_1.woff') format('woff'), url('../fonts/avenir/AvenirNext-Medium_1.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Avenir Next';
  src: url('../fonts/avenir/AvenirNext-Bold_1.woff2') format('woff2'), url('../fonts/avenir/AvenirNext-Bold_1.woff') format('woff'), url('../fonts/avenir/AvenirNext-Bold_1.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Avenir Next Ultra';
  src: url('../fonts/avenir/AvenirNext-UltraLight_1.woff2') format('woff2'), url('../fonts/avenir/AvenirNext-UltraLight_1.woff') format('woff'), url('../fonts/avenir/AvenirNext-UltraLight_1.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'Avenir Next';
  src: url('../fonts/avenir/AvenirNext-MediumItalic_1.woff2') format('woff2'), url('../fonts/avenir/AvenirNext-MediumItalic_1.woff') format('woff'), url('../fonts/avenir/AvenirNext-MediumItalic_1.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'Avenir Next';
  src: url('../fonts/avenir/AvenirNext-Heavy_1.woff2') format('woff2'), url('../fonts/avenir/AvenirNext-Heavy_1.woff') format('woff'), url('../fonts/avenir/AvenirNext-Heavy_1.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Avenir Next';
  src: url('../fonts/avenir/AvenirNext-Italic_1.woff2') format('woff2'), url('../fonts/avenir/AvenirNext-Italic_1.woff') format('woff'), url('../fonts/avenir/AvenirNext-Italic_1.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'Avenir Next Demi';
  src: url('../fonts/avenir/AvenirNext-DemiBoldItalic_1.woff2') format('woff2'), url('../fonts/avenir/AvenirNext-DemiBoldItalic_1.woff') format('woff'), url('../fonts/avenir/AvenirNext-DemiBoldItalic_1.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}
.f-avn-m {
  font-family: 'Avenir Next';
  font-weight: 500;
}
.f-avn-r {
  font-family: 'Avenir Next';
  font-weight: normal;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
figure {
  margin: 0;
  font-weight: 400;
}
ul,
ol {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
img {
  font-size: 0;
}
button,
select,
[type=submit],
[type=checkbox],
[type=color],
[type=date],
[type=datetime-local],
[type=datetime],
[type=email],
[type=month],
[type=number],
[type=password],
[type=search],
[type=tel],
[type=text],
[type=time],
[type=url],
[type=week],
textarea {
  outline: none;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  margin: 0;
  width: auto;
}
a {
  text-decoration: none;
}
html {
  font-size: 16px;
}
body {
  font-family: 'Avenir Next';
  color: #fff;
}
html,
body {
  position: relative;
  height: 100%;
}
section[role="main"] {
  max-width: 100%;
  overflow: hidden;
  font-size: 0;
}
.page-bg {
  position: fixed;
  z-index: 0;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100%;
  background-size: cover;
}
.page-wrapper {
  position: fixed;
  z-index: 10;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}
.svg-sprite {
  height: 0;
  width: 0;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}
.app-main {
  float: left;
  padding: 30px 40px;
  padding-top: 80px;
}
#space-bg canvas {
  position: absolute;
  top: 0;
  left: 0;
}
.content-wrapper {
  background-color: hsla(0, 0%, 100%, 0.001);
}
.page-bg {
  background-image: url('../images/main-bg.jpg');
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.fade-in {
  animation: fadeIn 0.25s ease;
}
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 6px rgba(255, 255, 255, 0.13);
  }
  50% {
    box-shadow: 0 0 6px 10px rgba(255, 255, 255, 0.13);
  }
  100% {
    box-shadow: 0 0 0 6px rgba(255, 255, 255, 0.13);
  }
}
@keyframes zoomIn {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
.zoom-in {
  animation: zoomIn 0.25s ease;
}
@keyframes slideLeftIn {
  0% {
    opacity: 0;
    transform: translateX(10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes slideUpIn {
  0% {
    transform: translateY(10px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 0.6;
  }
}
@keyframes shadowFlash {
  0% {
    box-shadow: 0 0 0 #00ffd2;
  }
  10% {
    box-shadow: 0 0 20px #00ffd2;
  }
  100% {
    box-shadow: 0 0 0px #00ffd2;
  }
}
@keyframes flip {
  0% {
    transform: rotate(45deg);
  }
  100% {
    transform: rotate(235deg);
  }
}
@keyframes backFlip {
  0% {
    transform: rotate(-45deg);
  }
  100% {
    transform: rotate(-235deg);
  }
}
@keyframes loading {
  from {
    left: -30%;
    width: 30%;
    background-color: #fe006d;
  }
  50% {
    width: 30%;
    background-color: #7e00ff;
  }
  70% {
    width: 70%;
  }
  80% {
    left: 50%;
  }
  95% {
    left: 120%;
  }
  to {
    left: 100%;
    background-color: #00ffd2;
  }
}
.ps {
  overflow: hidden;
  position: relative;
  /*----------  Style Y rail  ----------*/
  /*----------  Style X rail  ----------*/
}
.ps.always-visible .ps__rail-x,
.ps.always-visible .ps__rail-y {
  opacity: 0.9;
}
.ps .ps__rail-x,
.ps .ps__rail-y {
  background-color: #294260;
  border-radius: 6px;
  transition: width 0.25s ease, opacity 0.25s ease;
  z-index: 5;
}
.ps__thumb-y,
.ps__thumb-x {
  transition: background-color 0.25s ease, width 0.25s ease;
  background-color: #08b1ff;
}
.ps__thumb-y {
  width: 100%;
  right: 0;
}
.ps__rail-y {
  width: 4px;
  right: 0;
}
.ps__rail-y:hover {
  width: 7px;
}
.ps__rail-y:hover .ps__thumb-y {
  width: 100%;
  background-color: #08b1ff;
}
.ps__thumb-x {
  height: 100%;
  bottom: 0;
}
.ps__rail-x {
  height: 4px;
  bottom: 0;
}
.ps__rail-x:hover {
  height: 7px;
}
.ps__rail-x:hover .ps__thumb-x {
  height: 100%;
  background-color: #08b1ff;
}
body {
  background-color: #172f52;
}
/*----------  Colors  ----------*/
.th-red {
  color: #e80d5a !important;
}
.th-purple {
  color: #7e00ff !important;
}
.th-green {
  color: #06ded4 !important;
}
.th-blue {
  color: #08b1ff;
}
.th-gray {
  color: #7a7979;
}
.th-color-tr {
  transition: color 0.25s ease;
}
/*----------  Transitions  ----------*/
/*----------  Styling  ----------*/
h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  color: #7493c3;
}
.th-white-bg {
  background-color: #fff;
}
.th-blue-bg {
  background-color: #060828;
}
.th-title-big {
  color: #fff;
  font-size: 45px;
  font-weight: 500;
}
.th-title-xbig {
  color: #fff;
  font-size: 60px;
  font-weight: 500;
}
.th-title {
  font-size: 15px;
  color: #7493c3;
}
.th-title--big {
  font-size: 18px;
}
.th-title--small {
  font-size: 13px;
}
.th-title--small span {
  font-size: 8px;
}
.th-title--small2 {
  font-size: 10px;
}
.th-title--white {
  color: #fff;
}
.th-title--blue {
  color: #2e6ec5;
}
.th-title--dark {
  color: #2a3537;
}
.th-label {
  font-size: 14px;
  color: #7a7979;
}
.th-label--blue {
  color: #799bcd;
}
.th-label--small {
  font-size: 13px;
}
.th-label--up {
  text-transform: uppercase;
}
.th-label span {
  color: inherit;
}
.th-file-icon {
  fill: #00baff;
}
.th-file-icon text {
  text-anchor: middle;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 13px;
}
.th-ask-icon {
  cursor: pointer;
  color: #08b1ff;
  font-size: 10px;
  text-align: center;
  line-height: 13px;
  letter-spacing: 1px;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  border: 1px solid currentcolor;
  transition: box-shadow 0.25s ease;
  margin-left: 8px;
  display: inline-block;
  vertical-align: middle;
  zoom: 1;
  /* Fix for IE7 */
  *display: inline;
  /* Fix for IE7 */
}
.th-ask-icon:hover {
  box-shadow: 0 0 3px currentcolor;
}
.trash-icon {
  fill: #7493c3;
  width: 20px;
}
.trash-icon:hover {
  fill: #08b1ff;
}
.th-ok {
  width: 20px;
  height: 12px;
  position: relative;
  color: #00fff3;
  transform: rotate(-45deg);
}
.th-ok:after,
.th-ok:before {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  border: 1px solid currentcolor;
  box-shadow: 0 0 3px currentcolor;
  border-radius: 1px;
}
.th-ok:before {
  height: 100%;
}
.th-ok:after {
  width: 100%;
}
.th-x {
  width: 26px;
  height: 26px;
  position: relative;
  color: #1a2e3d;
}
.th-x:after,
.th-x:before {
  content: '';
  display: block;
  position: absolute;
  border: 2px solid currentColor;
  width: 100%;
  top: 50%;
  left: 0;
  margin-left: -1px;
  border-radius: 1px;
  box-shadow: 0 0 3px currentColor;
}
.th-x:after {
  transform: rotate(-45deg);
}
.th-x:before {
  transform: rotate(45deg);
}
.th-x--slim:after,
.th-x--slim:before {
  border-width: 1px;
  box-shadow: none;
}
.th-x--inverted:after {
  transform: rotate(0);
}
.th-x--inverted:before {
  transform: rotate(90deg);
}
/*=====================================
=            Toggle styles            =
=====================================*/
.toggle {
  user-select: none;
}
.toggle__label {
  font-size: 10px;
  color: #08b1ff;
}
.toggle__label--big {
  font-size: 13px;
}
.toggle__view {
  width: 54px;
  height: 25px;
  border: 1px solid #414d59;
  border-radius: 2px;
  position: relative;
  top: -1px;
  margin: 0 8px;
}
.toggle__tick {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid transparent;
  color: #00ffd2;
  transition: color 0.25s ease;
}
.toggle__tick:before {
  content: '';
  display: block;
  position: absolute;
  width: 50%;
  height: 100%;
  border-radius: 2px;
  top: 0;
  left: 0;
  background-color: currentcolor;
  transition: box-shadow 0.25s ease, transform 0.25s ease;
}
.toggle:hover .toggle__tick:before {
  box-shadow: 0 0 5px currentcolor;
}
.toggle.is-active .toggle__tick:before {
  transform: translateX(100%);
}
.toggle--dark .toggle__label {
  color: #304145;
}
.toggle--dark .toggle__tick {
  color: #304145;
}
.toggle--light .toggle__label {
  color: #fff;
}
.toggle--light .toggle__tick {
  color: #08b1ff;
}
.th-toggle {
  width: 20px;
  height: 20px;
  position: relative;
  cursor: pointer;
  margin: 0 auto;
  color: #00ffd2;
  border-radius: 50%;
  border: 2px solid currentColor;
  box-shadow: 0 0 2px, inset 0 0 2px;
  transition: color 0.25s ease;
  font-size: 0;
}
.th-toggle:after {
  display: block;
  content: '';
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.4);
  opacity: 0;
  background-color: currentcolor;
  transition: transform 0.25s ease, opacity 0.25s ease;
}
.th-toggle.no-hover {
  cursor: default;
}
.th-toggle:not(.no-hover):hover:after {
  opacity: 1;
}
.th-toggle.is-active:after {
  transform: translate(-50%, -50%) scale(1.1);
  opacity: 1;
}
.th-toggle.is-active:not(.no-hover):hover:after {
  opacity: 0.55;
}
.th-toggle--gray {
  color: #2a3537;
}
.th-toggle--red {
  color: #fe006d;
}
/*=====  End of Toggle styles  ======*/
/*====================================
=            Theme button            =
====================================*/
.th-btn {
  font-size: 11px;
  color: #718fb7;
  background-color: #12293d;
  box-shadow: 0 0 5px 1px #12293d;
  text-transform: capitalize;
  dispaly: inline-block;
  padding: 2px 25px;
  border-radius: 9px;
  cursor: pointer;
}
.th-btn--red {
  color: #fff;
  background-color: #c01861;
  box-shadow: 0 0 5px 1px #c01861;
}
.th-large-btn {
  width: 100%;
  text-align: center;
  color: #08b1ff;
  border: 2px solid #24426c;
  box-shadow: inset 0 0 0 1px #142f50;
  cursor: pointer;
  font-size: 14px;
  line-height: 38px;
  text-transform: uppercase;
  transition: box-shadow 0.25s ease, background-color 0.25s ease, border-color 0.25s ease;
}
.th-large-btn:hover,
.th-large-btn.is-active {
  background-color: #193558;
  box-shadow: inset 0 0 0 1px #27436e;
}
.th-large-btn--active {
  border-color: #08b1ff;
}
.th-light-btn {
  color: #fff;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 27px;
  padding: 0 17px;
  border: 2px solid currentcolor;
  border-radius: 3px;
  display: inline-block;
  vertical-align: middle;
  transition: opacity 0.25s ease, box-shadow 0.25s ease;
}
.th-light-btn:hover {
  box-shadow: 0 0 3px currentcolor;
}
.th-green-btn {
  text-transform: capitalize;
  color: #00ffd2;
  border: 1px solid currentcolor;
  border-radius: 8px;
  text-align: center;
  line-height: 50px;
  font-size: 20px;
  padding: 0 15px;
  width: 100%;
  font-family: 'Avenir Next';
  font-weight: normal;
  transition: box-shadow 0.25s ease;
}
.th-green-btn:hover {
  box-shadow: 0 0 3px currentcolor;
}
.th-task-btn {
  font-family: 'Avenir Next';
  font-weight: normal;
  white-space: nowrap;
  color: #fff;
  font-size: 13px;
  line-height: 30px;
  text-transform: uppercase;
  padding: 0 15px;
  background-color: #154e74;
  border-radius: 4px;
  display: inline-block;
  vertical-align: middle;
}
.th-task-btn[disabled] {
  color: #010a13;
  background-color: #042849;
}
.th-round-btn {
  font-size: 13px;
  color: #fff;
  padding: 0 20px;
  line-height: 36px;
  background-color: #091a33;
  border: 1px solid #303c4b;
  border-radius: 18px;
  display: inline-block;
  cursor: pointer;
  transition-property: background-color, border-color, box-shadow;
  transition-duration: 0.25s;
  transition-timing-function: ease;
  font-weight: 500;
}
.th-round-btn:not(.no-hover):hover {
  background-color: #08b1ff;
  border-color: #08b1ff;
  box-shadow: 0 0 7px #08b1ff;
}
.th-round-btn.is-active {
  background-color: #08b1ff;
  border-color: #08b1ff;
  box-shadow: 0 0 7px #08b1ff;
}
.th-round-btn--red-hov:not(.no-hover):hover {
  background-color: #fe006d;
  border-color: #fe006d;
  box-shadow: 0 0 7px #fe006d;
}
.th-round-btn--red-hov.is-active {
  background-color: #fe006d;
  border-color: #fe006d;
  box-shadow: 0 0 7px #fe006d;
}
.th-round-btn--gray {
  background-color: #2a3537;
}
.th-round-btn--light {
  background-color: #1c375e;
  border-color: #415370;
}
.ui-tab li {
  display: inline-block;
}
.th-button {
  color: #3a393a;
  font-size: 14px;
  line-height: 1.2;
  padding: 6px 10px 5px;
  display: inline-block;
  vertical-align: middle;
  border: 1px solid #12cac1;
  border-radius: 3px;
  text-transform: capitalize;
}
.th-button:hover {
  box-shadow: 0 0 3px #12cac1;
}
.th-new-row {
  color: #08b1ff;
  border: 1px solid currentcolor;
  cursor: pointer;
  position: relative;
  height: 25px;
  transition: opacity 0.25s ease, box-shadow 0.25s ease, color 0.25s ease;
}
.th-new-row:after,
.th-new-row:before {
  transition: box-shadow 0.25s ease, transform 0.25s ease;
  background-color: currentcolor;
  display: block;
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
}
.th-new-row:after {
  width: 2px;
  height: 12px;
  margin-left: -1px;
  margin-top: -6px;
}
.th-new-row:before {
  width: 12px;
  height: 2px;
  margin-left: -6px;
  margin-top: -1px;
}
.th-new-row:hover,
.th-new-row:hover:after,
.th-new-row:hover:before {
  box-shadow: 0 0 3px currentcolor;
}
.th-new-row--save {
  color: #09c8bf;
}
.th-new-row--save:after {
  transform: rotate(30deg) scale(1.1) translate(2px, -2px);
}
.th-new-row--save:before {
  transform: rotate(45deg) scaleX(0.7) translateX(-1px) translateY(3px);
}
.th-new-row--cancel {
  color: #fe006d;
}
.th-new-row--cancel:after {
  display: none;
}
.th-edit-btn {
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -15px;
  border-radius: 4px;
  padding: 5px;
  background-color: #12cac1;
  font-size: 0;
  opacity: 0;
  transition: opacity 0.25s ease;
  cursor: pointer;
}
.th-edit-btn svg {
  fill: #294260;
  width: 20px;
  height: 20px;
}
.th-edit-btn__hov-wrapper:hover .th-edit-btn {
  opacity: 1;
}
/*=====  End of Theme button  ======*/
/*=================================
=            Close btn            =
=================================*/
.th-close-btn {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  color: #32568e;
  border: 2px solid currentcolor;
  transition: color 0.25s ease;
  cursor: pointer;
  position: relative;
  display: inline-block;
  vertical-align: middle;
}
.th-close-btn span {
  position: absolute;
  width: 50%;
  height: 50%;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  color: currentcolor;
}
.th-close-btn span:after,
.th-close-btn span:before {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 3px;
  margin-top: -2px;
  background-color: currentcolor;
  content: '';
}
.th-close-btn span:after {
  transform: rotate(45deg);
}
.th-close-btn span:before {
  transform: rotate(-45deg);
}
.th-close {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
}
.th-close:after,
.th-close:before {
  background-color: #08b1ff;
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  position: absolute;
  top: 50%;
  margin-top: -1px;
  transition: transform 0.25s ease;
}
.th-close:before {
  transform: rotate(45deg);
}
.th-close:after {
  transform: rotate(-45deg);
}
.th-close:hover:before {
  transform: rotate(-45deg);
}
.th-close:hover:after {
  transform: rotate(45deg);
}
/*=====  End of Close btn  ======*/
/*==============================
=            Logout            =
==============================*/
.logout {
  width: 45px;
  height: 45px;
  cursor: pointer;
  padding: 12px;
}
.logout__wrapper {
  padding-bottom: 20px;
  position: absolute;
  bottom: 0;
  left: 0;
}
.logout .icon {
  width: 100%;
  height: 100%;
  fill: #294260;
  transition: fill 0.25s ease;
}
.logout:hover .icon {
  fill: #08b1ff;
}
/*=====  End of Logout  ======*/
.th-field {
  background-color: #2b5574;
  border: 1px solid #647dac;
  color: #fff;
  font-size: 14px;
  line-height: 18px;
  padding: 8px 5px;
  height: auto;
}
.w100 {
  width: 100%;
}
/*===================================
=            Input group            =
===================================*/
.in-grp {
  position: relative;
  display: table;
  background-color: #143753;
  width: 100%;
  border-radius: 8px;
  padding: 8px 0;
}
.in-grp__field-g,
.in-grp__cell {
  display: table-cell;
  vertical-align: middle;
}
.in-grp__cell {
  width: 1%;
  padding: 0 15px;
  white-space: nowrap;
  letter-spacing: 13px;
}
.in-grp__field-g {
  width: 100%;
}
.in-grp__field {
  width: 100%;
  color: #98dbfe;
  font-size: 18px;
  line-height: 22px;
  font-family: 'Avenir Next';
  font-weight: normal;
  height: auto;
  border: 1px solid #607aa8;
  transition: box-shadow 0.25s ease;
  padding: 6px 12px;
  background-color: #2b5574;
}
.in-grp__field:focus {
  box-shadow: 0 0 7px #08b1ff;
}
.in-grp__field::placeholder {
  color: currentcolor;
}
.in-grp__field__admin {
  width: 100%;
  color: #06607d;
  font-size: 18px;
  line-height: 22px;
  height: auto;
  border: 1px solid #08b1ff;
  transition: box-shadow 0.25s ease;
  padding: 6px 12px;
}
.in-grp__field__admin:focus {
  box-shadow: 0 0 7px #08b1ff;
}
.in-grp__field__admin::placeholder {
  color: currentcolor;
}
.in-grp__done {
  height: 9px;
  width: 18px;
  border: 2px solid #08b1ff;
  border-top: none;
  border-right: none;
  transform: rotate(-45deg);
  position: relative;
  top: -2px;
  display: inline-block;
}
.in-grp__done-wrapper {
  width: 20px;
  height: 20px;
  line-height: 20px;
  cursor: pointer;
  border: 5px solid transparent;
  box-sizing: content-box;
  margin: 0 -5px;
}
/*=====  End of Input group  ======*/
/*======================================
=            Tooltip styles            =
======================================*/
.tooltipster-default {
  max-width: 220px;
  border: 2px solid #2d4876;
  border-radius: 5px;
  background: #0f2245;
  color: #05ded4;
}
.tooltipster-default .tooltipster-content {
  font-size: 12px;
  line-height: 12px;
  padding: 14px 15px;
}
.tooltipster-green {
  border-radius: 3px;
  border: 2px solid #0a636d;
  background: #0a4d5c;
  color: #05ded4;
  transform: translateX(-4px);
}
.tooltipster-green .tooltipster-content {
  font-size: 12px;
  line-height: 12px;
  padding: 6px 20px;
  text-transform: uppercase;
}
/*=====  End of Tooltip styles  ======*/
.shared-square-icon {
  content: '';
  display: inline-block;
  width: 22px;
  height: 22px;
  background-color: #00ffd2;
  border-radius: 4px;
  vertical-align: middle;
  transition: background-color 0.25s ease;
}
.shared-item-name {
  color: #fff;
  font-size: 20px;
  line-height: 22px;
  position: relative;
  padding-left: 20px;
}
.hide-text {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}
.theme-loading {
  height: 4px;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: #122a4a;
  border-radius: 2px;
}
.theme-loading:before {
  border-radius: 2px;
  display: block;
  position: absolute;
  content: '';
  left: -100%;
  width: 100%;
  height: 4px;
  background-color: #2980b9;
  animation: loading 1.7s linear infinite;
}
.theme-loading--abs {
  position: absolute;
  top: 0;
  left: 0;
}
.theme-breadcrumb__item {
  color: #09c8bf;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 15px;
}
.theme-breadcrumb__item--inactive {
  color: #304145;
}
.theme-breadcrumb__item:not(:last-child) {
  margin-right: 10px;
}
.theme-breadcrumb__item:not(:first-child):before {
  display: inline-block;
  content: '';
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: currentColor;
  margin-right: 14px;
  vertical-align: baseline;
  margin-bottom: 2px;
}
.theme-breadcrumb-list {
  margin-top: 15px;
}
.theme-check {
  width: 20px;
  height: 20px;
  color: #08b1ff;
  border: 2px solid currentcolor;
  position: relative;
  cursor: pointer;
  line-height: 0;
  display: inline-block;
  white-space: nowrap;
}
.theme-check:after {
  display: block;
  content: '';
  width: 12px;
  height: 6px;
  border-left: 2px solid;
  border-bottom: 2px solid;
  border-color: currentcolor;
  transform: rotate(-45deg);
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -4px;
  margin-left: -6px;
  transition: opacity 0.25s ease;
  opacity: 0;
}
.theme-check.is-active:after {
  opacity: 1;
}
.theme-check__label {
  line-height: 20px;
  font-size: 13px;
  position: relative;
  right: 100%;
  margin-left: -15px;
}
.theme-check--green {
  color: #12cac1;
}
.theme-expand {
  display: inline-block;
  vertical-align: bottom;
  width: 26px;
  height: 26px;
  margin-left: 15px;
  border: 2px solid #08b1ff;
  border-radius: 50%;
  background-color: #0f3052;
  position: relative;
  transition: transform 0.25s ease;
  transform: rotate(180deg);
}
.theme-expand:after,
.theme-expand:before {
  display: block;
  content: '';
  width: 40%;
  height: 1px;
  background-color: #08b1ff;
  position: absolute;
  top: 50%;
  margin-top: -4px;
}
.theme-expand:after {
  left: 50%;
  transform-origin: center left;
  transform: rotate(45deg);
}
.theme-expand:before {
  right: 50%;
  transform-origin: center right;
  transform: rotate(-45deg);
}
.theme-expand.is-active {
  transform: rotate(0);
}
.th-ellipsis {
  text-align: center;
  font-size: 0;
  line-height: 0;
  cursor: pointer;
  display: inline-block;
  box-sizing: content-box;
  border: 5px solid transparent;
  position: absolute;
  top: 50%;
  width: 22px;
  margin-top: -7px;
  color: #4571b1;
  transition: color 0.25s ease;
}
.th-ellipsis:before {
  content: '';
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: currentcolor;
}
.th-ellipsis:before {
  box-shadow: 8px 0 0 currentcolor, -8px 0 0 currentcolor;
}
.th-ellipsis.is-active {
  color: #00ffd2;
}
.th-tab-header {
  background-color: #d9eae7;
  cursor: pointer;
  padding: 15px;
  color: #232323;
  transition: background-color 0.25s ease, color 0.25s ease;
  position: relative;
}
.th-tab-header:after {
  display: block;
  content: '';
  position: absolute;
  right: 15px;
  top: 50%;
  margin-top: -3px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 7px 0 7px;
  border-color: currentcolor transparent transparent transparent;
  transition: transform 0.25s ease;
}
.th-tab-header.is-active {
  background-color: #9acbc9;
  color: #fff;
}
.th-tab-header.is-active:after {
  transform: rotate(180deg);
}
.th-tab-header__title {
  font-size: 18px;
  line-height: 20px;
  font-weight: 600;
  color: inherit;
}
.th-tag {
  font-size: 12px;
  line-height: 20px;
  padding: 5px 20px;
  border-radius: 3px;
  color: #fff;
  background-color: #09c8bf;
  display: inline-block;
  cursor: pointer;
  position: relative;
}
.th-tag--delete-hov:after,
.th-tag--delete-hov:before {
  display: block;
  content: '';
  position: absolute;
  opacity: 0;
  transition: opacity 0.25s ease;
}
.th-tag--delete-hov:before {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #fe006d;
  border-radius: 3px;
}
.th-tag--delete-hov:after {
  width: 10px;
  height: 2px;
  background-color: #fff;
  left: 50%;
  top: 50%;
  margin-top: -1px;
  margin-left: -5px;
}
.th-tag--delete-hov:hover:after,
.th-tag--delete-hov:hover:before {
  opacity: 1;
}
.th-legend {
  min-width: 170px;
}
.th-legend__label {
  font-size: 11px;
  line-height: 1;
  color: #fff;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.th-legend__val {
  color: currentcolor;
  margin-bottom: 5px;
}
.th-legend__val:before {
  display: inline-block;
  content: '';
  width: 8px;
  height: 8px;
  margin-bottom: 3px;
  margin-right: 10px;
  background-color: currentcolor;
}
.th-search-box {
  position: relative;
}
.th-search-box input {
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  cursor: initial;
}
/*----------  Viewport sizes  ----------*/
.l-dis-ib {
  display: inline-block;
  vertical-align: middle;
  zoom: 1;
  /* Fix for IE7 */
  *display: inline;
  /* Fix for IE7 */
}
.l-dis-b {
  display: block;
}
.l-dis-t {
  display: table;
}
.l-dis-tc {
  display: table-cell;
}
.l-dis-tr {
  display: table-row;
}
.l-disabled {
  pointer-events: none;
  opacity: 0.5;
}
.l-min-w33 {
  min-width: 33.3333%;
}
.l-w-100 {
  width: 100%;
}
.tst__wrapper__l-w-30 {
  width: 30%;
}
.tst__wrapper__l-w-70 {
  width: 70%;
}
.l-fs-12 {
  font-size: 12px;
}
.l-m-h25 {
  min-height: 250px;
}
/*============================
=            Flex            =
============================*/
.l-dis-flx {
  display: flex;
  width: 100%;
}
.l-flx-wrap {
  flex-wrap: wrap;
}
.l-flx-jsb {
  justify-content: space-between;
}
/*=====  End of Flex  ======*/
/*====================================
=            Grid options            =
====================================*/
.l-ib-grid {
  font-size: 0;
}
.l-ib-grid .column,
.l-ib-grid .column:last-child:not(:first-child),
.l-ib-grid .columns:last-child:not(:first-child) {
  float: none;
  display: inline-block;
}
.row {
  width: 100%;
}
.row.no-gutter {
  margin-left: 0;
  margin-right: 0;
}
.no-gutter > .column,
.no-gutter > .columns {
  padding-left: 0;
  padding-right: 0;
}
/*-----  End of Grid options  ------*/
.l-wrap-600 {
  max-width: 600px;
}
.l-wrap-90p {
  max-width: 90%;
}
.l-wrap-100p {
  max-width: 100%;
}
.l-group {
  display: inline-table;
  width: 100%;
}
.l-group__cell {
  display: table-cell;
  width: auto;
}
.l-group__main {
  display: table-cell;
  width: 100%;
}
.l-tal-c {
  text-align: center;
}
.l-tal-r {
  text-align: right;
}
.l-tal-l {
  text-align: left;
}
.l-tal-j {
  text-align: justify;
}
.l-tal-j:after {
  display: inline-block;
  content: '';
  width: 100%;
  height: 0;
}
.l-ttr-up {
  text-transform: uppercase;
}
.l-ttr-cap {
  text-transform: capitalize;
}
.l-ttr-low {
  text-transform: lowercase;
}
.l-ttr-init {
  text-transform: initial;
}
.l-lts-init {
  letter-spacing: initial;
}
.l-lts-normal {
  letter-spacing: normal;
}
.l-cur-p {
  cursor: pointer;
}
.l-cur-t {
  cursor: text;
}
.l-of-h {
  overflow: hidden;
}
.l-fl-r {
  float: right;
}
.l-fl-l {
  float: left;
}
.l-fl-fix:after {
  display: block;
  content: '';
  clear: both;
}
.l-val-b {
  vertical-align: bottom;
}
.l-val-bl {
  vertical-align: baseline;
}
.l-val-m {
  vertical-align: middle;
}
.l-val-t {
  vertical-align: top;
}
.l-pos-r {
  position: relative;
}
.l-pos-a {
  position: absolute;
}
.l-pos-fx {
  position: fixed;
}
.l-pos-st {
  position: static;
}
.l-ws-nw {
  white-space: nowrap;
}
.l-fz-0 {
  font-size: 0;
}
.l-fz-1 {
  font-size: 1px;
}
.l-h-100 {
  height: 100%;
}
.l-full-h {
  position: relative;
  height: 100%;
}
.l-full-w {
  width: 100%;
}
.l-hidden {
  display: none;
}
.l-whs-nw {
  white-space: nowrap;
}
.l-a-c {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.l-posa-tl {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
/*----------  z-index  ----------*/
.l-zi-0 {
  z-index: 0;
}
.l-zi-5 {
  z-index: 5;
}
.l-zi-10 {
  z-index: 10;
}
.l-zi-15 {
  z-index: 15;
}
.l-zi-20 {
  z-index: 20;
}
.l-mt-35 {
  margin-top: 35px;
}
.l-mb-50 {
  margin-bottom: 50px;
}
.l-mt-50 {
  margin-top: 50px;
}
.l-mt-5 {
  margin-top: 5px;
}
.l-mt-10 {
  margin-top: 10px;
}
.l-mt-15 {
  margin-top: 15px;
}
.l-mt-20 {
  margin-top: 20px;
}
.l-mb-15 {
  margin-bottom: 15px;
}
.l-mb-10 {
  margin-bottom: 10px;
}
.l-mb-25 {
  margin-bottom: 25px;
}
.l-mb-5 {
  margin-bottom: 5px;
}
.l-mr--15 {
  margin-right: -15px;
}
.l-mr-15 {
  margin-right: 15px;
}
.l-mr-5 {
  margin-right: 5px;
}
.l-ml-0 {
  margin-left: 0;
}
.l-pb-0 {
  padding-bottom: 0;
}
.l-pl-15 {
  padding-left: 15px;
}
.l-pl-30 {
  padding-left: 30px;
}
.l-pb-15 {
  padding-bottom: 15px;
}
.l-pb-20 {
  padding-bottom: 20px;
}
.l-pb-30 {
  padding-bottom: 30px;
}
.l-pb-10 {
  padding-bottom: 10px;
}
.l-pb-5 {
  padding-bottom: 5px;
}
.l-pb-40 {
  padding-bottom: 40px;
}
.l-pb-50 {
  padding-bottom: 50px;
}
.l-pt-10 {
  padding-top: 10px;
}
.l-pt-15 {
  padding-top: 15px;
}
.l-pt-20 {
  padding-top: 20px;
}
.l-pt-30 {
  padding-top: 30px;
}
.l-pt-35 {
  padding-top: 35px;
}
.l-pt-40 {
  padding-top: 40px;
}
.l-pr-15 {
  padding-right: 15px;
}
.l-pr-10 {
  padding-right: 10px;
}
.l-pr-20 {
  padding-right: 20px;
}
.l-pr-30 {
  padding-right: 30px;
}
.l-pr-40 {
  padding-right: 40px;
}
.l-pr-80 {
  padding-right: 80px;
}
.l-pt-15 {
  padding-top: 15px;
}
.l-sp-5 {
  padding-left: 5px;
  padding-right: 5px;
}
.l-sp-10 {
  padding-right: 10px;
  padding-left: 10px;
}
.l-sp-15 {
  padding-right: 15px;
  padding-left: 15px;
}
.l-sp-20 {
  padding-right: 20px;
  padding-left: 20px;
}
.l-fw-6 {
  font-weight: 600;
}
.l-fw-7 {
  font-weight: 700;
}
/*----------  Style app edges  ----------*/
.edge__item {
  position: absolute;
  display: inline-block;
  white-space: nowrap;
}
.edge__item--br {
  bottom: 0;
  right: 0;
  padding-right: 40px;
  padding-bottom: 30px;
}
.edge__item--bl {
  bottom: 0;
  left: 390px;
  padding-left: 40px;
  padding-bottom: 30px;
}
@media screen and (max-width: 1280px) {
  .edge__item--br {
    padding-right: 20px;
  }
  .edge__item--bl {
    padding-left: 0px;
  }
  .edge__item--bl,
  .edge__item--br {
    padding-bottom: 15px;
  }
}
/*=============================================
=            field with side label            =
=============================================*/
.l-field {
  display: inline-table;
  vertical-align: middle;
}
.l-field:after {
  display: block;
  content: '';
  clear: both;
}
.l-field__label {
  display: table-cell;
  width: auto;
}
.l-field__input {
  display: block;
  float: left;
}
/*=====  End of field with side label  ======*/
@media screen and (max-width: 1280px) {
  .app-main {
    padding: 15px 20px;
    padding-top: 65px;
  }
}
.br-support {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: url(../images/main-bg.jpg);
  background-color: #08b1ff;
  text-align: center;
}
.br-support__row {
  max-width: none !important;
  display: inline-block;
}
.br-support__wrapper {
  width: 100%;
  height: 100%;
  display: table;
}
.br-support__content {
  display: table-cell;
  vertical-align: middle;
}
.br-support__browsers {
  margin-top: 20px;
}
.br-support__browsers:after {
  display: block;
  content: '';
  clear: both;
}
.br-support__container {
  margin: 0 auto;
  width: 100%;
  max-width: 1300px;
}
.br-support__browser {
  margin-top: 20px;
  padding: 0 15px;
  width: 180px;
  float: left;
}
.br-support__browser-icon {
  fill: #E4E7E7;
  width: 150px;
}
.br-support__browser-name {
  margin-top: 20px;
  text-transform: uppercase;
  color: #E4E7E7;
  font-size: 13px;
}
.cookies {
  position: fixed;
  width: 100%;
  z-index: 80;
  bottom: 0;
  left: 0;
  background-color: #073554;
  padding: 20px 30px;
  font-family: 'Avenir Next';
  display: none;
}
.cookies__title {
  font-size: 25px;
  color: #fff;
}
.cookies__subtitle {
  font-size: 14px;
  color: #fff;
  padding-left: 40px;
}
.cookies__button {
  background-color: #00a99d;
  color: #fff;
  font-size: 14px;
  line-height: 30px;
  width: 150px;
  text-transform: uppercase;
  border-radius: 4px;
  transition: background-color 0.25s ease;
}
.cookies__button:hover {
  background-color: #00ccbe;
}
.icon {
  font-size: 14px;
  width: 2em;
  height: 2em;
  color: #294260;
  cursor: pointer;
  display: inline-block;
  transition-duration: 0.25s;
  transition-easing: ease;
}
svg.icon {
  fill: #294260;
  transition-property: fill, filter;
  -webkit-transition-property: fill, -webkit-filter;
}
svg.icon:hover {
  fill: #00ffd2;
}
svg.icon.is-active {
  fill: #00ffd2;
}
.score svg {
  width: 100%;
  max-width: 100%;
}
.score__wrapper {
  position: absolute;
  top: 0;
  left: 0;
  padding: 25px;
  width: 100%;
  height: 100%;
}
.score__wrapper:after {
  display: inline-block;
  content: '';
  position: relative;
  height: 100%;
  vertical-align: middle;
}
.score__content {
  font-size: 0;
  max-width: 100%;
  width: 100%;
  padding: 0 3px;
}
.score__item {
  font-size: 16px;
  max-width: 100%;
  line-height: 1;
}
.score__item:first-child:not(:last-child) {
  padding-right: 7px;
  /*.score__value, 
			.score__value-reveal {
				font-size: 45px;
				font-weight: 600;
			}*/
}
.score__item:not(:first-child) {
  border-left: 1px solid #c2c2c2;
  padding-left: 7px;
}
.score__label-wrapper {
  color: currentcolor;
  position: relative;
}
.score__label-wrapper:before {
  content: '';
  margin-right: 4px;
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: currentcolor;
  border-radius: 2px;
  vertical-align: top;
  position: relative;
  top: 2px;
  box-shadow: 0 0 3px;
  transition: color 0.25s ease;
}
.score__label {
  font-size: 10px;
  line-height: 1.2;
  display: inline;
  color: #fff;
  vertical-align: top;
  padding-bottom: 5px;
  letter-spacing: 1px;
}
.score__value {
  color: #fff;
  font-size: 35px;
  line-height: 1;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}
.score__value-reveal {
  display: inline-block;
  content: attr(data-full-val);
  font-size: 14px;
  line-height: 20px;
  margin-top: -11px;
  padding: 0 5px;
  min-width: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  text-align: center;
  background-color: #294260;
  border-radius: 3px;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.25s ease;
  color: #fff;
}
.score__value-reveal:hover {
  opacity: 1;
}
.score__progress {
  width: 34px;
  display: inline-block;
  line-height: 32px;
  font-size: 8px;
  text-align: center;
  color: #00ffd2;
  border: 2px solid currentcolor;
  border-radius: 50%;
  box-shadow: 0 0 3px;
  font-weight: 600;
}
.score__progress--red {
  color: #b7185f;
}
.score__progress--big {
  font-size: 10px;
  width: 44px;
  line-height: 42px;
}
/*----------  Quick actions styles  ----------*/
.qa {
  position: absolute;
  z-index: 999;
  display: none;
}
.qa.is-visible {
  display: block;
}
.qa .resources-icon {
  width: 20px;
}
.qa .qw-icon {
  width: 20px;
}
.qa__icon {
  width: 60%;
  max-height: 100%;
  max-width: 20px;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  fill: #32568d;
  backface-visibility: hidden;
  pointer-events: none;
  transition: fill 0.25s ease, filter 0.25s ease, -webkit-filter 0.25s ease;
}
.qa__name {
  text-transform: uppercase;
  font-size: 10px;
  color: #5574a0;
  transition: color 0.25s ease, text-shadow 0.25s ease;
  white-space: nowrap;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 100%;
  margin-left: 13px;
  line-height: 16px;
  margin-top: -8px;
  backface-visibility: hidden;
}
.qa__item {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  box-shadow: inset 0 0 0 2px #32568d;
  position: absolute;
  z-index: 1;
  cursor: pointer;
  margin-top: -18px;
  margin-left: -18px;
}
.qa__item:after,
.qa__item:before,
.qa__item .wave-item {
  content: '';
  border: 1px solid #32568d;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  width: 100%;
  height: 100%;
  z-index: 0;
  transition: transform 0.25s ease, opacity 0.25s ease;
  pointer-events: none;
}
.qa__item:before {
  opacity: 0.5;
}
.qa__item:after {
  transform: translateY(-50%) translateX(-50%) scale(2.5);
  opacity: 0;
}
.qa__item .wave-item {
  transform: translateY(-50%) translateX(-50%) scale(2.2);
  opacity: 0;
}
.qa__item:hover .qa__icon {
  fill: #00ffd2;
}
.qa__item:hover:before {
  transform: translateY(-50%) translateX(-50%) scale(1.3);
}
.qa__item:hover:after {
  transform: translateY(-50%) translateX(-50%) scale(1.6);
  opacity: 0.3;
}
.qa__item:hover .wave-item {
  transform: translateY(-50%) translateX(-50%) scale(1.9);
  opacity: 0.1;
}
.qa__item:hover .qa__name {
  color: #00ffd2;
  text-shadow: 0 0 7px rgba(6, 222, 212, 0.9);
}
.qa--static {
  left: 50%;
  transform: translateY(-50%);
  margin-left: 10px;
}
.qa--static .qa__item {
  margin-top: 0;
  margin-left: 0;
  position: relative;
}
.qa--static .qa__item:not(:last-child) {
  margin-bottom: 25px;
}
.qa--left {
  transform: translateY(-50%) translateX(-100%);
  margin-left: -10px;
}
.qa--left .qa__name {
  left: 0;
  margin-left: -13px;
  transform: translateX(-100%);
}
.dropdown {
  text-transform: uppercase;
  display: inline-block;
  vertical-align: middle;
  min-width: 160px;
  max-width: 190px;
  font-size: 0;
  position: relative;
  user-select: none;
  z-index: 5;
}
.dropdown.is-active {
  z-index: 10;
}
.dropdown .ps-scrollbar-y-rail {
  z-index: 5;
}
.dropdown__box {
  position: relative;
  width: 100%;
  padding: 5px 0;
  cursor: pointer;
  padding-right: 20px;
  white-space: nowrap;
  font-size: 1px;
}
.dropdown__selected {
  font-size: 15px;
  line-height: 15px;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
  display: inline-block;
}
.dropdown__carret {
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -3px;
  display: inline-block;
}
.dropdown__carret:after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  border-color: #08b1ff transparent transparent transparent;
  transition: transform 0.2s ease;
  display: block;
}
.dropdown__carret.is-active:after {
  transform: rotate(180deg);
}
.dropdown__list {
  width: 100%;
  border: 1px solid #2a426a;
  background-color: #253251;
  padding: 5px 0;
  position: absolute;
  top: 100%;
  z-index: 5;
  background-visibility: hidden;
}
.dropdown__item {
  font-size: 15px;
  line-height: 15px;
  color: #7493c3;
  padding: 10px 10px;
  cursor: pointer;
  transition: background 0.25s ease, color 0.25s ease;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  z-index: 1;
}
.dropdown__item:hover,
.dropdown__item.is-active {
  background: #244677;
  color: #fff;
}
.dropdown--light {
  box-shadow: 0 0 0 1px #a9afb1;
}
.dropdown--light .dropdown__item,
.dropdown--light .dropdown__selected {
  color: #09c8bf;
  text-align: left;
}
.dropdown--light .dropdown__carret:after {
  border-color: #37505b transparent transparent;
}
.dropdown--light .dropdown__list {
  background-color: #fff;
  box-shadow: 0 0 0 1px #a9afb1;
  border: 0;
}
.dropdown--light .dropdown__item.is-active,
.dropdown--light .dropdown__item:hover {
  background-color: #09c8bf;
  color: #fff;
}
.dropdown--round {
  max-width: 215px;
}
.dropdown--round .dropdown__box {
  padding: 0;
}
.dropdown--round .dropdown__selected {
  font-size: 13px;
  color: #fff;
  padding: 0 20px;
  width: calc(100% - 46px);
}
.dropdown--round .dropdown__carret {
  height: 36px;
  width: 40px;
  top: 0;
  margin-top: 0;
  position: relative;
  padding: 0 15px;
  margin-left: 6px;
  backface-visibility: hidden;
}
.dropdown--round .dropdown__carret:after,
.dropdown--round .dropdown__carret:before {
  position: absolute;
  border: none;
  height: 2px;
  width: 8px;
  top: 50%;
  margin-top: -1px;
  background-color: #fff;
  content: '';
  transition: transform 0.25s ease;
}
.dropdown--round .dropdown__carret:after {
  left: 50%;
  margin-left: -1px;
  transform: rotate(-45deg);
}
.dropdown--round .dropdown__carret:before {
  right: 50%;
  transform: rotate(45deg);
  margin-right: -2px;
}
.dropdown--round .dropdown__carret.is-active:after {
  transform: rotate(45deg);
}
.dropdown--round .dropdown__carret.is-active:before {
  transform: rotate(-45deg);
}
.dropdown--round .dropdown__carret,
.dropdown--round .dropdown__selected {
  line-height: 36px;
  background-color: #091a33;
  border: 1px solid #303c4b;
  border-radius: 18px;
  vertical-align: top;
}
.dropdown--round .dropdown__list {
  background-color: #091a33;
  border: 1px solid #303c4b;
  border-radius: 18px;
  padding: 0;
  margin-top: 2px;
}
.dropdown--round .dropdown__item {
  font-size: 13px;
  color: #9ea3b4;
}
.dropdown--round .dropdown__item.is-active {
  color: #fff;
}
.dropdown--lighter .dropdown__carret,
.dropdown--lighter .dropdown__selected {
  border: none;
  background-color: transparent;
}
.dropdown--lighter .dropdown__carret {
  position: absolute;
}
.dropdown--lighter .dropdown__selected {
  padding-right: 40px;
  width: 100%;
}
.dropdown--lighter .dropdown__box {
  background: #1c375e;
  border: 1px solid #3e475e;
  border-radius: 18px;
}
.dropdown--lighter .dropdown__list {
  border: 1px solid #3e475e;
}
.dropdown--squared {
  background-color: #2a3537;
}
.dropdown--squared .dropdown__box {
  padding-left: 10px;
  padding-right: 25px;
}
.dropdown--squared .dropdown__carret {
  right: 10px;
}
.dropdown--squared .dropdown__selected {
  color: #fff;
  line-height: 20px;
  letter-spacing: 1px;
  text-transform: none;
}
.dropdown--squared .dropdown__list {
  background-color: #2a3537;
  padding: 0;
}
.dropdown--squared .dropdown__item {
  margin: 0;
  color: #fff;
  letter-spacing: 1px;
  text-transform: none;
}
.dropdown--blue {
  color: #799bcd;
  box-shadow: 0 0 0 1px #274e7b;
  background-color: #10253e;
}
.dropdown--blue .dropdown__selected,
.dropdown--blue .dropdown__item {
  color: #799bcd;
}
.dropdown--blue .dropdown__list {
  border-color: #274e7b;
  background-color: #10253e;
}
.preloader__text {
  font-size: 16px;
  position: absolute;
  bottom: 0;
  left: 50%;
  white-space: nowrap;
  transform: translateX(-50%) translateY(15px);
  padding-bottom: 5px;
}
.preloader--centered {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 99;
}
.preloader--centered .preloader__wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -30px;
  margin-left: -30px;
}
.preloader--bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  height: auto;
  min-height: 80px;
  width: 100%;
  z-index: 99;
}
.preloader--bottom .preloader__wrapper {
  position: relative;
  top: 0;
  margin-top: 20px;
}
.preloader--bottom .preloader__objects {
  margin: 0 auto;
}
.preloader--bottom .preloader__text {
  position: relative;
  left: 0;
  width: 100%;
  text-align: center;
  transform: none;
}
.preloader--page {
  position: fixed;
  background-color: #fff;
  z-index: 999;
}
.preloader__wrapper {
  position: relative;
}
.preloader__objects {
  width: 60px;
  height: 60px;
  animation: loading-spin 1s infinite;
  position: relative;
}
.preloader__object {
  width: 20px;
  height: 20px;
  background-color: #08b1ff;
  float: left;
  border-radius: 50%;
  margin-right: 20px;
  margin-bottom: 20px;
}
.preloader__object:nth-child(2n+0) {
  margin-right: 0px;
}
.preloader__object--1 {
  animation: object_one 1s infinite;
}
.preloader__object--2 {
  animation: object_two 1s infinite;
}
.preloader__object--3 {
  animation: object_three 1s infinite;
}
.preloader__object--4 {
  animation: object_four 1s infinite;
}
@keyframes loading-spin {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes object_one {
  50% {
    transform: translate(20px, 20px);
  }
}
@keyframes object_two {
  50% {
    transform: translate(-20px, 20px);
  }
}
@keyframes object_three {
  50% {
    transform: translate(20px, -20px);
  }
}
@keyframes object_four {
  50% {
    transform: translate(-20px, -20px);
  }
}
.help__title {
  color: #08b1ff;
  padding-bottom: 3px;
}
.help__content {
  color: #fff;
  line-height: 14px;
  margin: 10px 0;
}
.help__tooltip {
  max-width: 220px;
  border: 2px solid #2d4876;
  border-radius: 5px;
  background: #0f2245;
  color: #05ded4;
}
.help__tooltip .tooltipster-content {
  font-size: 12px;
  line-height: 12px;
  padding: 14px 15px;
}
.ui-widget-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(9, 23, 37, 0.8);
}
.ui-dialog {
  padding: 0;
  background-color: rgba(9, 23, 37, 0.8);
  z-index: 9999;
  outline: none;
  border-radius: 9px;
  border: 1px solid #0395b9;
  overflow: hidden;
  padding: 10px 20px;
  color: #08b1ff;
}
.ui-dialog .ui-dialog-buttonpane {
  text-align: center;
}
.ui-dialog .ui-dialog-buttonset {
  float: none;
  display: inline-block;
}
.ui-dialog .ui-dialog-title {
  color: currentcolor;
}
.ui-dialog .ui-dialog-titlebar {
  cursor: move;
  text-transform: uppercase;
  position: relative;
  margin-bottom: 20px;
  margin-top: 10px;
}
.ui-dialog .ui-dialog-titlebar .ui-button {
  width: 26px;
  height: 26px;
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -13px;
  margin-right: -2px;
  font-size: 0;
}
.ui-dialog .ui-dialog-titlebar .ui-button span {
  display: none;
}
.ui-dialog .ui-dialog-titlebar .ui-button:after,
.ui-dialog .ui-dialog-titlebar .ui-button:before {
  position: absolute;
  top: 50%;
  height: 2px;
  width: 100%;
  background-color: currentcolor;
  left: 0;
  content: '';
  display: block;
  transition: transform 0.15s ease;
}
.ui-dialog .ui-dialog-titlebar .ui-button:after {
  transform: rotate(-45deg);
}
.ui-dialog .ui-dialog-titlebar .ui-button:before {
  transform: rotate(45deg);
}
.ui-dialog .ui-dialog-titlebar .ui-button:hover:after {
  transform: rotate(-35deg);
}
.ui-dialog .ui-dialog-titlebar .ui-button:hover:before {
  transform: rotate(35deg);
}
.ui-dialog .ui-dialog-content {
  border-top: 1px solid #06607d;
  min-height: 60px !important;
  padding: 20px 0;
}
.ui-dialog .ui-dialog-content * {
  color: #fff;
  font-size: 15px;
  font-family: 'Avenir Next';
  font-weight: 500;
  word-break: break-word;
}
.ui-dialog .ui-dialog-buttonpane {
  margin-top: 3px;
  margin-bottom: 8px;
}
.ui-dialog .ui-dialog-buttonpane .ui-dialog-buttonset {
  width: 100%;
}
.ui-dialog .ui-dialog-buttonpane .ui-button {
  border: 1px solid #22345c;
  background-color: #1b2949;
  padding: 15px 0;
  border-radius: 0;
  width: 47%;
  transition: background-color 0.25s ease;
  border-radius: 2px;
  color: #fff;
}
.ui-dialog .ui-dialog-buttonpane .ui-button:first-child {
  float: left;
}
.ui-dialog .ui-dialog-buttonpane .ui-button:last-child {
  float: right;
}
.ui-dialog .ui-dialog-buttonpane .ui-button:hover {
  background-color: #08b1ff;
}
.ui-dialog .ui-dialog-buttonpane .ui-button-text {
  color: #fff;
  font-size: 16px;
}
.ui-dialog.error-dialog {
  color: #fe006d;
}
.ui-dialog.error-dialog .ui-dialog-titlebar .ui-button span,
.ui-dialog.error-dialog .ui-dialog-titlebar .ui-button:after,
.ui-dialog.error-dialog .ui-dialog-titlebar .ui-button:before {
  display: none;
}
.ui-dialog.error-dialog .ui-dialog-titlebar .ui-button:before {
  display: block;
  width: 100%;
  height: 100%;
  border: 2px solid currentcolor;
  border-radius: 50%;
  content: '!';
  top: 0;
  background-color: transparent;
  transform: none;
  font-size: 20px;
  line-height: 23px;
}
.lightbox {
  border: 1px solid #0395b9;
  border-radius: 9px;
  overflow: hidden;
  padding: 0 20px;
  min-width: 350px;
  max-width: 90%;
  color: #08b1ff;
  background-color: rgba(9, 23, 37, 0.8);
  position: relative;
  display: inline-block;
  text-align: left;
}
.lightbox.error-dialog {
  color: #fe006d;
  border-color: currentcolor;
}
.lightbox__wrapper {
  position: relative;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
.lightbox__main-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  display: table;
  z-index: 999;
  height: 100%;
  width: 100%;
  font-size: 0;
}
.lightbox__overlay {
  z-index: 998;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(9, 23, 37, 0.8);
}
.lightbox__header {
  border-bottom: 1px solid #06607d;
  padding: 15px 0;
}
.lightbox__close {
  position: absolute;
  width: 26px;
  height: 26px;
  right: 0;
  top: 50%;
  margin-top: -13px;
}
.lightbox__close:after,
.lightbox__close:before {
  content: '';
  width: 100%;
  height: 2px;
  background-color: currentcolor;
  position: absolute;
  top: 50%;
  left: 0;
  transform-origin: center center;
  transition: transform 0.15s ease;
}
.lightbox__close:after {
  transform: rotate(-45deg);
}
.lightbox__close:before {
  transform: rotate(45deg);
}
.lightbox__close:hover:before {
  animation: flip 0.2s ease;
}
.lightbox__close:hover:after {
  animation: backFlip 0.2s ease;
}
.lightbox__title {
  color: currentcolor;
  text-transform: uppercase;
  font-size: 15px;
  padding-right: 30px;
}
.lightbox__text-wrapper {
  padding: 20px 0;
}
.lightbox__text {
  color: #fff;
  font-size: 15px;
  font-family: 'Avenir Next';
  font-weight: 500;
  word-break: break-word;
}
.lightbox__buttons {
  padding-bottom: 15px;
  margin: 0 -10px;
}
.lightbox__button {
  border: 1px solid #22345c;
  background-color: #1b2949;
  padding: 15px 0;
  transition: background-color 0.25s ease;
  border-radius: 2px;
  color: #fff;
  font-size: 15px;
  text-align: center;
  width: 100%;
}
.lightbox__button-wrapper {
  width: 50%;
  padding: 0 10px;
  display: inline-block;
}
.lightbox__button-wrapper:first-child:last-child {
  width: 100%;
}
.lightbox__button:hover {
  background-color: #08b1ff;
}
.lightbox__button.is-disabled {
  cursor: default;
  opacity: 0.5;
  pointer-events: none;
}
.lightbox__actions {
  display: flex;
  justify-content: space-around;
}
.lightbox--fixed .lightbox__overlay,
.lightbox--fixed .lightbox__main-wrapper {
  position: fixed;
}
.lightbox--fixed .lightbox__title {
  line-height: 1;
}
.lightbox--fixed .lightbox__field {
  font-size: 15px;
  line-height: 1.5;
  padding: 5px;
  width: 100%;
  background-color: transparent;
  box-shadow: 0 0 5px 1px #06607d;
  color: #fff;
}
.lightbox__list {
  margin: 10px 0;
  padding-right: 10px;
  margin-right: -10px;
  min-width: 100px;
}
.lightbox__list-item {
  font-size: 15px;
  padding: 5px 10px;
  border-radius: 4px;
  background-color: #06607d;
  color: #fff;
  margin: 5px 0;
  cursor: pointer;
  transition: box-shadow 0.25s ease;
}
.lightbox__list-item:hover {
  box-shadow: 0 0 3px 1px #06607d;
}
.lightbox__list-item.is-active {
  background-color: #08b1ff;
}
.legal-info {
  display: none;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  animation: fadeIn 0.25s ease;
  background-color: rgba(255, 255, 255, 0.01);
}
.legal-info__header {
  color: #00ffd2;
  margin-bottom: 40px;
}
.legal-info__content {
  border: 1px solid #024266;
  border-radius: 8px;
  padding: 25px 20px;
  max-height: 60vh;
  position: relative;
  overflow: hidden;
}
.legal-info p {
  color: #fff;
  font-size: 13px;
}
.legal-info p:not(:last-child) {
  margin-bottom: 30px;
}
.legal-info a {
  color: #00ffd2;
}
.legal-info__close {
  text-transform: uppercase;
  font-size: 13px;
  margin-top: 50px;
  display: inline-block;
  cursor: pointer;
  color: #00ffd2;
}
.legal-info__close .th-close-btn {
  width: 30px;
  height: 30px;
  margin-left: 10px;
  color: #00ffd2;
  box-shadow: 0 0 5px;
}
.menu-btn {
  width: 24px;
  height: 16px;
  position: relative;
  z-index: 1;
  transform: rotate(0deg);
  border: 10px solid transparent;
  box-sizing: content-box;
  cursor: pointer;
  margin: 4px 0;
}
.menu-btn span,
.menu-btn:before,
.menu-btn:after {
  background-color: #294260;
  display: block;
  content: '';
  position: absolute;
  height: 2px;
  width: 100%;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.25s ease;
}
.menu-btn:before,
.menu-btn:after {
  top: calc(50% - 1px);
}
.menu-btn span:first-child {
  top: 0px;
}
.menu-btn span:last-child {
  top: calc(100% - 2px);
}
.menu-btn.is-active span {
  top: calc(50% - 1px);
  width: 0%;
  left: 50%;
  opacity: 0;
}
.menu-btn.is-active:before {
  transform: rotate(-45deg);
}
.menu-btn.is-active:after {
  transform: rotate(45deg);
}
.menu-btn.is-active span,
.menu-btn.is-active:before,
.menu-btn.is-active:after {
  background-color: #08b1ff;
}
.menu-btn:hover span,
.menu-btn:hover:before,
.menu-btn:hover:after {
  background-color: #08b1ff;
}
.th-header {
  color: #00ffd2;
}
.th-header__content {
  padding-left: 30px;
  position: relative;
}
.th-header__content:before {
  position: absolute;
  content: '';
  width: 4px;
  height: 100%;
  background-color: currentcolor;
  left: 0;
  top: 0;
  box-shadow: 0 0 5px;
}
.th-header__hint:not([class*="l-ttr"]),
.th-header__title:not([class*="l-ttr"]) {
  text-transform: uppercase;
}
.th-header__hint--cap,
.th-header__title--cap {
  text-transform: capitalize;
}
.th-header__hint {
  font-size: 12px;
  padding-bottom: 13px;
  padding-top: 5px;
  line-height: 1;
}
.th-header__hint:first-child {
  padding-top: 0;
}
.th-header__hint:before {
  display: inline-block;
  content: 'A';
  width: 0;
  visibility: hidden;
}
.th-header__title {
  color: #fff;
  font-size: 30px;
  line-height: 1.2;
  margin-top: -3px;
  margin-bottom: -9px;
}
.th-header__title:not(:last-of-type) {
  padding-bottom: 15px;
}
.th-header__title:before {
  display: inline-block;
  content: 'A';
  width: 0;
  visibility: hidden;
}
.th-header__title--big {
  font-size: 40px;
}
.th-header__title--small {
  font-size: 25px;
}
.th-header__help {
  text-transform: initial;
  color: #fff;
}
.dashboard {
  width: 100%;
}
.dashboard__tooltip {
  color: #fff;
}
.dashboard__header {
  background-color: #08b1ff;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-right: 20px;
  line-height: 45px;
}
.dashboard__nav {
  background-color: #00a2ed;
}
.dashboard .th-title {
  color: #fff;
  white-space: nowrap;
  letter-spacing: 1px;
}
.dashboard__btn {
  background-color: #08b1ff;
  font-size: 0;
  padding: 14px 0;
  cursor: pointer;
  position: relative;
  width: 45px;
  text-align: center;
}
.dashboard__icon {
  width: 17px;
  height: 17px;
  fill: #fff;
}
.dashboard__wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  min-width: 390px;
  top: 0;
  left: 0;
  background-color: #022b3e;
  background: linear-gradient(to bottom, #010a13 0, #022b3e 100%);
  animation: fadeIn 0.25s ease;
}
.dashboard__content {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  transform: translateY(-50%);
}
.dashboard__title {
  color: #08b1ff;
  font-size: 45px;
  letter-spacing: 1px;
}
.dashboard__date {
  color: #5fa6c5;
  font-size: 11px;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding-top: 5px;
}
.dashboard__app-list {
  font-size: 0;
  margin-top: 47px;
  padding: 0 45px;
  white-space: initial;
}
.dashboard__app-item {
  border: 3px solid #233f69;
  border-radius: 50%;
  overflow: hidden;
  width: 50px;
  height: 50px;
  margin: 13px 15px;
}
.dashboard__app-item a {
  border: 8px solid transparent;
}
.dashboard__app-item .icon {
  width: 100%;
  height: 100%;
  fill: #08b1ff;
  transition: fill 0.25s ease;
}
.dashboard__app-item:hover .icon,
.dashboard__app-item.active .icon {
  fill: #02f6ea;
}
.th-line {
  white-space: normal;
  font-size: 0;
  font-family: 'Avenir Next';
  font-weight: normal;
  width: 100%;
  display: inline-table;
}
.th-line__wrapper .ps-container {
  margin-right: -15px;
  padding-right: 15px;
  max-height: 170px !important;
}
.th-line__wrapper td,
.th-line__wrapper th {
  padding-right: 5px;
}
.th-line:after {
  display: block;
  content: '';
  clear: both;
}
.th-line.row {
  margin-left: 0;
  margin-right: 0;
}
.th-line .column {
  padding-left: 0;
  padding-right: 0;
  vertical-align: middle;
  display: table-cell;
}
.th-line .column,
.th-line .column:last-child {
  float: none;
}
.th-line__label {
  text-transform: uppercase;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #f0f1f3;
  font-size: 12px;
  padding-right: 15px;
  font-weight: 300;
}
.th-line__view {
  display: table-cell;
  width: 100%;
  vertical-align: middle;
}
.th-line__progress {
  background-color: #1a3357;
  height: 9px;
  border-radius: 4px;
  width: 100%;
  position: relative;
}
.th-line__progress-bar {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 0%;
  border-radius: 5px;
  background-color: currentcolor;
  box-shadow: 0 0 3px;
}
.th-line__data {
  white-space: nowrap;
  width: auto;
  display: table-cell;
  vertical-align: middle;
  padding-left: 12px;
  position: relative;
}
.th-line__state,
.th-line__value,
.th-line__note {
  background-color: #0f3052;
  display: inline-block;
  vertical-align: middle;
  font-size: 10px;
  text-align: center;
}
.th-line__state-wrapper {
  position: absolute;
  right: 100%;
  top: 0;
  transform: translateY(-90%);
  letter-spacing: 10px;
  white-space: nowrap;
}
.th-line__state,
.th-line__note {
  text-transform: capitalize;
  border-radius: 8px;
  padding: 3px 12px;
  line-height: 12px;
  color: #7e96c4;
  letter-spacing: 1px;
}
.th-line__state--colorful,
.th-line__note--colorful {
  color: currentcolor;
  background-color: currentcolor;
}
.th-line__state--colorful span,
.th-line__note--colorful span {
  color: #fff;
}
.th-line__state-container {
  min-width: 65px;
  color: inherit;
  text-align: right;
}
.th-line__value {
  min-width: 24px;
  height: 24px;
  border-radius: 12px;
  line-height: 26px;
  overflow: hidden;
  color: #fff;
  text-align: center;
  padding: 0 9px;
  white-space: nowrap;
}
.th-line__extra {
  margin-left: 10px;
  width: 90px;
}
.th-line__table-wrapper {
  border: 1px solid #294260;
  padding-right: 20px;
  padding-left: 10px;
  padding-bottom: 10px;
  border-radius: 4px;
}
.th-line__no-data {
  font-size: 12px;
  color: #fff;
  font-weight: 300;
}
.th-line__blob {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: currentcolor;
  display: block;
}
.th-line__blob--false {
  color: #fe006d;
}
.th-line__blob--true {
  color: #09c8bf;
}
.accordion {
  border: 1px solid #144a75;
  border-radius: 8px;
  padding: 0 14px;
  position: relative;
  line-height: 0;
  transition: box-shadow 0.25s ease, opacity 0.1s ease, border-color 0.1s ease;
  color: #00ffd2;
}
.accordion:not(:last-child) {
  margin-bottom: 30px;
}
.accordion__header {
  padding-left: 20px;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-right: 5px solid transparent;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  position: relative;
  display: inline-table;
  width: 100%;
}
.accordion__header:before {
  content: '';
  display: inline-block;
  width: 22px;
  height: 22px;
  background-color: #00ffd2;
  border-radius: 4px;
  vertical-align: middle;
  position: absolute;
  top: 50%;
  margin-top: -11px;
  left: 0;
  transition: background-color 0.25s ease;
  background-color: currentcolor;
}
.accordion__name {
  color: #fff;
  font-size: 20px;
  line-height: 22px;
  position: relative;
  padding-left: 20px;
  font-family: 'Avenir Next';
  font-weight: normal;
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  word-break: break-word;
}
.accordion__actions {
  font-size: 0;
  display: flex;
  width: auto;
  vertical-align: middle;
  white-space: nowrap;
  padding-left: 15px;
}
.accordion__arrow {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  font-size: 0;
  width: 14px;
  height: 8px;
  transition: transform 0.25s ease;
  margin-left: 15px;
}
.accordion__arrow:after,
.accordion__arrow:before {
  content: '';
  display: block;
  width: 73%;
  height: 1px;
  background-color: #00baff;
  position: absolute;
  top: 0;
}
.accordion__arrow:before {
  transform-origin: center left;
  transform: rotate(45deg);
  left: 0;
}
.accordion__arrow:after {
  transform-origin: center right;
  transform: rotate(-45deg);
  right: 0;
}
.accordion__toggle {
  content: '';
  display: inline-block;
  width: 22px;
  height: 22px;
  background-color: #00ffd2;
  border-radius: 4px;
  vertical-align: middle;
  transition: background-color 0.25s ease;
  transition: box-shadow 0.25s ease;
  color: #144a75;
  background-color: currentcolor;
  position: relative;
}
.accordion__toggle:after,
.accordion__toggle:before {
  display: block;
  content: '';
  width: 80%;
  height: 2px;
  border-radius: 2px;
  background-color: #00ffd2;
  position: absolute;
  top: 50%;
  margin-top: -1px;
  left: 50%;
  transition: transform 0.25s ease;
}
.accordion__toggle:before {
  transform: translateX(-50%);
}
.accordion__toggle:after {
  transform: translateX(-50%) rotate(90deg);
}
.accordion__toggle:hover {
  box-shadow: 0 0 5px 1px;
}
.accordion.is-active .accordion__arrow {
  transform: rotate(180deg);
}
.accordion.is-active > .accordion__header .accordion__toggle:before {
  transform: translateX(-50%) rotate(45deg);
}
.accordion.is-active > .accordion__header .accordion__toggle:after {
  transform: translateX(-50%) rotate(135deg);
}
.accordion__content {
  position: relative;
  padding-bottom: 15px;
  display: none;
}
.accordion__content:before {
  content: '';
  display: block;
  border-top: 1px solid #203c61;
  margin-bottom: 15px;
}
.accordion__text {
  font-size: 13px;
  line-height: 1.6;
  color: #fff;
  padding: 0 8px;
  padding-bottom: 10px;
  font-family: 'Avenir Next';
  font-weight: normal;
}
.task {
  color: #0d4864;
  border: 1px solid currentcolor;
  border-radius: 8px;
  padding: 14px;
  padding-left: 37px;
  cursor: pointer;
  position: relative;
  user-select: none;
  display: inline-table;
  transition: color 0.25s ease, box-shadow 0.25s ease, opacity 0.1s ease;
  line-height: 0;
}
.task:not(:last-child) {
  margin-bottom: 25px;
}
.task:before {
  content: '';
  display: inline-block;
  width: 22px;
  height: 22px;
  background-color: #00ffd2;
  border-radius: 4px;
  vertical-align: middle;
  transition: background-color 0.25s ease;
  position: absolute;
  left: 15px;
  top: 50%;
  margin-top: -11px;
}
.task:hover {
  box-shadow: 0 0 10px currentcolor;
}
.task__name {
  color: #fff;
  font-size: 20px;
  line-height: 22px;
  position: relative;
  padding-left: 20px;
  word-break: break-word;
  display: table-cell;
  width: 100%;
  vertical-align: middle;
}
.task__actions {
  letter-spacing: 20px;
  font-size: 0;
  vertical-align: middle;
  display: table-cell;
  width: auto;
  padding-left: 15px;
  white-space: nowrap;
}
.task__check {
  width: 28px;
  height: 28px;
  border: 2px solid #00baff;
  position: relative;
  transition: border-color 0.25s ease;
}
.task__check:after {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -7px;
  margin-left: -7px;
  content: '';
  display: block;
  width: 15px;
  height: 9px;
  border: 2px solid #00baff;
  border-right: none;
  border-top: none;
  transform: rotate(-45deg);
  transition: opacity 0.25s ease;
}
.task:not(.task--done) {
  color: #af0f5f;
}
.task:not(.task--done):before {
  background-color: #af0f5f;
}
.task:not(.task--done) .task__check {
  border-color: #af0f5f;
}
.task:not(.task--done) .task__check:after {
  opacity: 0;
}
.dashboard {
  z-index: 20;
}
svg.dashboard__icon {
  fill: #fff;
}
.sidebar-base-styles {
  width: 390px;
  display: block;
  min-height: 10px;
  float: left;
}
@media screen and (max-width: 1280px) {
  .sidebar-base-styles {
    width: 360px;
  }
}
.galaxy-center-guide {
  position: absolute;
  top: 0;
  left: 390px;
  width: calc(100% - 390px);
  z-index: -999;
  height: 100%;
  visibility: hidden;
}
.galaxy-center-guide.overview-active {
  width: calc(100% - 500px);
}
@media screen and (max-width: 1280px) {
  .galaxy-center-guide {
    width: calc(100% - 360px);
    left: 360px;
  }
}
.app-main.shared-active {
  width: 100%;
  max-width: calc(100% - 390px);
  position: relative;
  height: 100%;
}
@media screen and (max-width: 1280px) {
  .app-main.shared-active {
    max-width: calc(100% - 360px);
  }
}
sidebar {
  width: 390px;
  display: block;
  min-height: 10px;
  float: left;
}
@media screen and (max-width: 1280px) {
  sidebar {
    width: 360px;
  }
}
.sidebar {
  width: 390px;
  display: block;
  min-height: 10px;
  float: left;
  position: relative;
  z-index: 10;
  background: linear-gradient(to right, rgba(25, 48, 79, 0.41) 0%, rgba(13, 23, 42, 0.41) 100%);
  overflow: hidden;
  font-size: 0;
  white-space: nowrap;
}
@media screen and (max-width: 1280px) {
  .sidebar {
    width: 360px;
  }
}
.sidebar:before {
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  height: 100%;
  width: 45px;
  background: rgba(0, 0, 0, 0.33);
  content: '';
}
.sidebar__header-content {
  width: calc(100% - 45px);
}
.sidebar__header .sidebar__actions {
  padding-bottom: 0;
}
.sidebar__actions {
  width: 45px;
  text-align: center;
  padding-bottom: 15px;
  font-size: 0;
}
.sidebar__actions--no-padding {
  padding: 0;
}
.sidebar__actions .icon-link {
  margin-bottom: 20px;
}
.sidebar__actions .icon-link {
  padding: 6px 0;
}
.sidebar__content {
  padding: 0 20px;
  width: calc(100% - 45px);
}
.sidebar__section {
  overflow: hidden;
  position: relative;
  width: 100%;
  display: inline-block;
  vertical-align: top;
}
.sidebar__section--navs {
  z-index: 1;
  padding-top: 25px;
}
.sidebar__section.overview-section {
  width: calc(100% - 45px);
  right: 0;
  z-index: 4;
}
.sidebar__section.overview-section .sidebar__content {
  width: 100%;
}
.sidebar__section.overview-section.is-hidden {
  z-index: 0;
}
.sidebar .footer {
  position: absolute;
  bottom: 0;
  left: 0;
}
.sidebar .ps-scrollbar-y-rail {
  z-index: 1;
}
.menu-trigger.nav {
  margin-bottom: 0;
  padding-top: 4px;
  padding-bottom: 3px;
}
.user {
  background-color: rgba(3, 15, 32, 0.77);
  font-size: 0;
  height: 50px;
  position: relative;
  z-index: 1;
  padding-left: 5px;
}
.user figure {
  white-space: nowrap;
  line-height: 50px;
}
.user__logo {
  width: 100%;
  max-width: 40px;
}
.user dropdown {
  display: block;
  line-height: 1;
}
.user figcaption {
  padding-left: 20px;
  padding-right: 100px;
}
.user .dropdown__item,
.user .dropdown__selected {
  font-size: 13px;
  line-height: 13px;
}
.view-info {
  border: 1px solid #213c67;
  border-radius: 8px;
  padding: 9px 15px;
}
.view-info__level {
  color: #08b1ff;
  font-size: 18px;
}
.view-info__id,
.view-info__name {
  color: #4a6da5;
  font-size: 13px;
}
@media screen and (max-width: 1150px) {
  .view-info {
    display: none;
  }
}
.org-score .score {
  margin-top: 35px;
  width: 230px;
}
.en-overview {
  margin-top: 40px;
}
.en-overview__list {
  margin-top: 35px;
  margin-bottom: 20px;
}
.en-overview__header {
  position: absolute;
  right: 0;
  top: -6px;
  transform: translateY(-100%);
}
@media screen and (max-width: 1280px) {
  .en-overview__value {
    position: static;
  }
  .en-overview__progress {
    width: 100%;
  }
}
.shared__container {
  width: 100%;
  max-width: 1030px;
  margin: 0 auto;
  position: relative;
}
.shared__header {
  padding-top: 70px;
  padding-bottom: 100px;
}
.shared--fluid .shared__container {
  max-width: none;
  padding: 0 40px;
}
.shared--fluid .row {
  max-width: none;
}
.shared__bi-health .shared__header {
  padding-top: 30px;
  padding-bottom: 50px;
}
.shared__bi-health .shared__header-content:before {
  color: #00ffd2;
}
.quick-wins__container {
  padding: 5px 10px;
  margin: -5px -10px;
}
.quick-wins .score__label {
  font-size: 9px;
  font-weight: 600;
}
.quick-wins .score__value {
  font-size: 32px;
}
.qw-score {
  position: absolute;
  right: 0;
  bottom: -35px;
}
.qw-score .score {
  width: 230px;
}
.qw-score .score__label-wrapper {
  transition: color 0.5s ease;
}
.milestones__container {
  padding: 5px 10px;
  margin: -5px -10px;
}
.milestones__item {
  color: #fe006d;
}
.milestones__check {
  width: 26px;
  height: 26px;
  border: 3px solid rgba(255, 255, 255, 0.25);
  margin-right: 12px;
  transition: box-shadow 0.125s ease;
  position: relative;
}
.milestones__check--lock,
.milestones__check--mark {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
}
.milestones__check--lock {
  z-index: 1;
  width: 100%;
  fill: #fe006d;
  transition: opacity 0.125s ease;
}
.milestones__check--lock path {
  transition: transform 0.25s ease;
}
.milestones__check--mark {
  z-index: 2;
  width: 100%;
}
.milestones__check--mark polyline {
  fill-rule: evenodd;
  stroke: #00ffd2;
  clip-rule: evenodd;
  stroke-width: 2;
  fill: none;
  stroke-dasharray: 23;
  transition: stroke-dashoffset 0.25s ease;
  stroke-dashoffset: -23;
}
.milestones__check:hover {
  box-shadow: 0 0 14px #00ffd2;
}
.milestones__item:not(.not-achieved) {
  color: #00ffd2;
}
.milestones__item:not(.not-achieved) .milestones__header:before {
  background-color: #06ded4;
}
.milestones__item:not(.not-achieved) .milestones__check .milestones__check--mark polyline {
  transition-delay: 0.25s;
  stroke-dashoffset: 0;
}
.milestones__item:not(.not-achieved) .milestones__check .milestones__check--lock {
  transition-delay: 0.125s;
  transition-duration: 0.25s;
  opacity: 0;
}
.milestones__item:not(.not-achieved) .milestones__check .milestones__check--lock path:first-child {
  transform: translateY(-3px);
}
.milestones__item:not(.not-achieved) .milestones__check .milestones__check--lock path:last-child {
  transform: translateY(3px);
}
.milestones__item--active {
  box-shadow: 0 0 10px #144a75;
}
.milestones__item--active .milestones__arrow {
  transform: rotate(180deg);
}
.milestones .calendar-icon {
  display: inline-block;
  vertical-align: middle;
  fill: #4a6da5;
  width: 25px;
  line-height: 1;
}
.milestones .calendar-icon__date {
  font-size: 42px;
  font-family: 'Avenir Next';
  font-weight: 500;
  font-weight: 600;
  text-anchor: middle;
}
.ml-progress {
  height: 9px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.ml-progress:before {
  position: absolute;
  height: 5px;
  width: 100%;
  left: 0;
  top: 50%;
  margin-top: -3px;
  background-color: #193656;
  border-radius: 2px;
  content: '';
}
.ml-progress__value {
  color: #fff;
  font-size: 9px;
  text-align: center;
  display: inline-block;
  line-height: 31px;
  width: 31px;
  background-color: #0f3052;
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  background-visibility: hidden;
  right: -5px;
  margin-top: -16px;
  top: 50%;
  transform: translateX(100%) translateZ(0);
}
.ml-progress__bar {
  width: 0%;
  height: 100%;
  border-radius: 4px;
  position: relative;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#42b289+0,13faa6+44,08dbd5+100 */
  background: #42b289;
  /* Old browsers */
  background: -moz-linear-gradient(left, #42b289 0%, #13faa6 44%, #08dbd5 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #42b289 0%, #13faa6 44%, #08dbd5 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #42b289 0%, #13faa6 44%, #08dbd5 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#42b289', endColorstr='#08dbd5', GradientType=1);
  /* IE6-9 */
  transition: width 0.5s ease;
}
.ml-progress.is-active .ml-progress__bar {
  animation: shadowFlash 0.5s ease alternate;
}
.resources {
  /*----------  #header styles  ----------*/
}
.resources .ps-scrollbar-y-rail {
  right: 0 !important;
}
.resources__container > .ps-scrollbar-y-rail {
  display: none !important;
}
.resources .row {
  max-width: 100%;
}
.resources .row .column {
  padding: 0;
}
.resources__wrapper--left {
  padding-right: 37px;
}
.resources__wrapper--right {
  padding-left: 33px;
}
@media screen and (max-width: 1190px) {
  .resources__wrapper--left {
    padding-right: 17px;
  }
  .resources__wrapper--right {
    padding-left: 13px;
  }
}
.resources__table {
  width: 100%;
}
.resources__table thead {
  color: #7493c3;
  font-size: 10px;
}
.resources__table th {
  font-weight: 500;
  line-height: 1.9;
  text-align: left;
}
.resources__table td {
  color: #fff;
  font-size: 13px;
}
.resources__table td:first-child,
.resources__table th:first-child {
  padding-left: 5px;
}
.resources__table td:last-child,
.resources__table th:last-child {
  padding-right: 5px;
}
.resources .trash-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -10px;
  margin-top: 2px;
  z-index: 5;
  transform: scale(1.3) translateY(-50%);
  opacity: 0;
  transition: transform 0.25s ease, opacity 0.25s ease, fill 0.25s ease;
}
.resources__item {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
  animation: slideLeftIn 0.25s ease alternate;
  transition: background-color 0.25s ease;
}
.resources__item td {
  padding: 10px 0;
  position: relative;
}
.resources__item td.date-cell {
  padding-right: 15px;
}
.resources__item td:after,
.resources__item td:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #06ded4;
  left: 0;
  opacity: 0;
  transition: opacity 0.25s ease;
  box-shadow: 0 0 6px #06ded4;
}
.resources__item td:first-child {
  line-height: 0;
}
.resources__item td:after {
  bottom: -1px;
}
.resources__item td:before {
  top: -1px;
}
.resources__item:hover,
.resources__item.is-active {
  background: rgba(2, 10, 21, 0.3);
}
.resources__item:hover td:before,
.resources__item.is-active td:before,
.resources__item:hover td:after,
.resources__item.is-active td:after {
  opacity: 1;
}
.resources__item__name {
  color: #fff;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 15px;
  word-break: break-all;
}
.resources__item__size {
  color: #fff;
  transition: opacity 0.25s ease;
}
.resources__item:hover .resources__item__size {
  opacity: 0;
}
.resources__item:hover .trash-icon {
  transform: scale(1) translateY(-50%);
  opacity: 1;
}
.resources__hidden-header {
  font-size: 0 !important;
}
.resources .th-file-icon {
  width: 100%;
  max-width: 38px;
  max-height: 47px;
  line-height: 1;
}
.resources__type-column {
  width: 17%;
}
.resources__date-column {
  width: 18%;
}
.resources__name-column {
  width: 52%;
}
.resources__wrapper {
  height: calc(100% - 20px);
}
.resources__header {
  position: absolute;
  bottom: 0;
  right: 0;
}
.resources__hint {
  font-size: 12px;
  color: #59749d;
  line-height: 1.2;
  margin-top: 10px;
}
@media screen and (max-width: 1190px) {
  .resources__date-column,
  .resources .date-cell {
    display: none;
  }
  .resources__size-column {
    width: 15%;
  }
}
/*----------  #upload module  ----------*/
.upload {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px dashed #084c6f;
  background-color: rgba(11, 26, 44, 0.43);
}
.upload__form {
  padding-bottom: 30px;
}
.upload__label-wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0 25px;
  z-index: 2;
  display: table;
  width: 100%;
}
.upload__label-text {
  display: table-cell;
  width: 100%;
  font-size: 14px;
  line-height: 30px;
  color: #526885;
  padding-left: 20px;
  border: 1px solid #55729b;
  text-transform: uppercase;
  transition: border-color 0.25s ease, box-shadow 0.25s ease;
}
.upload__label-text:hover {
  border-color: #08b1ff;
  box-shadow: 0 0 5px #08b1ff;
}
.upload__btn {
  color: #8ea2c1;
  line-height: 32px;
  font-size: 14px;
  background-color: #32568e;
  padding: 0 40px;
  width: auto;
  display: table-cell;
  border: 1px solid #32568e;
  transition: border-color 0.25s ease, background-color 0.25s ease, box-shadow 0.25s ease;
}
.upload__btn:hover:not(.is-disabled) {
  border-color: #08b1ff;
  box-shadow: 0 0 5px #08b1ff;
}
.upload__btn.is-disabled {
  background-color: #48628C;
  cursor: default;
}
.upload__icon {
  width: 100%;
  max-width: 70px;
  max-height: 90px;
  margin: 0 auto;
  display: inline-block;
  fill: #132a48;
}
.upload__hint {
  font-size: 18px;
  line-height: 1.3;
  color: #213d62;
  text-transform: uppercase;
  margin-top: 60px;
}
.upload__info {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.upload__dropzone {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
  transition: box-shadow 0.125s ease;
}
.upload__dropzone.in,
.upload__dropzone.hover {
  box-shadow: inset 0 0 25px #08b1ff;
}
.upload__dropzone.is-active {
  z-index: 5;
}
.upload__item {
  animation: slideLeftIn 0.25s ease alternate;
}
.upload__item .l-dis-tc {
  padding: 12px 0;
}
.upload__item:not(:first-child) .l-dis-tc {
  border-top: 1px solid #283646;
}
.upload__item__icon {
  width: 15px;
  margin-right: 20px;
  line-height: 0;
}
.upload__item__icon-tc {
  width: 35px;
}
.upload__item__name,
.upload__item__size {
  font-size: 13px;
  line-height: 1.2;
  color: #7c94b7;
}
.upload__item__name {
  text-overflow: ellipsis;
  display: inline-block;
  width: 90px;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: middle;
}
.upload__item__name,
.upload__item__size,
.upload__item__icon {
  margin-right: 20px;
}
.upload__item__cancel {
  width: 10px;
  height: 10px;
  position: relative;
  border: 7px solid transparent;
  box-sizing: content-box;
  margin-left: 20px;
}
.upload__item__cancel:after,
.upload__item__cancel:before {
  position: absolute;
  top: 50%;
  left: 0;
  height: 2px;
  width: 120%;
  background-color: #06ded4;
  content: '';
  display: block;
  margin-top: -1px;
  transition: box-shadow 0.25s ease;
}
.upload__item__cancel:after {
  transform: rotate(45deg);
}
.upload__item__cancel:before {
  transform: rotate(-45deg);
}
.upload__item__cancel:hover:after {
  animation: flip 0.25s ease alternate;
}
.upload__item__cancel:hover:after,
.upload__item__cancel:hover:before {
  box-shadow: 0 0 2px #06ded4;
}
.upload__item__progress-bar {
  width: 100%;
  background-color: #17283b;
  height: 6px;
  border-radius: 3px;
  position: relative;
}
.upload__item__progress {
  position: absolute;
  left: 0;
  height: 100%;
  border-radius: 3px;
  width: 0%;
  background-color: #00baff;
  box-shadow: 0 0 7px #00baff;
  transition: width 0.15s ease;
}
.upload__complete {
  color: #22497a;
  font-size: 10px;
  line-height: 24px;
  border-radius: 4px;
  background-color: #0a203c;
  text-transform: uppercase;
  padding: 0 10px;
  display: inline-block;
  white-space: nowrap;
}
.upload__list {
  position: relative;
  padding: 0 15px;
  padding-bottom: 18px;
  z-index: 1;
  display: table;
  width: 100%;
}
.upload__list:after {
  display: none;
  content: '';
  width: 100%;
  height: 1px;
  background-color: #00baff;
  position: absolute;
  bottom: 0;
  left: 0;
  box-shadow: 0 0 7px #00baff;
  opacity: 0.6;
  animation: slideUpIn 0.25s ease alternate;
}
.upload__list:not(:empty):after {
  display: block;
}
.upload__list-wrapper {
  position: relative;
  overflow: hidden;
  height: 100%;
  border-bottom: 64px solid transparent;
}
.upload__error {
  color: #fe006d;
  font-size: 13px;
}
/*----------  #preview modules  ----------*/
.preview {
  overflow: hidden;
}
.preview__icon {
  margin-right: 25px;
}
.preview__title {
  color: #fff;
  text-transform: capitalize;
  font-size: 24px;
  white-space: initial;
  width: 85%;
  word-break: break-word;
}
.preview__tabs {
  width: 100%;
  margin-top: 10px;
  padding-top: 3px;
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.preview__tab {
  width: 50%;
  float: left;
  overflow: hidden;
  cursor: pointer;
  position: relative;
}
.preview__tab:before {
  content: '';
  dispaly: block;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  height: 5px;
  width: 100%;
  border: 1px solid #2c3589;
  background-color: #00baff;
  transition: transform 0.25s ease;
}
.preview__tab:first-child:before {
  transform: translateX(100%);
}
.preview__tab:last-child:before {
  transform: translateX(-100%);
}
.preview__tab.is-active:before {
  transform: translateX(0);
}
.preview__tab-name {
  text-align: center;
  text-transform: uppercase;
  font-size: 12px;
  padding: 20px 0;
}
.preview__tab-content {
  animation: fadeIn 0.25s ease alternate;
}
/*----------  Details tab styles  ----------*/
.details__list {
  text-transform: uppercase;
  border: 1px solid #39485a;
  border-left: none;
  border-right: none;
  padding: 15px 0;
}
.details table {
  width: 100%;
}
.details__type {
  font-size: 10px;
  color: #4b6489;
  padding-right: 20px;
  text-transform: uppercase;
}
.details__value {
  font-size: 10px;
  color: #08b1ff;
}
.details__description {
  font-size: 12px;
  color: #8ee0fe;
}
.details__title {
  padding: 25px 0;
}
.details td:first-child {
  min-width: 125px;
}
.details td {
  padding: 5px 0;
}
/*----------  #video styles  ----------*/
.video-wrapper {
  margin-top: 10px;
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 0;
}
.video-wrapper video {
  background-color: #0c1223;
  outline: none;
}
.video-wrapper .video-js {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.video-wrapper .video-js .vjs-big-play-button {
  border: none;
  background-color: transparent;
  width: auto;
  height: auto;
  top: 50%;
  left: 50%;
  margin: 0;
  transform: translateX(-50%) translateY(-50%);
}
.video-wrapper .video-js .vjs-big-play-button:after,
.video-wrapper .video-js .vjs-big-play-button:before {
  width: 0;
  height: 0;
  border-style: solid;
  display: block;
  content: '';
}
.video-wrapper .video-js .vjs-big-play-button:after {
  border-width: 14px 0 14px 25px;
  border-color: transparent transparent transparent #08b1ff;
  position: relative;
  z-index: 1;
}
.video-wrapper .video-js .vjs-big-play-button:before {
  border-width: 11px 0 11px 20px;
  border-color: transparent transparent transparent #132a48;
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  margin-left: -1px;
  transform: translateY(-50%) translateX(-50%);
}
.video-wrapper .video-js .vjs-control {
  color: #08b1ff;
}
.video-wrapper .video-js .vjs-slider-bar,
.video-wrapper .video-js .vjs-volume-level {
  background-color: #08b1ff;
}
.video-wrapper .video-js .vjs-slider-bar:before {
  display: none;
}
.video-wrapper .video-js .vjs-slider {
  background-color: #1d253a;
}
.video-wrapper .video-js .vjs-progress-control {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 5px;
  transform: translateY(-100%);
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}
.video-wrapper .video-js .vjs-progress-control .vjs-slider {
  margin: 0;
}
.video-wrapper .video-js .vjs-load-progress,
.video-wrapper .video-js .vjs-progress-holder,
.video-wrapper .video-js .vjs-play-progress {
  height: 5px;
}
.video-wrapper .video-js .vjs-current-time,
.video-wrapper .video-js .vjs-time-divider,
.video-wrapper .video-js .vjs-duration {
  display: block;
}
.video-wrapper .video-js .vjs-fullscreen-control {
  position: absolute;
  right: 0;
  left: auto;
  top: 0;
}
.video-wrapper .video-js .vjs-control:before {
  font-size: 23px;
  line-height: 1.4;
}
.bi-health__container {
  margin: 0 -70px;
}
.bi-health__container .preloader {
  display: none;
}
.bi-health .th-title {
  display: inline-block;
  text-align: left;
  text-transform: uppercase;
  line-height: 1;
}
.bi-health .th-title span {
  position: relative;
  top: -1px;
}
.bi-health__title {
  padding-left: 55px;
}
.bi-health__column:first-child {
  border-right: 1px solid #364659;
  width: 58.33333%;
}
.bi-health__column:last-child {
  width: 41.66667%;
}
.bi-health .score {
  width: 190px;
}
.bi-health .score__label {
  font-size: 8px;
  vertical-align: top;
  position: relative;
  top: 2px;
  text-align: left;
}
.bi-health .score__value {
  font-size: 27px;
}
.bi-health .score__item:first-child:not(:last-child) {
  padding-right: 5px;
}
.bi-health .score__item:not(:first-child) {
  padding-left: 5px;
}
.bi-health__chart {
  text-align: center;
  padding: 25px 0;
}
.bi-health__chart .th-title {
  margin-bottom: 25px;
}
.bi-health__chart--single .score__label-wrapper {
  display: none;
}
.bi-health__chart--single .score__value {
  font-size: 45px;
}
.bi-health__charts-list {
  height: calc(100% - 24px);
}
.bi-health__charts-list .score__progress {
  position: absolute;
  top: 0;
  right: -20px;
  height: 34px;
}
.bi-health__charts-list .column {
  width: 33.33333%;
}
.bi-health__stats {
  color: #fff;
  font-size: 45px;
  margin-right: 15px;
  margin-top: 3px;
}
.bi-health__finance-title {
  font-size: 12px;
  color: #fff;
}
.bi-health__finance-list {
  margin-top: 25px;
}
.bi-health__finance-list .th-title {
  margin-bottom: 35px;
}
.bi-health__finance-list .th-line {
  padding-left: 20px;
  padding-right: 20px;
}
.bi-health__finance-list .th-line__label {
  text-align: right;
}
.bi-health__finance-list .th-line__value {
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 9px;
}
.bi-health__finance-list .th-line__wrapper {
  padding-left: 20px;
}
.bi-health .th-btn:not(:last-child) {
  margin-right: 6px;
}
@media screen and (max-width: 1641px) {
  .bi-health__charts-list {
    height: auto;
  }
  .bi-health__charts-list .column {
    width: 50%;
  }
  .bi-health__column:first-child {
    width: 50%;
  }
  .bi-health__column:last-child {
    width: 50%;
  }
}
@media screen and (max-width: 1381px) {
  .bi-health__charts-list .column {
    width: 100%;
  }
  .bi-health__column:first-child {
    width: 40%;
  }
  .bi-health__column:last-child {
    width: 60%;
  }
}
@media screen and (max-width: 1080px) {
  .bi-health__charts-list .column {
    width: 50%;
  }
  .bi-health__column:first-child {
    width: 100%;
  }
  .bi-health__column:last-child {
    width: 100%;
    margin-top: 50px;
  }
  .bi-health__row {
    height: auto;
  }
  .bi-health__finance-item {
    padding: 0 15px;
  }
}
@media screen and (max-width: 891px) {
  .bi-health__charts-list .column {
    width: 100%;
  }
  .bi-health__finance-item .column {
    width: 100%;
    text-align: left;
  }
  .bi-health__finance-title {
    padding-bottom: 10px;
  }
}
/*----------  Style list scroll  ----------*/
.shared-list-container .ps-scrollbar-y-rail {
  opacity: 0.6;
}
.breadcrumb {
  background-color: #0d2e50;
  padding: 0 5px;
  border-radius: 16px;
}
.breadcrumb .th-ask-icon {
  color: inherit;
  border-color: currentcolor;
  width: 15px;
  height: 15px;
  line-height: 15px;
  font-size: 12px;
  position: absolute;
  right: 4px;
  top: 50%;
  margin-top: -8px;
}
.breadcrumb__item {
  background-color: #213c67;
  font-size: 12px;
  line-height: 24px;
  border-radius: 12px;
  min-width: 80px;
  color: #0e2540;
  padding-left: 7px;
  padding-right: 7px;
  margin-right: 15px;
  text-transform: uppercase;
  transition: background-color 0.25s ease, color 0.25s ease;
  animation: zoomIn 0.25s ease alternate;
}
.breadcrumb__item:last-child {
  margin-right: 0;
}
.breadcrumb__item:hover,
.breadcrumb__item.is-active {
  background-color: #08b1ff;
  color: #fff;
}
.breadcrumb__star {
  color: inherit;
}
.overview {
  /*==============================================
	=            Overview action styles            =
	==============================================*/
  /*=====  End of Overview action styles  ======*/
}
.overview__action {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  color: #32568e;
  border: 2px solid currentcolor;
  position: fixed;
  right: 15px;
  top: 60px;
  transition: color 0.25s ease;
  cursor: pointer;
}
.overview__action:hover {
  color: #08b1ff;
}
.overview__action.is-active .overview__close {
  transform: translateY(-50%) translateX(-50%) scale(1);
  opacity: 1;
}
.overview__action.is-active .overview__open {
  transform: translateX(-50%) translateY(-50%) scale(0.5);
  opacity: 0;
}
.overview__open,
.overview__close {
  color: #08b1ff;
  user-select: none;
  transition: transform 0.25s ease, opacity 0.25s ease;
  backface-visibility: none;
  z-index: 1;
  overflow: hidden;
}
.overview__open {
  width: 20%;
  fill: #08b1ff;
  margin-top: 2px;
}
.overview__close {
  width: 40%;
  height: 40%;
  transform: translateX(-50%) translateY(-50%) scale(1.5);
  opacity: 0;
}
.overview__close:after,
.overview__close:before {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 3px;
  margin-top: -2px;
  background-color: currentcolor;
  content: '';
}
.overview__close:after {
  transform: rotate(45deg);
}
.overview__close:before {
  transform: rotate(-45deg);
}
.overview__wrapper {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 370px;
  background-color: rgba(9, 30, 59, 0.7);
  padding-left: 50px;
  padding-right: 40px;
  padding-top: 80px;
  padding-bottom: 50px;
}
.overview__wrapper .shared__header-content {
  color: #00ffd2;
}
.overview__header {
  margin-bottom: 40px;
}
.overview__content {
  font-size: 13px;
}
.overview__content p {
  color: #fff;
  line-height: 1.6;
}
.overview__content p:not(:last-child) {
  margin-bottom: 25px;
}
.overview__article {
  height: 100%;
  position: relative;
  padding-right: 10px;
}
.overview__article .ps-scrollbar-y-rail {
  right: 0 !important;
}
overview {
  animation: fadeIn 0.25s ease alternate;
}
.performance {
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background-color: rgba(3, 15, 32, 0.7);
  padding-left: 430px;
  padding-right: 15px;
}
.performance .ecg-icon {
  width: 32px;
  fill: #294260;
  position: relative;
  top: 3px;
  margin-right: 14px;
}
.performance__title {
  font-size: 13px;
}
.performance__state {
  margin-left: 10px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 2px solid #294260;
  position: relative;
}
.performance__state svg {
  width: 60%;
  fill: #db0974;
  display: block;
  position: relative;
  top: -1px;
  margin: 0 auto;
  pointer-events: none;
}
.performance__progress {
  width: 165px;
  height: 9px;
  position: relative;
  border-radius: 4px;
  top: -1px;
  background-color: #21406d;
  margin: 0 25px;
}
.performance__progress--bar {
  border-radius: 4px;
  position: absolute;
  background-color: currentcolor;
  height: 100%;
  left: 0;
  top: 0;
  box-shadow: 0 0 7px currentcolor;
}
.performance__label {
  text-transform: capitalize;
  color: #718fb7;
  font-size: 11px;
  line-height: 17px;
  background-color: #12293d;
  padding: 0 17px;
  padding-top: 1px;
  border-radius: 8px;
}
.performance__graph {
  border: 1px solid #042536;
  box-shadow: 0 0 0 1px #033544;
  border-radius: 15px;
  padding-left: 15px;
  padding-right: 5px;
  padding-bottom: 5px;
  padding-top: 5px;
  font-size: 0;
}
.performance__graph__title {
  text-transform: uppercase;
  color: #fff;
  font-size: 12px;
}
.performance__details {
  color: #10bade;
  font-size: 9px;
  text-transform: uppercase;
}
.performance__details-icon {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  line-height: 57px;
  border: 2px solid #033442;
  position: relative;
  margin-left: 6px;
  text-align: center;
}
.performance__details svg {
  fill: #00ffd2;
  width: 18px;
  display: inline-block;
  pointer-events: none;
}
.performance .wrap {
  display: table;
  width: 100%;
  height: 100%;
}
.performance .wrap-cell {
  display: table-cell;
  vertical-align: middle;
  white-space: nowrap;
}
.performance .wrap-cell:last-child {
  text-align: right;
}
.performance .wrap-cell:not(:last-child):not(:first-child) {
  text-align: center;
}
@media screen and (max-width: 1750px) {
  .performance__label {
    display: none;
  }
  .performance__progress {
    margin-right: 5px;
  }
}
@media screen and (max-width: 1450px) {
  .performance__progress {
    width: 130px;
    margin-left: 15px;
  }
  .performance__graph__title {
    font-size: 11px;
  }
}
@media screen and (max-width: 1350px) {
  .performance {
    padding-left: 400px;
  }
  .performance__progress {
    width: 100px;
  }
}
@media screen and (max-width: 1280px) {
  .performance {
    padding-left: 380px;
  }
}
@media screen and (max-width: 1240px) {
  .performance {
    padding-left: 360px;
  }
  .performance__title {
    display: none;
  }
  .performance .ecg-icon {
    display: none;
  }
  .performance__state {
    margin-left: 0;
  }
}
@media screen and (max-width: 1060px) {
  .performance__header {
    display: none;
  }
}
.footer {
  display: inline-block;
  margin-left: -5px;
  width: 266px;
}
.footer__text {
  font-size: 11px;
  line-height: 14px;
  color: #3d66a8;
}
.footer a {
  color: inherit;
}
.footer__logo {
  max-width: 67px;
  fill: #3d66a8;
  position: relative;
  left: -5px;
  max-height: 53px;
}
.footer__label {
  display: inline-block;
  background-color: #132a48;
  text-transform: uppercase;
  padding: 0 12px;
  line-height: 18px;
  font-size: 10px;
  border-radius: 2px;
  margin-bottom: 7px;
}
/*----------  Nav item styles and customizations  ----------*/
.nav {
  font-size: 0;
  white-space: nowrap;
}
.nav:not(:last-child) {
  margin-bottom: 25px;
}
.nav__icon-wrapper {
  text-align: center;
  width: 45px;
}
.nav__icon {
  fill: #294260;
}
.nav__thumb {
  width: 100%;
  height: 43px;
  background-size: 100%;
  background-size: cover;
}
.nav .icon {
  display: inline-block;
  max-height: 25px;
  width: 60%;
  transition: fill 0.25s ease;
}
.nav__header .icon {
  display: inline-block;
  vertical-align: middle;
}
.nav__header:hover .sidebar__nav-action {
  background-color: #122a49;
}
.nav__header,
.nav__child span {
  line-height: 43px;
}
.nav__action,
.nav__child span {
  transition: background-color 0.25s ease;
  text-transform: uppercase;
  color: #fff;
  font-size: 12px;
}
.nav__action:not(:first-child):before {
  display: inline-block;
  content: '/';
  line-height: 1;
  font-size: 14px;
  vertical-align: middle;
  margin-right: 4px;
  margin-left: 4px;
  color: #9A9A9A;
}
.nav__action__list {
  width: calc(100% - 45px);
  color: #fff;
  border-left: 4px solid currentcolor;
  padding-left: 25px;
  background-color: #122a49;
  transition: background-color 0.25s ease;
  display: inline-block;
  vertical-align: middle;
  transition: color 0.25s ease;
}
.nav__childs {
  padding-left: 45px;
  display: none;
}
.nav__childs-wrapper {
  background-color: rgba(19, 42, 72, 0.45);
  padding-left: 30px;
}
.nav__child {
  display: block;
  padding-left: 25px;
  position: relative;
  color: currentcolor;
}
.nav__child:before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  border-left: 4px solid currentcolor;
  background-color: #122a49;
  transition: opacity 0.25s ease;
  opacity: 0;
  z-index: 0;
}
.nav__child:hover:before,
.nav__child.is-active:before {
  opacity: 1;
}
.nav__child span {
  position: relative;
}
.nav__child--list {
  padding-left: 15px;
}
.nav.is-active .icon {
  fill: #00ffd2;
}
.nav {
  margin-bottom: 20px;
}
.nav.is-active svg {
  filter: url(#icon-shadow);
}
.nav__icon {
  width: 45px;
}
.radar-glow-circle {
  display: none !important;
}
/*=============================
=            #star            =
=============================*/
.galaxy {
  transition: opacity 0.25s ease;
}
.galaxy text {
  pointer-events: none;
  text-transform: uppercase;
  letter-spacing: 0.7px;
}
.galaxy--hidden {
  opacity: 0;
  z-index: 1;
}
.galaxy-tip-trigger {
  display: none;
  position: fixed;
  pointer-events: none;
}
.star__dummy {
  pointer-events: all;
  opacity: 0;
  transition: opacity 0.25s ease;
}
.star.is-preview .star__dummy {
  stroke-width: 10px;
}
.star.is-preview:hover .star__dummy,
.star.is-preview.is-focused .star__dummy {
  opacity: 1;
}
.star__arc {
  fill: #1a3356;
}
.star__arc:not(.is-preview) {
  transition: fill 0.25s ease;
}
.star__label {
  fill: #98ADCD;
  transition: fill 0.25s ease;
  user-select: none;
}
.star__triangle {
  transition: fill-opacity 0.25s ease;
  fill-opacity: 0;
  fill: currentcolor;
}
.star__slice:not(.is-preview):hover .star__arc,
.star__slice:not(.is-preview).is-focused .star__arc {
  fill: currentcolor;
}
.star__slice:not(.is-preview):hover .star__triangle,
.star__slice:not(.is-preview).is-focused .star__triangle {
  fill-opacity: 1;
}
.star__slice:not(.is-preview):hover .star__label,
.star__slice:not(.is-preview).is-focused .star__label {
  fill: #fff;
}
.star-guide {
  pointer-events: none;
  fill: none;
}
/*=====  End of #star  ======*/
/*================================
=            Star tip            =
================================*/
.star-tip {
  opacity: 0;
  pointer-events: none;
}
.star-tip__text {
  fill: #fff;
  font-size: 14px;
  line-height: 14px;
}
.star-tip__text--dummy {
  display: inline-block;
  visibility: hidden;
  position: fixed;
  z-index: -999;
  pointer-events: none;
}
.star-tip__container {
  stroke-width: 1px;
}
/*=====  End of Star tip  ======*/
/*==================================
=            Star label            =
==================================*/
.star-label__text,
.star-label-dummy {
  fill: #0b9ce2;
  font-size: 12px;
  line-height: 12px;
  text-transform: uppercase;
  direction: inline-block;
}
.star-label-dummy {
  visibility: hidden;
  position: absolute;
  z-index: -999;
  top: 0;
  left: 0;
}
.star-label__rect {
  fill: #071c33;
  stroke: #213c67;
  stroke-width: 1px;
}
.star-label__circle {
  stroke: #0b9ce2;
  stroke-width: 1px;
  fill: none;
}
.star-label__qa {
  fill: #0b9ce2;
  font-size: 14px;
  line-height: 14;
  text-anchor: middle;
}
@media screen and (max-width: 1150px) {
  .star-label {
    display: none !important;
  }
}
/*=====  End of Star label  ======*/
.js-gallaxy {
  position: fixed;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
