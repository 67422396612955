.bi-health {

	&__container {
		margin: 0 -70px;

		.preloader {
			display: none;
		}
	}

	.th-title {
		display: inline-block;
		text-align: left;

		.l-ttr-up;
		line-height: 1;

		span {
			position: relative;
			top: -1px;
		}
	}

	&__title {
		padding-left: 55px;
	}

	&__column {

		&:first-child {
			border-right: 1px solid #364659;

			width: 58.33333%;
		}

		&:last-child {
			width: 41.66667%;
		}
	}

	.score {
		width: 190px;

		&__label {
			font-size: 8px;
			vertical-align: top;
			position: relative;
			top: 2px;
			text-align: left;
		}

		&__value {
			font-size: 27px;
		}

		&__item {

			&:first-child:not(:last-child) {
				padding-right: 5px;
			}

			&:not(:first-child) {
				padding-left: 5px;
			}
		}
	}

	&__chart {
		text-align: center;
		padding: 25px 0;

		.th-title {
			margin-bottom: 25px;
		}

		&--single {
			.score {

				&__label-wrapper {
					display: none;
				}

				&__value {
					font-size: 45px;
				}
			}	
		}
	}

	&__charts-list {
		height: ~'calc(100% - 24px)';

		.score__progress {
			position: absolute;
			top: 0;
			right: -20px;
			height: 34px;
		}

		.column {
			width: 33.33333%;
		}


	}

	&__stats {
		color: #fff;
		font-size: 45px;
		margin-right: 15px;
		margin-top: 3px;
	}

	&__finance {
		&-title {
			font-size: 12px;
			color: #fff;
		}

		&-list {
			margin-top: 25px;

			.th-title {
				margin-bottom: 35px;
			}

			.th-line {
				padding-left: 20px;
				padding-right: 20px;

				&__label {
					text-align: right;
				}

				&__value {
					width: 30px;
					height: 30px;
					line-height: 30px;
					font-size: 9px;
				}

				&__wrapper {
					padding-left: 20px;
				}
			}
		}
	}

	.th-btn:not(:last-child) {
		margin-right: 6px;
	}


	@media screen and (max-width: 1641px) {
		&__charts-list {
			height: auto;

			.column {
				width: 50%;
			}
		}

		&__column {

			&:first-child {
				width: 50%;
			}

			&:last-child {
				width: 50%;
			}
		}
	}

	@media screen and (max-width: 1381px) {
		&__charts-list {

			.column {
				width: 100%;
			}
		}

		&__column {

			&:first-child {
				width: 40%;
			}

			&:last-child {
				width: 60%;
			}
		}
	}

	@media screen and (max-width: 1080px) {
		&__charts-list {

			.column {
				width: 50%;
			}
		}

		&__column {

			&:first-child {
				width: 100%;
			}

			&:last-child {
				width: 100%;
				margin-top: 50px;
			}
		}

		&__row {
			height: auto;
		}

		&__finance-item {
			padding: 0 15px;
		}
	}

	@media screen and (max-width: 891px) {
		&__charts-list {

			.column {
				width: 100%;
			}
		}

		&__finance-item .column {
			width: 100%;
			text-align: left;
		}

		&__finance-title {
			padding-bottom: 10px;
		}
	}
}
