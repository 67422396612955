@sidebarSize: 390px;

.dashboard {
	width: 100%;

	&__tooltip {
		color: #fff;
	}

	&__header {
		background-color: @active-blue;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		padding-right: 20px;
		line-height: 45px;
	}

	&__nav {
		background-color: darken(@active-blue, 5%);
	}

	.th-title {
		color: #fff;
		white-space: nowrap;
		letter-spacing: 1px;
	}

	&__btn {
		background-color: @active-blue;
		font-size: 0;
		padding: 14px 0;
		cursor: pointer;
		position: relative;
		width: 45px;
		text-align: center;
	}

	&__icon {
		width: 17px;
		height: 17px;
		fill: #fff;
	}

	&__wrapper {
		position: absolute;
		width: 100%;
		height: 100%;
		min-width: @sidebarSize;
		top: 0;
		left: 0;
		background-color: #022b3e;
		background: linear-gradient(to bottom,#010a13 0,#022b3e 100%);
		animation: fadeIn @duration @easing;
	}


	&__content {
		position: absolute;
		top: 50%;
		left: 0;
		width: 100%;
		transform: translateY(-50%);
	}

	&__title {
		color: @active-blue;
		font-size: 45px;
		letter-spacing: 1px;
	}

	&__date {
		color: #5fa6c5;
		font-size: 11px;
		letter-spacing: 1px;
		text-transform: uppercase;
		padding-top: 5px;
	}

	&__app-list {
		font-size: 0;
		margin-top: 47px;
		padding: 0 45px;
		white-space: initial;
	}

	&__app-item {
		border: 3px solid #233f69;
		border-radius: 50%;
		overflow: hidden;
		width: 50px;
		height: 50px;
		margin: 13px 15px;

		a {
			border: 8px solid transparent;
		}

		.icon {
			width: 100%;
			height: 100%;
			fill: @active-blue;
			transition: fill @duration @easing;
		}

		&:hover, &.active {
			.icon {
				fill: #02f6ea;
			}
		}
	}
}