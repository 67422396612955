.overview {

	/*==============================================
	=            Overview action styles            =
	==============================================*/
	
	&__action {
		width: 50px;
		height: 50px;
		border-radius: 50%;
		color: #32568e;
		border: 2px solid currentcolor;
		position: fixed;
		right: 15px;
		top: 60px;
		transition: color @duration @easing;
		cursor: pointer;

		&:hover {
			color: @active-blue;
		}


		&.is-active {

			.overview__close {
				transform: translateY(-50%) translateX(-50%) scale(1);
				opacity: 1;
			}

			.overview__open {
				transform: translateX(-50%) translateY(-50%) scale(0.5);
				opacity: 0;
			}
		}
	}

	&__open, &__close {
		color: @active-blue;
		user-select: none;
		transition: transform @duration @easing, opacity @duration @easing;
		backface-visibility: none;
		z-index: 1;
		overflow: hidden;
	}

	&__open {
		width: 20%;
		fill: @active-blue;
		margin-top: 2px;
	}

	&__close {
		width: 40%;
		height: 40%;

		&:after, &:before {
			position: absolute;
			top: 50%;
			left: 0;
			width: 100%;
			height: 3px;
			margin-top: -2px;
			background-color: currentcolor;
			content: '';
		}

		&:after {
			transform: rotate(45deg);
		}

		&:before {
			transform: rotate(-45deg);
		}

		transform: translateX(-50%) translateY(-50%) scale(1.5);
		opacity: 0;
	}

	/*=====  End of Overview action styles  ======*/



	&__wrapper {
		display: none;
		position: fixed;
		top: 0;
		right: 0;
		height: 100%;
		width: 370px;
		background-color: rgba(9, 30, 59, 0.7);
		padding-left: 50px;
		padding-right: 40px;
		padding-top: 80px;
		padding-bottom: 50px;

		.shared__header-content {
			color: @active-green;
		}
	}

	&__header {
		margin-bottom: 40px;
	}

	&__content {
		font-size: 13px;

		p {
			color: #fff;
			line-height: 1.6;

			&:not(:last-child) {
				margin-bottom: 25px;
			}
		}
	}

	&__article {
		height: 100%;
		position: relative;
		padding-right: 10px;

		.ps-scrollbar-y-rail {
			right: 0 !important;
		}
	}
}

overview {
	animation: fadeIn @duration @easing alternate;
}
