@import 'spare/_keyframes.less';
@import 'theme/scrollbar.less';


body { background-color: #172f52; }


/*----------  Colors  ----------*/
@dark-blue: #294260;
@active-blue: #08b1ff;

@active-green: #00ffd2;
@green: #09c8bf;
@green2: #12cac1;
@green3: #00fff3;
@green4: #1a847f;

@red: #fe006d;
@red2: #f11150;
@gray: #7a7979;
@gray2: #a9afb1;
@gray3: #C9CDCF;

@blue: #799bcd;

@text-blue: #7493c3;
@text-dark-blue: #3d66a8;
@text-color-1: #2a3537;
@text-color-2: #0e282d;

.th-red {color: #e80d5a !important;}
.th-purple {color: #7e00ff !important;}
.th-green {color: #06ded4 !important;}
.th-blue {color: @active-blue;}
.th-gray {color: @gray; }

.th-color-tr {transition: color @duration @easing;}

/*----------  Transitions  ----------*/
@duration: .25s;
@easing: ease;



/*----------  Styling  ----------*/ 
h1, h2, h3, h4, h5, h6, p, span {
	color: @text-blue;
}

.th-white-bg {background-color: #fff;}
.th-blue-bg {background-color: #060828;}

.th-title-big {
	color: #fff;
	font-size: 45px;
	font-weight: 500;
}

.th-title-xbig {
	color: #fff;
	font-size: 60px;
	font-weight: 500;
}

.th-title {
	font-size: 15px;
	color: @text-blue;

	&--big {
		font-size: 18px;
	}

	&--small {
		font-size: 13px;

		span {
			font-size: 8px;
		}

		&2 {
			font-size: 10px;
		}
	}

	&--white {
		color: #fff;
	}
	&--blue {
		color: #2e6ec5;
	}
	&--dark {
		color: #2a3537;
	}
}
.th-label {
	font-size: 14px;
	color: @gray;

	&--blue {
		color: @blue;
	}

	&--small {
		font-size: 13px;
	}

	&--up {
		text-transform: uppercase;
	}

	span {
		color: inherit;
	}
}


.th-file-icon {
	fill: #00baff;

	text {
		text-anchor: middle;
		font-weight: 600;
		text-transform: uppercase;
		font-size: 13px;
	}
}


.th-ask-icon {
	cursor: pointer;
	color: @active-blue;
	font-size: 10px;
	text-align: center;
	line-height: 13px;
	letter-spacing: 1px;
	height: 14px;
	width: 14px;
	border-radius: 50%;
	border: 1px solid currentcolor;
	transition: box-shadow @duration @easing;
	margin-left: 8px;

	.l-dis-ib;

	&:hover {
		box-shadow: 0 0 3px currentcolor;
	}
}

.trash-icon {
	fill: @text-blue;
	width: 20px;
	// transition: fill .15s ease;

	&:hover {
		fill: @active-blue;
	}
}

.th-ok {
	width: 20px;
	height: 12px;
	position: relative;
	color: @green3;
	transform: rotate(-45deg);

	&:after, &:before {
		content: '';
		display: block;
		position: absolute;
		bottom: 0;
		left: 0;
		border: 1px solid currentcolor;
		box-shadow: 0 0 3px currentcolor;
		border-radius: 1px;
	}

	&:before {
		height: 100%;
	}

	&:after {
		width: 100%;
	}
}

.th-x {
	width: 26px;
	height: 26px;
	position: relative;
	color: #1a2e3d;

	&:after, &:before {
		content: '';
		display: block;
		position: absolute;
		border: 2px solid currentColor;
		width: 100%;
		top: 50%;
		left: 0;
		margin-left: -1px;
		border-radius: 1px;
		box-shadow: 0 0 3px currentColor;
	}

	&:after {
		transform: rotate(-45deg);
	}

	&:before {
		transform: rotate(45deg);
	}

	&--slim {
		&:after, &:before {
			border-width: 1px;
			box-shadow: none;
		}
	}

	&--inverted {
		&:after {
			transform: rotate(0);
		}

		&:before {
			transform: rotate(90deg);
		}
	}
}


/*=====================================
=            Toggle styles            =
=====================================*/

.toggle {
	user-select: none;

	&__label {
		font-size: 10px;
		color: @active-blue;

		&--big {
			font-size: 13px;
		}
	}

	&__view {
		width: 54px;
		height: 25px;
		border: 1px solid #414d59;
		border-radius: 2px;
		position: relative;
		top: -1px;
		margin: 0 8px;
	}

	&__tick {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: 2px solid transparent;
		color: @active-green;
		transition: color @duration @easing;

		&:before {
			content: '';
			display: block;
			position: absolute;
			width: 50%;
			height: 100%;
			border-radius: 2px;
			top: 0;
			left: 0;
			background-color: currentcolor;

			transition: box-shadow @duration @easing, transform @duration @easing,;
		}
	}

	&:hover {
		.toggle__tick:before {
			box-shadow: 0 0 5px currentcolor;
		}
	}

	&.is-active {
		.toggle__tick:before {
			transform: translateX(100%);
		}	
	}

	&--dark {
		.toggle__label {color: #304145;}
		.toggle__tick {color: #304145;}
	}

	&--light {
		.toggle__label {color: #fff;}
		.toggle__tick {color: @active-blue;}
	}
}

.th-toggle {
	width: 20px;
	height: 20px;
	position: relative;
	cursor: pointer;
	margin: 0 auto;
	color: @active-green;
	border-radius: 50%;
	border: 2px solid currentColor;
	box-shadow: 0 0 2px, inset 0 0 2px;
	transition: color @duration @easing;
	font-size: 0;

	&:after {
		display: block;
		content: '';
		width: 100%;
		height: 100%;
		border-radius: 50%;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%) scale(0.4);
		opacity: 0;
		background-color: currentcolor;
		transition: transform .25s ease, opacity .25s ease;
	}

	&.no-hover {
		cursor: default;
	}

	&:not(.no-hover):hover:after {
		opacity: 1;
	}

	&.is-active {
		&:after {
			transform: translate(-50%, -50%) scale(1.1);
			opacity: 1;	
		}

		&:not(.no-hover):hover {
			&:after {
				opacity: 0.55;
			}
		}
	}

	&--gray {
		color: @text-color-1;
	}

	&--red {
		color: @red;
	}
}

/*=====  End of Toggle styles  ======*/



/*====================================
=            Theme button            =
====================================*/

.th-btn {
	font-size: 11px;
	color: #718fb7;
	background-color: #12293d;
	box-shadow: 0 0 5px 1px #12293d;
	cursor: pointer;
	text-transform: capitalize;
	dispaly: inline-block;
	padding: 2px 25px;
	border-radius: 9px;
	cursor: pointer;

	&--red {
		color: #fff;
		background-color: #c01861;
		box-shadow: 0 0 5px 1px #c01861;
	}
}

.th-large-btn {
	width: 100%;
	text-align: center;
	color: @active-blue;
	border: 2px solid #24426c;
	box-shadow: inset 0 0 0 1px #142f50;

	cursor: pointer;
	font-size: 14px;
	line-height: 38px;
	text-transform: uppercase;

	transition: 
		box-shadow @duration @easing,
		background-color @duration @easing,
		border-color @duration @easing;

	&:hover, &.is-active {
		background-color: #193558;
		box-shadow: inset 0 0 0 1px #27436e;
	}

	&--active {
		border-color: @active-blue;
	}
}

.th-light-btn {
	color: #fff;
	text-transform: uppercase;
	font-size: 14px;
	line-height: 27px;
	padding: 0 17px;
	border: 2px solid currentcolor;
	border-radius: 3px;
	display: inline-block;
	vertical-align: middle;
	transition: opacity @duration @easing,
				box-shadow @duration @easing;

	&:hover {
		box-shadow: 0 0 3px currentcolor;
	}
}

.th-green-btn {
	text-transform: capitalize;
	color: @active-green;
	border: 1px solid currentcolor;
	border-radius: 8px;
	text-align: center;
	line-height: 50px;
	font-size: 20px;
	padding: 0 15px;
	width: 100%;
	.f-avn-r;
	transition: box-shadow @duration @easing;

	&:hover {
		box-shadow: 0 0 3px currentcolor;
	}
}

.th-task-btn {
	.f-avn-r;
	white-space: nowrap;
	color: #fff;
	font-size: 13px;
	line-height: 30px;
	text-transform: uppercase;
	padding: 0 15px;
	background-color: #154e74;
	border-radius: 4px;
	display: inline-block;
	vertical-align: middle;

	&[disabled] {
		color: #010a13;
		background-color: #042849;
	}
}

.th-round-btn {
	font-size: 13px;
	color: #fff;
	padding: 0 20px;
	line-height: 36px;
	background-color: #091a33;
	border: 1px solid #303c4b;
	border-radius: 18px;
	display: inline-block;
	cursor: pointer;
	transition-property: background-color, border-color, box-shadow;
	transition-duration: @duration;
	transition-timing-function: @easing;
	font-weight: 500;

	&:not(.no-hover) {
		&:hover {
			background-color: @active-blue;
			border-color: @active-blue;
			box-shadow: 0 0 7px @active-blue;
		}

	}

	&.is-active {
		background-color: @active-blue;
		border-color: @active-blue;
		box-shadow: 0 0 7px @active-blue;
	}

	&--red-hov {
		&:not(.no-hover):hover {
			background-color: @red;
			border-color: @red;
			box-shadow: 0 0 7px @red;
		}

		&.is-active {
			background-color: @red;
			border-color: @red;
			box-shadow: 0 0 7px @red;
		}
	}

	&--gray {
		background-color: @text-color-1;
	}

	&--light {
		background-color: #1c375e;
		border-color: #415370;
	}
}

.ui-tab li {
    display: inline-block;
}

.th-button {
    color: #3a393a;
    font-size: 14px;
    line-height: 1.2;
    padding: 6px 10px 5px;
    display: inline-block;
    vertical-align: middle;
    border: 1px solid @green2;
    border-radius: 3px;
    text-transform: capitalize;

    &:hover {
        box-shadow: 0 0 3px @green2;
    }
}

.th-new-row {
	color: @active-blue;
	border: 1px solid currentcolor;
	cursor: pointer;
	position: relative;
	height: 25px;
	transition: opacity @duration @easing, box-shadow @duration @easing, color @duration @easing;

	&:after, &:before {
		transition: box-shadow @duration @easing, transform @duration @easing;
		background-color: currentcolor;
		display: block;
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
	}

	&:after {
		width: 2px;
		height: 12px;
		margin-left: -1px;
		margin-top: -6px;
	}
	&:before {
		width: 12px;
		height: 2px;
		margin-left: -6px;
		margin-top: -1px;
	}

	&:hover, &:hover:after, &:hover:before {
		box-shadow: 0 0 3px currentcolor;
	}

	&--save {
		color: @green;
		
		&:after {
			transform: rotate(30deg) scale(1.1) translate(2px, -2px);
		}
		&:before {
			transform: rotate(45deg) scaleX(.7) translateX(-1px) translateY(3px);
		}
	}

	&--cancel {
		color: @red;
		
		&:after {
			display: none;
		}
	}
}

.th-edit-btn {
	position: absolute;
	top: 50%;
	right: 0;
	margin-top: -15px;
	border-radius: 4px;
	padding: 5px;
	background-color: @green2;
	font-size: 0;
	opacity: 0;
	transition: opacity @duration @easing;
	cursor: pointer;

	svg {
		fill: #294260;
		width: 20px;
		height: 20px;
	}
}

.th-edit-btn__hov-wrapper:hover {
	.th-edit-btn {
		opacity: 1;
	}
}

/*=====  End of Theme button  ======*/



/*=================================
=            Close btn            =
=================================*/

// X inside a circle
.th-close-btn {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	color: #32568e;
	border: 2px solid currentcolor;
	transition: color @duration @easing;
	cursor: pointer;
	position: relative;
	display: inline-block;
	vertical-align: middle;

	span {
		position: absolute;
		width: 50%;
		height: 50%;
		left: 50%;
		top: 50%;
		transform: translateX(-50%) translateY(-50%);
		color: currentcolor;

		&:after, &:before {
			position: absolute;
			top: 50%;
			left: 0;
			width: 100%;
			height: 3px;
			margin-top: -2px;
			background-color: currentcolor;
			content: '';
		}

		&:after {
			transform: rotate(45deg);
		}

		&:before {
			transform: rotate(-45deg);
		}

	}
}

// x only
.th-close {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	width: 20px;
	height: 20px;

	&:after, &:before {
		background-color: @active-blue;
		content: '';
		display: block;
		width: 100%;
		height: 2px;
		position: absolute;
		top: 50%;
		margin-top: -1px;
		transition: transform @duration @easing;
	}

	&:before {
		transform: rotate(45deg);
	}
	&:after {
		transform: rotate(-45deg);
	}

	&:hover {
		&:before {
			transform: rotate(-45deg);
		}
		&:after {
			transform: rotate(45deg);
		}
	}
}

/*=====  End of Close btn  ======*/


/*==============================
=            Logout            =
==============================*/

.logout {
	width: 45px;
	height: 45px;
	cursor: pointer;
	padding: 12px;

	&__wrapper {
		padding-bottom: 20px;
		position: absolute;
		bottom: 0;
		left: 0;
	}

	.icon {
		width: 100%;
		height: 100%;
		fill: @dark-blue;
		transition: fill @duration @easing;
	}

	&:hover .icon {
		fill: @active-blue;
	}
}

/*=====  End of Logout  ======*/


.th-field {
	background-color: #2b5574;
	border: 1px solid #647dac;
	color: #fff;
	font-size: 14px;
	line-height: 18px;
	padding: 8px 5px;
	height: auto;
}

.w100{
    width: 100%;
}


/*===================================
=            Input group            =
===================================*/

.in-grp {
    position: relative;
    display: table;
    background-color: #143753;
    width: 100%;
    border-radius: 8px;
    padding: 8px 0;

    &__field-g, &__cell {
        display: table-cell;
        vertical-align: middle;
    }

    &__cell {
        width: 1%;
        padding: 0 15px;
        white-space: nowrap;
        letter-spacing: 13px;
    }

    &__field-g {
        width: 100%;
    }

    &__field {
        width: 100%;
        color: #98dbfe;
        font-size: 18px;
        line-height: 22px;
        .f-avn-r;
        height: auto;
        border: 1px solid #607aa8;
        transition: box-shadow @duration @easing;
        padding: 6px 12px;
        background-color: #2b5574;

        &:focus {
            box-shadow: 0 0 7px #08b1ff;
        }

        &::placeholder {
            color: currentcolor;
        }
    }

    &__field__admin {
        width: 100%;
        color: #06607d;
        font-size: 18px;
        line-height: 22px;
        height: auto;
        border: 1px solid #08b1ff;
        transition: box-shadow @duration @easing;
        padding: 6px 12px;

        &:focus {
            box-shadow: 0 0 7px #08b1ff;
        }

        &::placeholder {
            color: currentcolor;
        }
    }

    &__done {
        height: 9px;
        width: 18px;
        border: 2px solid @active-blue;
        border-top: none;
        border-right: none;
        transform: rotate(-45deg);
        position: relative;
        top: -2px;
        display: inline-block;

        &-wrapper {
            width: 20px;
            height: 20px;
            line-height: 20px;
            cursor: pointer;
            border: 5px solid transparent;
            box-sizing: content-box;
            margin: 0 -5px;
        }
    }
}

// <div class="in-grp">
// 	<div class="in-grp__cell">
// 		<div class="in-grp__done-wrapper">
// 			<div class="in-grp__done"></div>
// 		</div>
// 	</div>
// 	<div class="in-grp__field-g">
// 		<input type="text" class="in-grp__field" placeholder="" />
// 	</div>
// 	<div class="in-grp__cell">
// 		<button class="th-close"></button>
// 	</div>
// </div>

/*=====  End of Input group  ======*/



/*======================================
=            Tooltip styles            =
======================================*/

.tooltipster-default {
	max-width: 220px;
	border: 2px solid #2d4876;
	border-radius: 5px;
	background: #0f2245;
	color: #05ded4;

	.tooltipster-content {
		font-size: 12px;
		line-height: 12px;
		padding: 14px 15px;
	}
}

.tooltipster-green {
	border-radius: 3px; 
	border: 2px solid #0a636d;
	background: #0a4d5c;
	color: #05ded4;
	transform: translateX(-4px);

	.tooltipster-content {
		font-size: 12px;
		line-height: 12px;
		padding: 6px 20px;
		text-transform: uppercase;
	}
}

/*=====  End of Tooltip styles  ======*/


.shared-square-icon {
	content: '';
	display: inline-block;
	width: 22px;
	height: 22px;
	background-color: @active-green;
	border-radius: 4px;
	vertical-align: middle;

	transition: background-color @duration @easing;
}

.shared-item-name {
	color: #fff;
	font-size: 20px;
	line-height: 22px;
	position: relative;
	padding-left: 20px;
}

.hide-text {
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
}

.theme-loading {
	height: 4px;
	width: 100%;
	position: relative;
	overflow: hidden;
	background-color: #122a4a;
	// margin: 20px 0;
	border-radius: 2px;

	&:before {
		border-radius: 2px;
		display: block;
		position: absolute;
		content: '';
		left: -100%;
		width: 100%;
		height: 4px;
		background-color: #2980b9;
		animation: loading 1.7s linear infinite;
	}

	&--abs {
		position: absolute;
		top: 0;
		left: 0;
	}
}


.theme-breadcrumb {
	&__item {
		color: @green;
		text-transform: uppercase;
		font-size: 13px;
		line-height: 15px;

		&--inactive {
			color: #304145;
		}

		&:not(:last-child) {
			margin-right: 10px;
		}

		&:not(:first-child) {
			&:before {
				display: inline-block;
				content: '';
				width: 5px;
				height: 5px;
				border-radius: 50%;
				background-color: currentColor;
				margin-right: 14px;
				vertical-align: baseline;
				margin-bottom: 2px;
			}
		}
	}

	&-list {
		margin-top: 15px;
	}
}

.theme-check {
	width: 20px;
	height: 20px;
	color: @active-blue;
	border: 2px solid currentcolor;
	position: relative;
	cursor: pointer;
	line-height: 0;
	display: inline-block;
	white-space: nowrap;

	&:after {
		display: block;
		content: '';
		width: 12px;
		height: 6px;
		border-left: 2px solid;
		border-bottom: 2px solid;
		border-color: currentcolor;
		transform: rotate(-45deg);
		position: absolute;
		left: 50%;
		top: 50%;
		margin-top: -4px;
		margin-left: -6px;
		transition: opacity @duration @easing;
		opacity: 0;
	}

	&.is-active:after {
		opacity: 1;
	}

	&__label {
		line-height: 20px;
		font-size: 13px;
		position: relative;
		right: 100%;
		margin-left: -15px;
	}

	&--green {
		color: @green2;
	}
}

.theme-expand {
	display: inline-block;
	vertical-align: bottom;
	width: 26px;
	height: 26px;
	margin-left: 15px;
	border: 2px solid @active-blue;
	border-radius: 50%;
	background-color: #0f3052;
	position: relative;
	transition: transform @duration @easing;
	transform: rotate(180deg);

	&:after, &:before {
		display: block;
		content: '';
		width: 40%;
		height: 1px;
		background-color: @active-blue;
		position: absolute;
		top: 50%;
		margin-top: -4px;
	}

	&:after {
		left: 50%;
		transform-origin: center left;
		transform: rotate(45deg);
	}

	&:before {
		right: 50%;
		transform-origin: center right;
		transform: rotate(-45deg);
	}

	&.is-active {
		transform: rotate(0);
	}
}

.th-ellipsis {
	text-align: center;
	font-size: 0;
	line-height: 0;
	cursor: pointer;
	display: inline-block;
	box-sizing: content-box;
	border: 5px solid transparent;
	position: absolute;
	top: 50%;
	width: 22px;
	margin-top: -7px;
	color: #4571b1;
	transition: color @duration @easing;

	&:before {
		content: '';
		display: inline-block;
		width: 5px;
		height: 5px;
		border-radius: 50%;
		background-color: currentcolor;
	}

	&:before {
		box-shadow: 8px 0 0 currentcolor,
					-8px 0 0 currentcolor;
	}

	&.is-active {	
		color: @active-green;
	}
}

.th-tab-header {
	background-color: #d9eae7;
	cursor: pointer;
	padding: 15px;
	color: #232323;
	transition: background-color @duration @easing, color @duration @easing;
	position: relative;

	&:after {
		display: block;
		content: '';
		position: absolute;
		right: 15px;
		top: 50%;
		margin-top: -3px;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 6px 7px 0 7px;
		border-color: currentcolor transparent transparent transparent;

		transition: transform @duration @easing;
	}

	&.is-active {
		background-color: #9acbc9;
		color: #fff;

		&:after {
			transform: rotate(180deg);
		}
	}

	&__title {
		font-size: 18px;
		line-height: 20px;
		font-weight: 600;
		color: inherit;
	}
}

.th-tag {
	font-size: 12px;
	line-height: 20px;
	padding: 5px 20px;
	border-radius: 3px;
	color: #fff;
	background-color: @green;
	display: inline-block;
	cursor: pointer;
	position: relative;

	&--delete-hov {
		&:after, &:before {
			display: block;
			content: '';
			position: absolute;
			opacity: 0;
			transition: opacity @duration @easing;
		}

		&:before {
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			background-color: @red;
			border-radius: 3px;
		}

		&:after {
			width: 10px;
			height: 2px;
			background-color: #fff;
			left: 50%;
			top: 50%;
			margin-top: -1px;
			margin-left: -5px;
		}

		&:hover {
			&:after, &:before {opacity: 1;}
		}
	}
}

.th-legend {
	min-width: 170px;

	&__label {
		font-size: 11px;
		line-height: 1;
		color: #fff;
		letter-spacing: 1px;
		// color: #99a2b1;
		text-transform: uppercase;
	}

	&__val {
		color: currentcolor;
		margin-bottom: 5px;

		&:before {
			display: inline-block;
			content: '';
			width: 8px;
			height: 8px;
			margin-bottom: 3px;
			margin-right: 10px;
			background-color: currentcolor;
		}
	}
}

.th-search-box {
	position: relative;

	input {
		width: 100%;
		font-size: 14px;
		font-weight: 400;
		cursor: initial;
	}
}


