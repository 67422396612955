@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100%{
		opacity: 1;
	}
}
.fade-in {
	animation: fadeIn @duration @easing;
}

@keyframes pulse {
	0% {
		box-shadow: 0 0 0 6px rgba(255, 255, 255, 0.13);
	}
	50% {
		box-shadow: 0 0 6px 10px rgba(255, 255, 255, 0.13);
	}
	100% {
		box-shadow: 0 0 0 6px rgba(255, 255, 255, 0.13);
	}
}

@keyframes zoomIn {
	0% { 
		opacity: 0;
		transform: scale(0.9);
	}
	100% {
		opacity: 1;
		transform: scale(1);
	}
}
.zoom-in {
	animation: zoomIn @duration @easing;
}


@keyframes slideLeftIn {
    0% {
		opacity: 0;
		transform: translateX(10px);
	}
    100% {
    	opacity: 1;
		transform: translateX(0);
    }
}

@keyframes slideUpIn {
	0% { 
		transform: translateY(10px);
		opacity: 0;
	}
	100% { 
		transform: translateY(0);
		opacity: 0.6;
	}
}

@keyframes shadowFlash {
	0% { 
		box-shadow: 0 0 0 @active-green;
	}
	10% { 
		box-shadow: 0 0 20px @active-green;
	}
	100% { 
		box-shadow: 0 0 0px @active-green;
	}
}

@keyframes flip {
	0% { 
		transform: rotate(45deg);
	}
	100% { 
		transform: rotate(235deg);
	}
}
@keyframes backFlip {
	0% { 
		transform: rotate(-45deg);
	}
	100% { 
		transform: rotate(-235deg);
	}
}

@keyframes loading {
    from {left: -30%; width: 30%;background-color: #fe006d;}
    50% {width: 30%;background-color: #7e00ff;}
    70% {width: 70%;}
    80% {left: 50%;}
    95% {left: 120%;}
    to {left: 100%;background-color: #00ffd2;}
}