.help {

	&__title {
		color: @active-blue;
		padding-bottom: 3px;
	}

	&__content {
		color: #fff;
		line-height: 14px;
		margin: 10px 0;
	}

	&__tooltip {
		max-width: 220px;
		border: 2px solid #2d4876;
		border-radius: 5px;
		background: #0f2245;
		color: #05ded4;

		.tooltipster-content {
			font-size: 12px;
			line-height: 12px;
			padding: 14px 15px;
		}
	}
}