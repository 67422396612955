.quick-wins {

	&__container {
		padding: 5px 10px;
		margin: -5px -10px;
	}

	.score__label {
		font-size: 9px;
		font-weight: 600;
	}

	.score__value {
		font-size: 32px;
	}
}

.qw-score {
	position: absolute;
	right: 0;
	bottom: -35px;

	.score {
		width: 230px;
	}

	.score__label-wrapper {
		transition: color .5s ease;
	}
}
