/*----------  Viewport sizes  ----------*/
@s1: 1280px;
@s2: 1150px;


.l-dis-ib {
	display: inline-block;
	vertical-align: middle;
	zoom: 1; /* Fix for IE7 */
	*display: inline; /* Fix for IE7 */
}
.l-dis-b { display: block; }
.l-dis-t { display: table; }
.l-dis-tc { display: table-cell; }
.l-dis-tr { display: table-row; }

.l-disabled {
	pointer-events: none;
	opacity: 0.5;
}

.l-min-w33 {
	min-width: 33.3333%;
}
.l-w-100 {
	width: 100%;
}
.tst__wrapper {
    &__l-w-30 {
        width: 30%;
    }
}
.tst__wrapper {
    &__l-w-70 {
        width: 70%;
    }
}
.l-fs-12 {
	font-size: 12px;
}

.l-m-h25 {min-height: 250px;}

/*============================
=            Flex            =
============================*/

.l-dis-flx {
	display: flex;
	width: 100%;
}

.l-flx-wrap {
	flex-wrap: wrap;
}

.l-flx-jsb {
    justify-content: space-between;
}

/*=====  End of Flex  ======*/


/*====================================
=            Grid options            =
====================================*/

.l-ib-grid {
	font-size: 0;

	.column, 
	.column:last-child:not(:first-child), 
	.columns:last-child:not(:first-child) {
		float: none;
		display: inline-block;
	}
}

.row {width: 100%;}

.row.no-gutter {
	margin-left: 0;
	margin-right: 0;
}
.no-gutter > .column,
.no-gutter > .columns {
	padding-left: 0;
	padding-right: 0;
}

/*-----  End of Grid options  ------*/


.l-wrap-600 {max-width: 600px;}
.l-wrap-90p {max-width: 90%;}
.l-wrap-100p {max-width: 100%;}


.l-group {
	display: inline-table;
	width: 100%;

	&__cell {
		display: table-cell;
		width: auto;
	}

	&__main {
		display: table-cell;
		width: 100%;
	}
}


.l-tal-c { text-align: center; }
.l-tal-r { text-align: right; }
.l-tal-l { text-align: left; }
.l-tal-j { 
	text-align: justify; 

	&:after {
		display: inline-block;
		content: '';
		width: 100%;
		height: 0;
	}
}
.l-ttr-up { text-transform: uppercase; }
.l-ttr-cap { text-transform: capitalize; }
.l-ttr-low { text-transform: lowercase; }
.l-ttr-init { text-transform: initial; }
.l-lts-init { letter-spacing: initial; }
.l-lts-normal { letter-spacing: normal; }

.l-cur-p { cursor: pointer; }
.l-cur-t { cursor: text; }
.l-of-h { overflow: hidden; }

.l-fl-r {float: right;}
.l-fl-l {float: left;}
.l-fl-fix {
	&:after {
		display: block;
		content: '';
		clear: both;
	}
}

.l-val-b { vertical-align: bottom; }
.l-val-bl { vertical-align: baseline; }
.l-val-m { vertical-align: middle; }
.l-val-t { vertical-align: top; }

.l-pos-r { position: relative; }
.l-pos-a { position: absolute; }
.l-pos-fx { position: fixed; }
.l-pos-st { position: static; }
.l-ws-nw { white-space: nowrap; }

.l-fz-0 { font-size: 0; }
.l-fz-1 { font-size: 1px; }

.l-h-100 {
	height: 100%;
}

.l-full-h {
	position: relative;
	height: 100%;
}
.l-full-w { width: 100%; }

.l-hidden { display: none; }

.l-whs-nw { white-space: nowrap; }

.l-a-c {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
}
.l-posa-tl {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}

/*----------  z-index  ----------*/
.l-zi-0 { z-index: 0; }
.l-zi-5 { z-index: 5; }
.l-zi-10 { z-index: 10; }
.l-zi-15 { z-index: 15; }
.l-zi-20 { z-index: 20; }


.l-mt-35 {margin-top: 35px;}
.l-mb-50 {margin-bottom: 50px;}
.l-mt-50 {margin-top: 50px;}
.l-mt-5 {margin-top: 5px;}
.l-mt-10 {margin-top: 10px;}
.l-mt-15 {margin-top: 15px;}
.l-mt-20 {margin-top: 20px;}
.l-mb-15 {margin-bottom: 15px;}
.l-mb-10 {margin-bottom: 10px;}
.l-mb-25 {margin-bottom: 25px;}
.l-mb-5 {margin-bottom: 5px;}
.l-mr--15 {margin-right: -15px;}
.l-mr-15 {margin-right: 15px;}
.l-mr-5 {margin-right: 5px;}
.l-ml-0 {margin-left: 0;}

.l-pb-0 {padding-bottom: 0;}
.l-pl-15 {padding-left: 15px;}
.l-pl-30 {padding-left: 30px;}
.l-pb-15 {padding-bottom: 15px;}
.l-pb-20 {padding-bottom: 20px;}
.l-pb-30 {padding-bottom: 30px;}
.l-pb-10 {padding-bottom: 10px;}
.l-pb-5 {padding-bottom: 5px;}
.l-pb-40 {padding-bottom: 40px;}
.l-pb-50 {padding-bottom: 50px;}
.l-pt-10 {padding-top: 10px;}
.l-pt-15 {padding-top: 15px;}
.l-pt-20 {padding-top: 20px;}
.l-pt-30 {padding-top: 30px;}
.l-pt-35 {padding-top: 35px;}
.l-pt-40 {padding-top: 40px;}
.l-pr-15 {padding-right: 15px;}
.l-pr-10 {padding-right: 10px;}
.l-pr-20 {padding-right: 20px;}
.l-pr-30 {padding-right: 30px;}
.l-pr-40 {padding-right: 40px;}
.l-pr-80 {padding-right: 80px;}
.l-pt-15 {padding-top: 15px};
.l-sp-5 {
	padding-left: 5px;
	padding-right: 5px;
}
.l-sp-10 {
	padding-right: 10px;
	padding-left: 10px;
}
.l-sp-15 {
	padding-right: 15px;
	padding-left: 15px;
}
.l-sp-20 {
	padding-right: 20px;
	padding-left: 20px;
}

.l-fw-6 {font-weight: 600;}
.l-fw-7 {font-weight: 700;}

/*----------  Style app edges  ----------*/
.edge {

	&__item {
		position: absolute;
		display: inline-block;
		white-space: nowrap;

		&--br {
			bottom: 0;
			right: 0;
			padding-right: 40px;
			padding-bottom: 30px;
		}

		&--bl {
			bottom: 0;
			left: 390px;
			padding-left: 40px;
			padding-bottom: 30px;
		}

		@media screen and (max-width: @s1) {
			&--br {
				padding-right: 20px;
			}

			&--bl {
				padding-left: 0px;
			}

			&--bl, &--br {
				padding-bottom: 15px;
			}
		}
	}
}


/*=============================================
=            field with side label            =
=============================================*/

.l-field {
	display: inline-table;
	vertical-align: middle;

	&:after {
		display: block;
		content: '';
		clear: both;
	}

	&__label {
		display: table-cell;
		width: auto;
	}

	&__input {
		display: block;
		float: left;
	}
}

/*=====  End of field with side label  ======*/



@media screen and (max-width: @s1) {
	.app-main {
		padding: 15px 20px;
		padding-top: 65px;
	}
}