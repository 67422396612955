.th-line {
    white-space: normal;
    font-size: 0;
    // margin-bottom: 30px;
    .f-avn-r;
    width: 100%;
    display: inline-table;

    &__wrapper {
        .ps-container {
            margin-right: -15px;
            padding-right: 15px;
            max-height: 170px !important;
        }

        td, th {
            padding-right: 5px;
        }
    }

    &:after {
        display: block;
        content: '';
        clear: both;
    }

    &.row {
        margin-left: 0;
        margin-right: 0;
    }

    .column {
        padding-left: 0;
        padding-right: 0;
        vertical-align: middle;
        display: table-cell;

        &, &:last-child {
            float: none;
        }
    }

    &__label {
        text-transform: uppercase;
        text-overflow: ellipsis;
        overflow: hidden;
        color: #f0f1f3;
        font-size: 12px;
        padding-right: 15px;
        font-weight: 300;
    }

    &__view {
        display: table-cell;
        width: 100%;
        vertical-align: middle;
    }

    &__progress {
        background-color: #1a3357;
        height: 9px;
        border-radius: 4px;
        width: 100%;
        position: relative;

        &-bar {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 0%;
            border-radius: 5px;
            background-color: currentcolor;
            box-shadow: 0 0 3px;
            // transition: width (@duration * 2) @easing;
        }
    }

    &__data {
        white-space: nowrap;
        width: auto;
        display: table-cell;
        vertical-align: middle;
        padding-left: 12px;
        position: relative;
    }

    &__state, &__value, &__note {
        background-color: #0f3052;
        display: inline-block;
        vertical-align: middle;
        font-size: 10px;
        text-align: center;
    }

    &__state-wrapper {
        position: absolute;
        right: 100%;
        top: 0;
        transform: translateY(-90%);
        letter-spacing: 10px;
        white-space: nowrap;
    }

    &__state, &__note {
        text-transform: capitalize;
        border-radius: 8px;
        padding: 3px 12px;
        line-height: 12px;
        color: #7e96c4;
        letter-spacing: 1px;

        &--colorful {
            color: currentcolor;
            background-color: currentcolor;

            span {
                color: #fff;
            }
        }
    }

    &__state-container {
        min-width: 65px;
        color: inherit;
        text-align: right;
    }

    &__value {
        min-width: 24px;
        height: 24px;
        border-radius: 12px;
        line-height: 26px;
        overflow: hidden;
        color: #fff;
        text-align: center;
        padding: 0 9px;
        white-space: nowrap;
    }

    &__extra {
        margin-left: 10px;
        width: 90px;
    }

    &__table-wrapper {
        border: 1px solid @dark-blue;
        padding-right: 20px;
        padding-left: 10px;
        padding-bottom: 10px;
        border-radius: 4px;
    }

    &__no-data {
        font-size: 12px;
        color: #fff;
        font-weight: 300;
    }

    &__blob {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: currentcolor;
        display: block;

        &--false {
            color: @red;
        }

        &--true {
            color: @green;
        }
    }
}
