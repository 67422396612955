.sidebar-base-styles {
	width: 390px;
	display: block;
	min-height: 10px;
	float: left;

	@media screen and (max-width: @s1) {
		width: 360px;
	}
}

.galaxy-center-guide {
	position: absolute;
	top: 0;
	left: 390px;
	width: ~'calc(100% - 390px)';
	z-index: -999;
	height: 100%;
	visibility: hidden;

	&.overview-active {
		width: ~'calc(100% - 500px)';
	}

	@media screen and (max-width: @s1) {
		width: ~'calc(100% - 360px)';
		left: 360px;
	}
}

.app-main.shared-active {
	width: 100%;
	max-width: ~'calc(100% - 390px)';
	.l-full-h;

	@media screen and (max-width: @s1) {
		max-width: ~'calc(100% - 360px)';
	}
}

sidebar {
	.sidebar-base-styles;
}

.sidebar {
	.sidebar-base-styles;
	position: relative;
	z-index: 10;
	background: linear-gradient(to right,  rgba(25,48,79,0.41) 0%,rgba(13,23,42,0.41) 100%);
	overflow: hidden;
	font-size: 0;
	white-space: nowrap;

	&:before {
		position: absolute;
		z-index: -1;
		left: 0;
		top: 0;
		height: 100%;
		width: @iconBarSize;
		background: rgba(0, 0, 0, 0.33);
		content: '';
	}

	&__header {
		&-content {
			width: ~'calc(100% - 45px)';
		}
		
		.sidebar__actions {
			padding-bottom: 0;
		}

		// padding-bottom: 15px;
	}

	&__actions {
		width: @iconBarSize;
		text-align: center;
		padding-bottom: 15px;
		font-size: 0;

		&--no-padding {
			padding: 0;
		}

		.icon-link {
			margin-bottom: 20px;
		}

		.icon-link {
			padding: 6px 0;
		}
	}

	&__content {
		padding: 0 20px;
		width: ~'calc(100% - 45px)';
	}

	&__section {
		overflow: hidden;
		position: relative;
		width: 100%;
		display: inline-block;
		vertical-align: top;

		&--navs {
			z-index: 1;
			padding-top: 25px;
			// top: 0 !important;
		}

		&:not(&--navs) {
			// position: absolute;
		}

		&.overview-section {
			width: ~'calc(100% - 45px)';
			right: 0;
			z-index: 4;
			
			.sidebar__content {
				width: 100%;
			}

			&.is-hidden {
				z-index: 0;
			}
		}
	}

	.footer {
		position: absolute;
		bottom: 0;
		left: 0;
	}

	.ps-scrollbar-y-rail {
		z-index: 1;
	}
}


// hamburger menu styling
.menu-trigger.nav {
	margin-bottom: 0;
	padding-top: 4px;
	padding-bottom: 3px;
}