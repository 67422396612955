@import './perfect-scrollbar.css';

.ps {
	overflow: hidden;
	position: relative;

	&.always-visible {
		.ps__rail-x, 
		.ps__rail-y {
			opacity: .9;
		}
	}

	.ps__rail-x, 
	.ps__rail-y {
		background-color: @dark-blue;
		border-radius: 6px;
		transition: width @duration @easing, opacity @duration @easing;
		z-index: 5;
	}

	&__thumb-y, &__thumb-x {
		transition: background-color @duration @easing,
					width @duration @easing;
		background-color: @active-blue;
	}

	/*----------  Style Y rail  ----------*/
	&__thumb-y {
		width: 100%;
		right: 0;
	}
	&__rail-y {
		width: 4px;
		right: 0;

		&:hover {
			width: 7px;

			.ps__thumb-y {
				width: 100%;
				background-color: @active-blue;
			}
		}
	}

	/*----------  Style X rail  ----------*/
	&__thumb-x {
		height: 100%;
		bottom: 0;
	}
	&__rail-x {
		height: 4px;
		bottom: 0;

		&:hover {
			height: 7px;

			.ps__thumb-x {
				height: 100%;
				background-color: @active-blue;
			}
		}
	}
}
