@import 'spare/_header.less';

.br-support {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 999;
	background: url(../images/main-bg.jpg);
	background-color: #08b1ff;
	text-align: center;

	&__row {
		max-width: none !important;
		display: inline-block;
	}

	&__wrapper {
		width: 100%;
		height: 100%;
		display: table;
	}

	&__content {
		display: table-cell;
		vertical-align: middle;
	}

	&__browsers {
		margin-top: 20px;

		&:after {
			display: block;
			content: '';
			clear: both;
		}
	}

	&__container {
		margin: 0 auto;
		width: 100%;
		max-width: 1300px;
	}

	&__browser {
		margin-top: 20px;
		padding: 0 15px;
		width: 180px;
		float: left;

		&-icon {
			fill: #E4E7E7;
			width: 150px;
		}

		&-name {
			margin-top: 20px;
			text-transform: uppercase;
			color: #E4E7E7;
			font-size: 13px;
		}
	}
}