@import "../../assets/variables.scss";

// variables
$xs-base-padding: $base-padding/3;
$xl-base-padding: $base-padding * 3;

//inhertance
%flex-column-centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

//scss
.registration {
  height: 100vh;
  width: 100vw;
  @extend %flex-column-centered;
  justify-content: space-around;

  .div {
    @extend %flex-column-centered;

    .img {
      width: 75px;
      height: auto;
      margin-bottom: $base-padding;
    }
    .h1 {
      @extend %heading;
      margin-bottom: $base-padding;
      font-family: $font-headings;
    }
    .h2 {
      font-size: 10pt;
      font-family: $font-headings;
    }
    .form {
      margin-top: $base-padding;
      border: none;
      @extend %flex-column-centered;

      .label {
        margin-top: $xs-base-padding;
      }
    }
    p {
      font-size: 10pt;
      margin-top: $xs-base-padding;
      color: $primary-text-color;
    }
  }
}

.statement {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 210px;
}

.statementText {
  text-align: center;
  color: red;
  width: 210px;
  font-size: 15px;
}
.add-display {
  display: unset;
}
