.resources {

	.ps-scrollbar-y-rail {
		right: 0 !important;
	}

	&__container {
		& > .ps-scrollbar-y-rail {
			display: none !important;
		}
	}

	.row {
		max-width: 100%;

		.column {
			padding: 0;
		}
	}

	&__wrapper {
		&--left {
			padding-right: 37px;
		}

		&--right {
			padding-left: 33px;
		}

		@media screen and (max-width: 1190px) {
			&--left {
				padding-right: 17px;
			}

			&--right {
				padding-left: 13px;
			}
		}
	}

	&__table {
		width: 100%;

		thead {
			color: @text-blue;
			font-size: 10px;
		}

		th {
			font-weight: 500;
			line-height: 1.9;
			text-align: left;
		}

		td {
			color: #fff;
			font-size: 13px;
		}

		td, th {
			&:first-child {
				padding-left: 5px;
			}

			&:last-child {
				padding-right: 5px;
			}
		}
	}

	.trash-icon {
		position: absolute;
		top: 50%;
		left: 50%;
		margin-left: -10px;
		margin-top: 2px;
		z-index: 5;
		transform: scale(1.3) translateY(-50%);
		opacity: 0;
		transition: transform .25s ease, opacity .25s ease, fill .25s ease;
	}

	&__item {
		border-top: 1px solid rgba(255, 255, 255, .12);
		animation: slideLeftIn @duration @easing alternate;
		transition: background-color @duration @easing;

		td {
			padding: 10px 0;
			position: relative;

			&.date-cell {
				padding-right: 15px;
			}

			&:after, &:before {
				content: '';
				position: absolute;
				width: 100%;
				height: 1px;
				background-color: #06ded4;
				left: 0;
				opacity: 0;
				transition: opacity @duration @easing;
				box-shadow: 0 0 6px #06ded4;
			}

			&:first-child {
				line-height: 0;
			}

			&:after {
				bottom: -1px;
			}

			&:before {
				top: -1px;
			}
		}

		&:hover, &.is-active {
			background: rgba(2, 10, 21, .3);

			td:before, td:after {
				opacity: 1;
			}
		}

		&__name {
			color: #fff;
			// width: 255px;
			// white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			padding-right: 15px;
			word-break: break-all;
		}

		&__size {
			color: #fff;
			transition: opacity .25s ease;
		}

		&:hover {
			.resources__item__size {
				opacity: 0;
			}

			.trash-icon {
				transform: scale(1) translateY(-50%);
				opacity: 1;
			}
		}
	}

	&__hidden-header {
		font-size: 0 !important;
	}

	.th-file-icon {
		width: 100%;
		max-width: 38px;
		max-height: 47px; 
		line-height: 1;
	}

	&__type-column {
		width: 17%;
	}

	&__size-column {
		// width: 11%;
	}

	&__date-column {
		width: 18%;
	}

	&__name-column {
		width: 52%;
	}

	&__wrapper {
		height: ~'calc(100% - 20px)';
	}

	/*----------  #header styles  ----------*/
	&__header {
		position: absolute;
		bottom: 0;
		right: 0;
	}

	&__hint {
		font-size: 12px;
		color: #59749d;
		line-height: 1.2;
		margin-top: 10px;
	}

	@media screen and (max-width: 1190px) {
		&__date-column, .date-cell {
			display: none;
		}

		&__size-column {
			width: 15%;
		}
	}
}



/*----------  #upload module  ----------*/

.upload {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 1px dashed #084c6f;
	background-color: rgba(11, 26, 44, 0.43);

	&__form {
		padding-bottom: 30px;
	}

	&__label-wrapper {
		position: absolute;
		bottom: 0;
		// bottom: 30px;
		left: 0;
		width: 100%;
		padding: 0 25px;
		z-index: 2;
		display: table;
		width: 100%;
	}

	&__label-text {
		display: table-cell;
		width: 100%;
		font-size: 14px;
		line-height: 30px;
		color: #526885;
		padding-left: 20px;

		border: 1px solid #55729b;
		text-transform: uppercase;

		transition: 
			border-color @duration @easing,
			box-shadow @duration @easing;

		&:hover {
			border-color: @active-blue;
			box-shadow: 0 0 5px @active-blue;
		}
	}

	&__btn {
		color: #8ea2c1;
		line-height: 32px;
		font-size: 14px;
		background-color: #32568e;
		padding: 0 40px;
		width: auto;
		display: table-cell;
		border: 1px solid #32568e;

		transition: 
			border-color @duration @easing,
			background-color @duration @easing,
			box-shadow @duration @easing;

		&:hover:not(.is-disabled) {
			border-color: @active-blue;
			box-shadow: 0 0 5px @active-blue;
		}

		&.is-disabled {
			background-color: #48628C;
			cursor: default;
		}
	}

	&__icon {
		width: 100%;
		max-width: 70px;
		max-height: 90px;
		margin: 0 auto;
		display: inline-block;
		fill: #132a48;
	}

	&__hint {
		font-size: 18px;
		line-height: 1.3;
		color: #213d62;
		text-transform: uppercase;
		margin-top: 60px;
	}

	&__info {
		position: absolute;
		width: 100%;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
	}

	&__dropzone {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 0;

		transition: box-shadow (@duration / 2) @easing;

		&.in, &.hover {
			box-shadow: inset 0 0 25px @active-blue;
		}

		&.is-active {
			z-index: 5;
		}
	}

	&__item {
		animation: slideLeftIn @duration @easing alternate;

		.l-dis-tc {
			padding: 12px 0;
		}

		&:not(:first-child) {
			.l-dis-tc {
				border-top: 1px solid #283646;
			}
		}

		&__icon {
			width: 15px;
			margin-right: 20px;
			line-height: 0;

			&-tc {
				width: 35px;
			}
		}

		&__name, &__size {
			font-size: 13px;
			line-height: 1.2;
			color: #7c94b7;
		}

		&__name {
			text-overflow: ellipsis;
			display: inline-block;
			width: 90px;
			white-space: nowrap;
			overflow: hidden;
			vertical-align: middle;
		}

		&__name, &__size, &__icon {
			margin-right: 20px;
		}

		&__cancel {
			width: 10px;
			height: 10px;
			position: relative;
			border: 7px solid transparent;
			box-sizing: content-box;
			margin-left: 20px;

			&:after, &:before {
				position: absolute;
				top: 50%;
				left: 0;
				height: 2px;
				width: 120%;
				background-color: #06ded4;
				content: '';
				display: block;
				margin-top: -1px;
				transition: box-shadow @duration @easing;
			}

			&:after {
				transform: rotate(45deg);
			}

			&:before {
				transform: rotate(-45deg);
			}

			&:hover {
				&:after {
					animation: flip .25s ease alternate;
				}

				&:after, &:before {
					box-shadow: 0 0 2px #06ded4;
				}
			}
		}

		&__progress-bar {
			width: 100%;
			background-color: #17283b;
			height: 6px;
			border-radius: 3px;
			position: relative;
		}

		&__progress {
			position: absolute;
			left: 0;
			height: 100%;
			border-radius: 3px;
			width: 0%;
			background-color: #00baff;
			box-shadow: 0 0 7px #00baff;
			transition: width .15s ease;
		}
	}

	&__complete {
		color: #22497a;
		font-size: 10px;
		line-height: 24px;
		border-radius: 4px;
		background-color: #0a203c;
		text-transform: uppercase;
		padding: 0 10px;
		display: inline-block;
		white-space: nowrap;
	}

	&__list {
		position: relative;
		padding: 0 15px;
		padding-bottom: 18px;
		z-index: 1;
		display: table;
		width: 100%;

		&:after {
			display: none;
			content: '';
			width: 100%;
			height: 1px;
			background-color: #00baff;
			position: absolute;
			bottom: 0;
			left: 0;
			box-shadow: 0 0 7px #00baff;
			opacity: 0.6;
			animation: slideUpIn @duration @easing alternate;
		}

		&:not(:empty):after {
			display: block;
		}
	}

	&__list-wrapper {
		position: relative;
		overflow: hidden;
		height: 100%;
		border-bottom: 64px solid transparent;
	}

	&__error {
		color: @red;
		font-size: 13px;
	}
}



/*----------  #preview modules  ----------*/
.preview {
	overflow: hidden;

	&__icon {
		margin-right: 25px; 
	}

	&__title {
		color: #fff;
		text-transform: capitalize;
		font-size: 24px;
		white-space: initial;
		width: 85%;
		word-break: break-word;
	}

	&__tabs {
		width: 100%;
		margin-top: 10px;
		padding-top: 3px;
		border-top: 1px solid rgba(255, 255, 255, .12);
	}

	&__tab {
		width: 50%;
		float: left;
		overflow: hidden;
		cursor: pointer;
		position: relative;

		&:before {
			content: '';
			dispaly: block;
			position: absolute;
			z-index: 2;
			top: 0;
			left: 0;
			height: 5px;
			width: 100%;
			border: 1px solid #2c3589;
			background-color: #00baff;
			transition: transform @duration @easing;
		}

		&:first-child:before {
			transform: translateX(100%);
		}

		&:last-child:before {
			transform: translateX(-100%);
		}

		&.is-active {
			&:before {
				transform: translateX(0);
			}
		}
	}

	&__tab-name {
		text-align: center;
		text-transform: uppercase;
		font-size: 12px;
		padding: 20px 0;
	}

	&__tab-content {
		animation: fadeIn @duration @easing alternate;
	}
}



/*----------  Details tab styles  ----------*/
.details {
	&__list {
		text-transform: uppercase;
		border: 1px solid #39485a;
		border-left: none;
		border-right: none;
		padding: 15px 0;
	}

	table {
		width: 100%;
	}

	&__type {
		font-size: 10px;
		color: #4b6489;
		padding-right: 20px;
		text-transform: uppercase;
	}

	&__value {
		font-size: 10px;
		color: @active-blue;
	}

	&__description {
		font-size: 12px;
		color: #8ee0fe;
	}

	&__title {
		padding: 25px 0;
	}

	td:first-child {
		min-width: 125px;
	}

	td {
		padding: 5px 0;
	}
}


/*----------  #video styles  ----------*/

.video-wrapper {
	margin-top: 10px;
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;

	video {
		background-color: #0c1223;
		outline: none;
	}

	.video-js {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		.vjs-big-play-button {
			border: none;
			background-color: transparent;
			width: auto;
			height: auto;
			top: 50%;	
			left: 50%;
			margin: 0;
			transform: translateX(-50%) translateY(-50%);

			&:after, &:before {
				width: 0;
				height: 0;
				border-style: solid;
				display: block;
				content: '';
			}

			&:after {
				border-width: 14px 0 14px 25px;
				border-color: transparent transparent transparent #08b1ff;
				position: relative;
				z-index: 1;
			}

			&:before {
				border-width: 11px 0 11px 20px;
				border-color: transparent transparent transparent #132a48;
				position: absolute;
				z-index: 2;
				top: 50%;	
				left: 50%;
				margin-left: -1px;	
				transform: translateY(-50%) translateX(-50%);
			}
		}

		.vjs-control {
			color: @active-blue;
		}

		.vjs-slider-bar, .vjs-volume-level {
			background-color: @active-blue;
		}

		.vjs-slider-bar:before {
			display: none;
		}

		.vjs-slider {
			background-color: #1d253a;
		}

		.vjs-progress-control {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 5px;
			transform: translateY(-100%);
			-webkit-transition: all .4s;
			-moz-transition: all .4s;
			-o-transition: all .4s;
			transition: all .4s;

			.vjs-slider {
				margin: 0;	
			}
		}

		.vjs-load-progress, .vjs-progress-holder, .vjs-play-progress {
			height: 5px;
		}

		.vjs-current-time, .vjs-time-divider, .vjs-duration {
			display: block;
		}

		.vjs-fullscreen-control {
			position: absolute;
		    right: 0;
		    left: auto;
		    top: 0;
		}

		.vjs-control:before {
			font-size: 23px;	
			line-height: 1.4;	
		}
	}
}
