.main {
  padding: 60px 0 0 0;
}

@media only screen and (min-width: 1000px) {
  .main {
    padding-top: 60px;
    padding-left: 4em;
  }
}
