@import "../../assets/variables.scss";

.progressBaseBar {
  position: fixed;
  background-color: $primary-color-3;
  width: 100%;
  height: 3px;
  margin: 0 auto;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  opacity: 0.5;
}

.progressOverlayBar {
  position: fixed;
  background-color: $primary-color-5;
  height: 3px;
  z-index: 1;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}

.progressBarContainer {
  position: relative;
  width: 100%;
  height: 15px;
}

.progressBarAll {
  position: fixed;
}

.active {
  position: fixed;
  background-color: $primary-color-5;
  height: 3px;
  z-index: 1;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}

.notActive {
  position: fixed;
  background-color: $primary-color-5;
  opacity: 0.5;
  height: 3px;
  z-index: 1;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}
