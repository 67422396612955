.milestones {

	&__container {
		padding: 5px 10px;
		margin: -5px -10px;
	}

	&__item {
		color: @red;
	}

	&__check {
		width: 26px;
		height: 26px;
		border: 3px solid rgba(255, 255, 255, .25);
		margin-right: 12px;
		transition: box-shadow (@duration / 2) @easing;
		position: relative;

		&--lock, &--mark {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translateY(-50%) translateX(-50%);
		}

		&--lock {
			z-index: 1;
			width: 100%;
			fill: @red;
			transition: opacity (@duration / 2) @easing;

			path {
				transition: transform @duration @easing;
			}
		}

		&--mark {
			z-index: 2;
			width: 100%;

			polyline {
				fill-rule: evenodd;
				stroke: @active-green;
				clip-rule: evenodd;
				stroke-width: 2;
				fill: none;
				stroke-dasharray: 23;
				transition: stroke-dashoffset @duration @easing;
				stroke-dashoffset: -23;
			}
		}

		&:hover {
			box-shadow: 0 0 14px @active-green;
		}
	}

	&__item:not(.not-achieved) {
		color: @active-green;
		
		.milestones__header:before {
			background-color: #06ded4;
		}

		.milestones__check {
			.milestones__check--mark polyline {
				transition-delay: @duration;
				stroke-dashoffset: 0;
			}

			.milestones__check--lock {
				transition-delay: (@duration / 2);
				transition-duration: @duration;
				opacity: 0;

				path:first-child {
					transform: translateY(-3px);
				}
				path:last-child {
					transform: translateY(3px);
				}
			}
		}
	}

	&__item--active {
		box-shadow: 0 0 10px #144a75;

		.milestones__arrow {
			transform: rotate(180deg);
		}
	}

	// icon styles
	.calendar-icon {
		display: inline-block;
		vertical-align: middle;
		fill: #4a6da5;
		width: 25px;
		line-height: 1;

		&__date {
			font-size: 42px;
			.f-avn-m;
			font-weight: 600;
			text-anchor: middle;
		}
	}
}

.ml-progress {
	height: 9px;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;

	&:before {
		position: absolute;
		height: 5px;
		width: 100%;
		left: 0;
		top: 50%;
		margin-top: -3px;
		background-color: #193656;
		border-radius: 2px;
		content: '';
	}

	&__value {
		color: #fff;
		font-size: 9px;
		text-align: center;
		display: inline-block;
		line-height: 31px;
		width: 31px;
		background-color: #0f3052;
		border-radius: 50%;
		position: absolute;
		z-index: 1;
		background-visibility: hidden;
		right: -5px;
		margin-top: -16px;
		top: 50%;
		transform: translateX(100%) translateZ(0);
	}

	&__bar {
		width: 0%;
		height: 100%;
		border-radius: 4px;
		position: relative;
		/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#42b289+0,13faa6+44,08dbd5+100 */
		background: #42b289; /* Old browsers */
		background: -moz-linear-gradient(left,  #42b289 0%, #13faa6 44%, #08dbd5 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(left,  #42b289 0%,#13faa6 44%,#08dbd5 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to right,  #42b289 0%,#13faa6 44%,#08dbd5 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#42b289', endColorstr='#08dbd5',GradientType=1 ); /* IE6-9 */

		transition: width (@duration * 2) @easing;
	}

	&.is-active {
		.ml-progress__bar {
			animation: shadowFlash (@duration * 2) @easing alternate;
		}
	}
}
