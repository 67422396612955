@import url("https://fonts.googleapis.com/css?family=Nunito");
@import "../../assets/variables.scss";

.topSection {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../../assets/backgrounds/hubble.jpg") center;
  background-size: cover;
  letter-spacing: 3px;
  height: 250px;
}

.profilePhoto {
  margin-bottom: 10px;
  height: 100px;
  width: 100px;
  border: solid 2px white;
  border-radius: 50%;
}
.updatePictureFrame {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 20px;
  .pictureLabel {
    display: none;
  }
  .fileInputFrame {
    margin-bottom: 30px;
    height: 40px;
    width: 150px;
    color: #0ff;
    border: 2px solid currentcolor;
    background-color: transparent;
    border-radius: $border-radius-button;
    font-size: 13px;
    line-height: 38px;
    padding: 0 20px;
    box-shadow: 0 0 8px currentcolor, inset 0 0 8px currentcolor;
    transition: box-shadow .25s ease; 


    &:hover {
      cursor: pointer;
      color: #0ff;
      box-shadow: 0 0 6px currentcolor
    }
    &:active {
      min-width: 150px;
      height: 40px;
    }

    .fileInput {
      display: none;
    }
    @extend %focus-light;
  }
  
  .smallProfilePhoto {
    display: none;
  }
}

.details {
  text-align: center;
  color: white;
  h4 {
    font-weight: 400;
    color: #00ffd2;
    margin-top: $base-padding/2;
  }
}

.mainSection {
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 500px;
  color: white;
  h2 {
    letter-spacing: 3px;
  }
}

.profileForm {
  margin-top: 20px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  label {
    padding: 0px;
    align-self: flex-start;
  }
  input {
    margin: 0px 0px 0px 0px;
  }
}

@media only screen and (min-width: 1080px) {
  .updatePictureFrame {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 42px;
    width: 565px;
    margin-top: 0;
    .fileInputFrame {
      margin-top: 30px;
    }
    .pictureLabel {
      text-align: left;
      display: inline-block;
    }
    .smallProfilePhoto {
      display: inline-block;
      height: 60px;
      width: 60px;
      margin-left: 50px;
      margin-right: 50px;
    }
  }

  .mainSection {
    align-items: flex-start;
  }
  .details {
    margin-left: 20px;
    text-align: left;
  }
  .profileForm {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 15px;
    label {
      text-align: right;
      padding-top: 20px;
    }
    input {
      width: 100%;
    }
  }

  .topSection {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    height: 250px;
  }

  .profilePhoto {
    margin-top: 0px;
    margin-bottom: 0px;
    height: 150px;
    width: 150px;
    margin-left: 50px;
  }
}
