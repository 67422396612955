.en-overview {
	margin-top: 40px;

	// .column {
	// 	padding: 0;
	// 	float: none !important;
	// 	display: inline-block;
	// 	vertical-align: middle;
	// }

	&__list {
		margin-top: 35px;
		margin-bottom: 20px;
	}	

	&__header {
		position: absolute;
		right: 0;
		top: -6px;
		transform: translateY(-100%);
	}

	@media screen and (max-width: @s1) {
		&__value {
			position: static;
		}

		&__progress {
			width: 100%;
		}
	}
}