.dropdown {
	text-transform: uppercase;
	display: inline-block;
	vertical-align: middle;
	min-width: 160px;
	max-width: 190px;
	font-size: 0;
	position: relative;
	user-select: none;
	z-index: 5;

	&.is-active {
		z-index: 10;
	}

	.ps-scrollbar-y-rail {
		z-index: 5;
	}

	&__box {
		position: relative;
		width: 100%;
		padding: 5px 0;
		cursor: pointer;
		padding-right: 20px;
		white-space: nowrap;
		font-size: 1px;
	}

	&__selected {
		font-size: 15px;
		line-height: 15px;
		text-overflow: ellipsis;
		width: 100%;
	    overflow: hidden;
	    display: inline-block;
	}

	&__carret {
		position: absolute;
		right: 0;
		top: 50%;
		margin-top: -3px;
		display: inline-block;

		&:after {
			content: '';
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 5px 4px 0 4px;
			border-color: @active-blue transparent transparent transparent;
			transition: transform 0.2s @easing;
			display: block;
		}

		&.is-active:after {
			transform: rotate(180deg);
		}
	}

	&__list {
		width: 100%;
		border: 1px solid #2a426a;
		background-color: #253251;
		padding: 5px 0;
		position: absolute;
		top: 100%;
		z-index: 5;
		background-visibility: hidden;
	}

	&__item {
		font-size: 15px;
		line-height: 15px;
		color: @text-blue;
		padding: 10px 10px;
		cursor: pointer;
		transition: background @duration @easing,
		color @duration @easing;
		overflow: hidden;
		text-overflow: ellipsis;
		position: relative;
		z-index: 1;

		&:hover, &.is-active {
			background: #244677;
			color: #fff;
		}
	}


	&--light {
		box-shadow: 0 0 0 1px @gray2;

		.dropdown__item, .dropdown__selected {
			color: @green;
			text-align: left;
		}

		.dropdown__carret:after {
			border-color: #37505b transparent transparent;
		}

		.dropdown__list {
			background-color: #fff;
			box-shadow: 0 0 0 1px @gray2;
			border: 0;
		}

		.dropdown__item.is-active, 
		.dropdown__item:hover {
			background-color: @green;
			color: #fff;
		}
	}

	&--round {
		max-width: 215px;

		.dropdown {
			&__box {
				padding: 0;
			}

			&__selected {
				font-size: 13px;
				color: #fff;
				padding: 0 20px;
				width: ~'calc(100% - 46px)';
			}

			&__carret {
				height: 36px;
				width: 40px;
				top: 0;
				margin-top: 0;
				position: relative;
				padding: 0 15px;
				margin-left: 6px;
				backface-visibility: hidden;

				&:after, &:before {
					position: absolute;
					border: none;
					height: 2px;
					width: 8px;
					top: 50%;
					margin-top: -1px;
					background-color: #fff;
					content: '';
					transition: transform @duration @easing;
				}

				&:after {
					left: 50%;
					margin-left: -1px;
					transform: rotate(-45deg);
				}
				&:before {
					right: 50%;
					transform: rotate(45deg);
					margin-right: -2px;
				}

				&.is-active {
					&:after {
						transform: rotate(45deg);
					}
					&:before {
						transform: rotate(-45deg);
					}
				}
			}

			&__carret, &__selected {
				line-height: 36px;
				background-color: #091a33;
				border: 1px solid #303c4b;
				border-radius: 18px;
				vertical-align: top;
			}

			&__list {
				background-color: #091a33;
				border: 1px solid #303c4b;
				border-radius: 18px;
				padding: 0;
				margin-top: 2px;
			}

			&__item {
				font-size: 13px;
				color: #9ea3b4;

				&.is-active {
					color: #fff;
				}
			}
		}
	}

	&--lighter {
		.dropdown {
			&__carret, &__selected {
				border: none;
				background-color: transparent;
			}

			&__carret {
				position: absolute;
			}

			&__selected {
				padding-right: 40px;
				width: 100%;
			}

			&__box {
				background: #1c375e;
				border: 1px solid #3e475e;
				border-radius: 18px;
			}

			&__list {
				border: 1px solid #3e475e;
			}
		}
	}

	&--squared {
		background-color: #2a3537;

		.dropdown {
			&__box {
				padding-left: 10px;
				padding-right: 25px;
			}

			&__carret {
				right: 10px;
			}

			&__selected {
				color: #fff;
				line-height: 20px;
				letter-spacing: 1px;
				text-transform: none;
			}

			&__list {
				background-color: #2a3537;
				padding: 0;
			}

			&__item {
				margin: 0;
				color: #fff;
				letter-spacing: 1px;
				text-transform: none;
			}
		}
	}

	&--blue {
		color: #799bcd;
	    box-shadow: 0 0 0 1px #274e7b;
		background-color: #10253e;

		.dropdown {
			&__selected, &__item {
				color: #799bcd;
			}

			&__list {
				border-color: #274e7b;
				background-color: #10253e;
			}
		}
	}
}