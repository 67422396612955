@import "../../assets/variables.scss";

.button {
  margin: 10px;
  height: 40px;
  width: 150px;
  color: #0ff;
  border: 2px solid currentcolor;
  background-color: transparent;
  border-radius: $border-radius-button;
  font-size: 13px;
  line-height: 38px;
  padding: 0 20px;
  box-shadow: 0 0 8px currentcolor, inset 0 0 8px currentcolor;
  transition: box-shadow .25s ease; 


  &:hover {
    cursor: pointer;
    color: #0ff;
    box-shadow: 0 0 6px currentcolor
  }
  &:active {
    min-width: 150px;
    height: 40px;
  }
  @extend %focus-light;
}
