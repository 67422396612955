@import "../../assets/variables.scss";

%flex-column-centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pageNotFoundContainer {
  width: 100%;
  height: 75vh;
  display: flex;
  @extend %flex-column-centered;

  h1 {
    font-size: 2em;
    width: 100%;
    text-align: center;
    padding: 15px;
  }
  h2 {
    // font-size: 1em;
    width: 100%;
    text-align: center;
    padding: 15px;
  }
  span {
    color: $primary-color-5;
  }
}
