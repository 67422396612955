$temp-margin: 10px;
@import "../../assets/variables.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.nav {
  background-color: #022b3e;
  box-sizing: border-box;
  // min-height: 100vh;
  max-height: 90vh;
  overflow: auto;
  width: 100vw;
  position: fixed;
  top: 60px;
  z-index: 2;
}
.navIsVisible {
  transform: translateY(0);
}

.icon {
  color: #294260;
  font-size: 2em;
  transition: fill .25s ease;
}

.navFlex {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  margin-top: 0px;
  max-width: 100vw;

  .navText {
    color: white;
    list-style: none;
    padding-bottom: 25px;
    border-bottom: 1px solid white;
    margin-top: 15px;
  }

  .navLinks {
    width: 100%;
    text-align: center;
    padding: 15px 0;
    display: inline-block;
  }
}

@media only screen and (min-width: 1000px) {
  .nav {
    background-color: #022b3e;
    min-height: 100vh;
    float: left;
    min-width: 4em;
    max-width: 4em;
    position: fixed;
    // background: linear-gradient(to bottom,  #010a13 0%,#022b3e 100%);

    .icon {
      font-size: 1.8em;
    }
  }
  .navText {
    display: none;
  }
  .navFlex {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    .navLinks {
      // color: white;
      // border-bottom: 1px solid white;
      margin: 12px 0;
    }
    .navLinks:hover {
      //background-color: #08b1ff;
      .icon {
        color: #08b1ff;
      }
    }
  }
}
