.th-header {
	color: #00ffd2;

	&__content {
		padding-left: 30px;
		position: relative;

		&:before {
			position: absolute;
			content: '';
			width: 4px;
			height: 100%;
			background-color: currentcolor;
			left: 0;
			top: 0;
			box-shadow: 0 0 5px;
		}
	}

	&__hint, &__title {		
		&:not([class*="l-ttr"]) {
			text-transform: uppercase;
		}

		&--cap {
			text-transform: capitalize;
		}
	}

	&__hint {
		font-size: 12px;
		padding-bottom: 13px;
		padding-top: 5px;
		line-height: 1;

		&:first-child {
			padding-top: 0;
		}

		&:before {
			display: inline-block;
			content: 'A';
			width: 0;
			visibility: hidden;
		}
	}

	&__title {
		color: #fff;
		font-size: 30px;
		line-height: 1.2;
		margin-top: -3px;
		margin-bottom: -9px;

		&:not(:last-of-type) {
			padding-bottom: 15px;
		}

		&:before {
			display: inline-block;
			content: 'A';
			width: 0;
			visibility: hidden;
		}

		&--big {
			font-size: 40px;
		}

		&--small {
			font-size: 25px;
		}
	}

	&__help {
		text-transform: initial;
		color: #fff;
	}
}