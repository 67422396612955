.view-info  {
	border: 1px solid #213c67;
	border-radius: 8px;
	padding: 9px 15px;

	&__level {
		color: @active-blue;
		font-size: 18px;
	}

	&__id, &__name {
		color: #4a6da5;
		font-size: 13px;
	}

	@media screen and (max-width: @s2) {
		display: none;
	}
}