body {
  margin: 0;
  padding: 0;
  font-family: "Nunito", sans-serif;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: "Nunito", sans-serif;
} */

a {
  text-decoration: none;
}
.navIsVisible {
  visibility: hidden;
}
@media only screen and (min-width: 1000px) {
  .navIsVisible {
    visibility: unset;
  }
}
