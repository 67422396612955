@import "../../../assets/variables.scss";

.input {
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: $base-padding;
  color: $secondary-text-color;
  height: 2em;
  @extend %focus-light;
}
.input:focus {
  color: $secondary-text-color;
}
