.icon {
	font-size: 14px;
	width: 2em;
	height: 2em;
	color: @dark-blue;
	cursor: pointer;
	display: inline-block;
	transition-duration: @duration;
	transition-easing: @easing;
}

svg.icon {
	fill: @dark-blue;
	transition-property: fill, filter;
	-webkit-transition-property: fill, -webkit-filter;
	
	&:hover,  {
		fill: @active-green;
	}

	&.is-active {
		fill: @active-green;	
	}
}