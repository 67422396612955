.accordion {
	border: 1px solid #144a75;
	border-radius: 8px;
	padding: 0 14px;
	position: relative;
	line-height: 0;
	transition: box-shadow @duration @easing, opacity .1s ease, border-color .1s ease;
	color: @active-green;

	&:not(:last-child) {
		margin-bottom: 30px;
	}

	&__header {
		padding-left: 20px;
		border-top: 15px solid transparent;
		border-bottom: 15px solid transparent;
		border-right: 5px solid transparent;
		cursor: pointer;
		-webkit-user-select: none;
		user-select: none;
		position: relative;
		display: inline-table;
		width: 100%;

		&:before {
			.shared-square-icon;
			position: absolute;
			top: 50%;
			margin-top: -11px;
			left: 0;
			transition: background-color @duration @easing;
			background-color: currentcolor;
		}
	}

	&__name {
		.shared-item-name;
		.f-avn-r;
		display: table-cell;
		vertical-align: middle;
		width: 100%;
		word-break: break-word;
	}

	&__actions {
		font-size: 0;
		display: flex;
		width: auto;
		vertical-align: middle;
		white-space: nowrap;
		padding-left: 15px;
	}

	&__arrow {
		display: inline-block;
		vertical-align: middle;
		position: relative;
		font-size: 0;
		width: 14px;
		height: 8px;
		transition: transform @duration @easing;
		margin-left: 15px;

		&:after, &:before {
			content: '';
			display: block;
			width: 73%;
			height: 1px;
			background-color: #00baff;
			position: absolute;
			top: 0;
		}

		&:before {
			transform-origin: center left;
			transform: rotate(45deg);
			left: 0;
		}
		&:after {
			transform-origin: center right;
			transform: rotate(-45deg);
			right: 0;
		}
	}

	&__toggle {
		.shared-square-icon;
		transition: box-shadow @duration @easing;
		color: #144a75;
		background-color: currentcolor;
		position: relative;

		&:after, &:before {
			display: block;
			content: '';
			width: 80%;
			height: 2px;
			border-radius: 2px;
			background-color: @active-green;
			position: absolute;
			top: 50%;
			margin-top: -1px;
			left: 50%;
			transition: transform @duration @easing;
		}

		&:before {
			transform: translateX(-50%);
		}

		&:after {
			transform: translateX(-50%) rotate(90deg);
		}

		&:hover {
			box-shadow: 0 0 5px 1px;
		}
	}

	&.is-active {
		.accordion__arrow {
			transform: rotate(180deg);
		}
	}

	&.is-active > .accordion__header {
		.accordion__toggle {
			&:before {
				transform: translateX(-50%) rotate(45deg);
			}

			&:after {
				transform: translateX(-50%) rotate(135deg);
			}
		}
	}

	&__content {
		position: relative;
		padding-bottom: 15px;
		display: none;

		&:before {
			content: '';
			display: block;
			border-top: 1px solid #203c61;
			margin-bottom: 15px;
		}
	}

	&__text {
		font-size: 13px;
		line-height: 1.6;
		color: #fff;
		padding: 0 8px;
		padding-bottom: 10px;
		.f-avn-r;
	}
}



// <div class="accordion">
//     <div class="accordion__header">
//         <p class="accordion__name"></p>
//         <div class="accordion__actions">
//             <span class="accordion__arrow"></span>
//         </div>
//     </div>
//     <div class="accordion__content">
//         <p class="accordion__text"></p>
//     </div>
// </div>
