@import "../../assets/variables.scss";
$icon-width: 42px;

.header {
  background-color: #022b3e;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 $base-margin / 2;
  min-height: 60px;
  width: 100vw;
  z-index: 1;
  // border-bottom: 1px solid #6ecbd3;

  .icon {
    color: white;
    font-size: 25px;
    width: $icon-width;
    cursor: pointer;
  }

  .icon2 {
    font-size: 2em;
    color: white;
    margin-right: 5px;
    padding-left: 5px;
  }

  .icon3 {
    color: rgb(255, 255, 255);
    margin-right: 2px;
    // padding-right: 5px;
    margin-bottom: 5px;
  }

  .logo {
    padding-top: $base-padding / 4;
    height: 35px;
    width: 35px;
    display: none;
  }

  .menuName {
    font-size: 20px;
  }

  .headerText {
    @extend %heading;
    text-transform: uppercase;
  }

  .profileMenu {
    background-color: #003864;
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: auto;
    padding: 8px;
    // margin: 8px;
    border-radius: 5px;
    position: absolute;
    top: 60px;
    right: 10px;
  }
  .profileIcons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 10%;
    padding-right: 5px;
    cursor: pointer;
  }

  ul {
    list-style: none;
    li {
      margin: 8px 0px;
      font-size: 1.2em;
    }

    li:hover {
      color: white;
    }
  }

  span {
    display: flex;
    align-items: center;
  }
}

@media only screen and (min-width: 1000px) {
  .header {
    max-width: 100%;
    .logo {
      margin-left: 0.3px;
      height: 50px;
      width: 50px;
      display: unset;
    }

    .icon {
      margin-right: 1px;
      display: none;
    }

    .icon2 {
      color: white;
      font-size: 2em;
      margin-right: 5px;
    }
  }
}
