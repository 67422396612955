.cookies {
	position: fixed;
	width: 100%;
	z-index: 80;
	bottom: 0;
	left: 0;
	background-color: #073554;
	padding: 20px 30px;
    font-family: 'Avenir Next';
    display: none;

	&__title {
		font-size: 25px;
		color: #fff;
	}

	&__subtitle {
		font-size: 14px;
		color: #fff;
		padding-left: 40px;
	}

	&__button {
		background-color: #00a99d;
		color: #fff;
		font-size: 14px;
		line-height: 30px;
		width: 150px;
		text-transform: uppercase;
		border-radius: 4px;
		transition: background-color .25s ease;

		&:hover {
			background-color: #00ccbe;
		}
	}
}