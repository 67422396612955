@import "../assets/variables.scss";

.wrapper {
  display: flex;
  justify-content: space-between;
  max-width: 100vw;
  max-height: 100vh;
  min-height: 75vh;
  min-width: 100%;
}

.pageText {
  display: none;
  padding: 20px 20px 0px 0px;
}

.sliderBars {
  text-align: left;
}

.slider {
  margin-top: 15px;
  width: 80%;
  -webkit-appearance: none;
  appearance: none;
  height: 7px;
  background: #d3d3d3;
  border-radius: 25px;
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

@media only screen and (min-width: 768px) {
  .pageText {
    display: unset;
    width: 30%;
  }
}
