.footer {
	display: inline-block;
	margin-left: -5px;
	width: 266px;

	&__text {
		font-size: 11px;
		line-height: 14px;
		color: @text-dark-blue;
	}

	a {
		color: inherit;
	}

	&__logo {
		max-width: 67px;
		fill: @text-dark-blue;
		position: relative;
		left: -5px;
		max-height: 53px;
	}

	&__label {
		display: inline-block;
		background-color: #132a48;
		text-transform: uppercase;
		padding: 0 12px;
		line-height: 18px;
		font-size: 10px;
		border-radius: 2px;
		margin-bottom: 7px;
	}
}