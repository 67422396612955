.preloader {

    &__text {
        font-size: 16px;
        position: absolute;
        bottom: 0;
        left: 50%;
        white-space: nowrap;
        transform: translateX(-50%) translateY(15px);
        padding-bottom: 5px;
    }

    &--centered {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 99;

        .preloader__wrapper {
        	position: fixed;
        	top: 50%;
        	left: 50%;
        	margin-top: -30px;
        	margin-left: -30px;
        }
    }

    &--bottom {
        position: fixed;
        bottom: 0;
        left: 0;
        height: auto;
        min-height: 80px;
        width: 100%;
        z-index: 99;

        .preloader__wrapper {
            position: relative;
            top: 0;
            margin-top: 20px;
        }

        .preloader__objects {
            margin: 0 auto;
        }

        .preloader__text {
            position: relative;
            left: 0;
            width: 100%;
            text-align: center;
            transform: none;
        }
    }

    &--page {
        position: fixed;
        background-color: #fff;
        z-index: 999;
    }

    &__wrapper {
        position: relative;
    }

    &__objects {
        width: 60px;
        height: 60px;
        animation: loading-spin 1s infinite;
        position: relative;
    }

    &__object {
        width: 20px;
        height: 20px;
        background-color: @active-blue;
        float: left;
        border-radius: 50%;
        margin-right: 20px;
        margin-bottom: 20px;
        &:nth-child(2n+0) {
            margin-right: 0px;
        }

        &--1 {
        	animation: object_one 1s infinite;
        }
        &--2 {
        	animation: object_two 1s infinite;
        }
        &--3 {
        	animation: object_three 1s infinite;
        }
        &--4 {
        	animation: object_four 1s infinite;
        }
    }
}

@keyframes loading-spin {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes object_one {
    50% {
        transform: translate(20px, 20px);
    }
}

@keyframes object_two {
    50% {
        transform: translate(-20px, 20px);
    }
}

@keyframes object_three {
    50% {
        transform: translate(20px, -20px);
    }
}

@keyframes object_four {
    50% {
        transform: translate(-20px, -20px);
    }
}
