.legal-info {
	display: none;
	position: fixed;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	animation: fadeIn .25s ease;
	background-color: rgba(255, 255, 255, 0.01);

	&__header {
		color: @active-green;
		margin-bottom: 40px;
	}

	&__content {
		border: 1px solid #024266;
		border-radius: 8px;
		padding: 25px 20px;
		max-height: 60vh;
		position: relative;
		overflow: hidden;
	}

	p {
		color: #fff;
		font-size: 13px;

		&:not(:last-child) {
			margin-bottom: 30px;
		}
	}

	a {
		color: @active-green;
	}

	&__close {
		text-transform: uppercase;
		font-size: 13px;
		margin-top: 50px;
		display: inline-block;
		cursor: pointer;
		color: @active-green;

		.th-close-btn {
			width: 30px;
			height: 30px;
			margin-left: 10px;
			color: @active-green;
			box-shadow: 0 0 5px;
		}
	}
}