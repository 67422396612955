.breadcrumb {
	background-color: #0d2e50;
	padding: 0 5px;
	border-radius: 16px;

	.th-ask-icon {
		color: inherit;
		border-color: currentcolor;
		width: 15px;
		height: 15px;
		line-height: 15px;
		font-size: 12px;
		position: absolute;
		right: 4px;
		top: 50%;
		margin-top: -8px;
	}

	&__item {
		background-color: #213c67;
		font-size: 12px;
		line-height: 24px;
		border-radius: 12px;
		min-width: 80px;
		color: #0e2540;
		padding-left: 7px;
		padding-right: 7px;
		margin-right: 15px;
		text-transform: uppercase;

		transition: 
			background-color @duration @easing,
			color @duration @easing;
		animation: zoomIn @duration @easing alternate;

		&:last-child {
			margin-right: 0;
		}

		&:hover, &.is-active {
			background-color: @active-blue;
			color: #fff;
		}
	}

	&__star {
		color: inherit;
	}
}