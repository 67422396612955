.nav {
	font-size: 0;
    white-space: nowrap;

    &:not(:last-child) {
    	margin-bottom: 25px;
    }

	&__icon-wrapper {
		text-align: center;
		width: @iconBarSize;
	}

	&__icon {
		fill: @dark-blue;
	}

	&__thumb {
		width: 100%;
		height: 43px;
		background-size: 100%;
		background-size: cover;
	}

	.icon {
		display: inline-block;
		max-height: 25px;
		width: 60%;
		transition: fill @duration @easing;
	}

	&__header {

		.icon {
			display: inline-block;
			vertical-align: middle;
		}	

		&:hover {

			.sidebar__nav-action {
				background-color: #122a49;
			}
		}
	}		

	&__header, &__child span {
		line-height: 43px;
	}

	&__action, &__child span {
		transition: background-color @duration @easing;
		text-transform: uppercase;
		color: #fff;
		font-size: 12px;
	}

	&__action {
		&:not(:first-child) {
			&:before {
				display: inline-block;
			    content: '/';
			    line-height: 1;
			    font-size: 14px;
			    vertical-align: middle;
			    margin-right: 4px;
			    margin-left: 4px;
			    color: #9A9A9A;
			}
		}
	}

	&__action__list {
		width: ~'calc(100% - 45px)';
		color: #fff;
		border-left: 4px solid currentcolor;
		padding-left: 25px;
		background-color: #122a49;
		transition: background-color @duration @easing;
		display: inline-block;
		vertical-align: middle;
		transition: color @duration @easing;
	}

	&__childs {
		padding-left: 45px;
		display: none;

		&-wrapper {
			background-color: rgba(19,42,72,.45);
			padding-left: 30px;
		}
	}

	&__child {
		display: block;
		padding-left: 25px;
		position: relative;
		color: currentcolor;

		&:before {
			content: '';
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			width: 100%;
			border-left: 4px solid currentcolor;
			background-color: #122a49;
			transition: opacity @duration @easing;
			opacity: 0;
			z-index: 0;
		}

		&:hover:before, &.is-active:before {
			opacity: 1;
		}

		span {
			position: relative;
		}

		&--list {
			padding-left: 15px;
		}
	}

	&.is-active {
		.icon {
			fill: @navActiveColor;
		}
	}
}
