.task {
	color: #0d4864;
	border: 1px solid currentcolor;
	border-radius: 8px;
	padding: 14px;
	padding-left: 37px;
	cursor: pointer;
	position: relative;
	user-select: none;
	display: inline-table;
	transition: 
		color @duration @easing, 
		box-shadow @duration @easing,
		opacity .1s ease;
	line-height: 0;

	&:not(:last-child) {
		margin-bottom: 25px;
	}

	&:before {
		.shared-square-icon;
		position: absolute;
		left: 15px;
		top: 50%;
		margin-top: -11px;
	}

	&:hover {
		box-shadow: 0 0 10px currentcolor;
	}

	&__name {
		.shared-item-name;
		word-break: break-word;
		display: table-cell;
		width: 100%;
		vertical-align: middle;
	}

	&__actions {
		letter-spacing: 20px;
		font-size: 0;
		vertical-align: middle;
		display: table-cell;
		width: auto;
		padding-left: 15px;
		white-space: nowrap;
	}

	&__check {
		width: 28px;
		height: 28px;
		border: 2px solid #00baff;
		position: relative;
		transition: border-color @duration @easing;

		&:after {
			position: absolute;
			top: 50%;
			left: 50%;
			margin-top: -7px;
			margin-left: -7px;
			content: '';
			display: block;
			width: 15px;
			height: 9px;
			border: 2px solid #00baff;
			border-right: none;
			border-top: none;

			transform: rotate(-45deg);
			transition: opacity @duration @easing;
		}
	}

	&:not(.task--done) {
		color: #af0f5f;

		&:before {
			background-color: #af0f5f;
		}

		.task__check {
			border-color: #af0f5f;

			&:after {
				opacity: 0;
			}
		}
	}
}


// <div class="task">
//     <p class="l-dis-ib task__name f-avn-r"></p>
//     <div class="task__actions">
//     	<div class="tasks__check"></div>
//     </div>
// </div>
