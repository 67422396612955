/*  */

header {
  font-family: 'Avenir Next', sans-serif;
  letter-spacing: 6px;
  font-size: 1.5rem;
  color: white;
}

body {
  font-family: 'Avenir Next';
  font-size: 12pt;
  color: white;
  background-image: url("../src/assets/backgrounds/background.jpg");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}

h1,
h2,
h3 {
  font-family: 'Avenir Next';
  font-size: 13pt;
  color: white;
}

* {
  box-sizing: border-box;
}

a {
  color: #6ecbd3;
}
