/*----------  Quick actions styles  ----------*/
.qa {
	position: absolute;
	z-index: 999;
	display: none;

	&.is-visible {
		display: block;
	}
	
	.resources-icon { width: 20px; }
	.qw-icon { width: 20px; }

	&__icon {
		width: 60%;
		max-height: 100%;
		max-width: 20px;
		position: absolute;
		z-index: 1;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
		fill: #32568d;
		backface-visibility: hidden;
		pointer-events: none;
		transition: 
			fill @duration @easing,		
			filter @duration @easing,		
			-webkit-filter @duration @easing,
	}

	&__name {
		text-transform: uppercase;
		font-size: 10px;
		color: #5574a0;
		transition: color @duration @easing, text-shadow @duration @easing;
	    white-space: nowrap;
	    position: absolute;
	    z-index: 1;
	    top: 50%;
	    left: 100%;
	    margin-left: 13px;
	    line-height: 16px;
	    margin-top: -8px;
	    backface-visibility: hidden;
	}

	&__item {
		// opacity: 0.4;
		width: 36px;
		height: 36px;
		border-radius: 50%;
		box-shadow: inset 0 0 0 2px #32568d;
		position: absolute;
		z-index: 1;
		cursor: pointer;
		margin-top: -18px;
		margin-left: -18px;

		&:after, &:before, .wave-item {
			content: '';
			pointer-events: none;
			border: 1px solid #32568d;
			border-radius: 50%;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translateY(-50%) translateX(-50%);
			width: 100%;
			height: 100%;
			z-index: 0;
			transition: transform @duration @easing, opacity @duration @easing;
			pointer-events: none;
		}

		&:before {
			opacity: 0.5;
		}

		&:after {
			transform: translateY(-50%) translateX(-50%) scale(2.5);
			opacity: 0;
		}

		.wave-item {
			transform: translateY(-50%) translateX(-50%) scale(2.2);
			opacity: 0;
		}

		&:hover {
			.qa__icon {
				fill: @active-green;
			}

			&:before {
				transform: translateY(-50%) translateX(-50%) scale(1.3);
			}

			&:after {
				transform: translateY(-50%) translateX(-50%) scale(1.6);
				opacity: 0.3;
			}

			.wave-item {
				transform: translateY(-50%) translateX(-50%) scale(1.9);
				opacity: 0.1;
			}

			.qa__name {
				color: @active-green;
			    text-shadow: 0 0 7px rgba(6, 222, 212, 0.9);
			}
		}
	}

	&--static {
		left: 50%;
		transform: translateY(-50%);
		margin-left: 10px;

		.qa__item {
			margin-top: 0;
			margin-left: 0;
			position: relative;

			&:not(:last-child) {
				margin-bottom: 25px;
			}
		}
	}

	// orientations styles
	&--left {
		transform: translateY(-50%) translateX(-100%);
		margin-left: -10px;

		.qa__name {
			left: 0;
			margin-left: -13px;
			transform: translateX(-100%);
		}
	}
}