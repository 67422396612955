.performance {
	position: absolute;
	z-index: 5;
	top: 0;
	left: 0;
	width: 100%;
	height: 50px;
	background-color: rgba(3, 15, 32, .7);
	padding-left: 430px;
	padding-right: 15px;

	.ecg-icon {
		width: 32px; 
		fill: @dark-blue;
		position: relative;
		top: 3px;
		margin-right: 14px;
	}

	&__title {
		font-size: 13px;
	}

	&__state {
		margin-left: 10px;
		width: 32px;
		height: 32px;
		border-radius: 50%;
		border: 2px solid @dark-blue;
		position: relative;

		svg {
			width: 60%;
			fill: #db0974;
			display: block;
			position: relative;
			top: -1px;
			margin: 0 auto;
			pointer-events: none;
		}
	}

	&__progress {
		width: 165px;
		height: 9px;
		position: relative;
		border-radius: 4px;
		top: -1px;
		background-color: #21406d;
		margin: 0 25px;

		&--bar {
			border-radius: 4px;
			position: absolute;
			background-color: currentcolor;
			height: 100%;
			left: 0;
			top: 0;
			box-shadow: 0 0 7px currentcolor;
		}
	}

	&__label {
		text-transform: capitalize;
		color: #718fb7;
		font-size: 11px;
		line-height: 17px;
		background-color: #12293d;
		padding: 0 17px;
		padding-top: 1px;
		border-radius: 8px;
	}



	&__graph {
		border: 1px solid #042536;
		box-shadow: 0 0 0 1px #033544;
		border-radius: 15px;
		padding-left: 15px;
		padding-right: 5px;
		padding-bottom: 5px;
		padding-top: 5px;
		font-size: 0;

		&__title {
			text-transform: uppercase;
			color: #fff;
			font-size: 12px;
		}
	}



	&__details {
		color: #10bade;
		font-size: 9px;
		text-transform: uppercase;

		&-icon {
			border-radius: 50%;
			width: 40px;
			height: 40px;
			line-height: 57px;
			border: 2px solid #033442;
			position: relative;
			margin-left: 6px;
			text-align: center;
		}

		svg {
			fill: @active-green;
			width: 18px;
			display: inline-block;
			pointer-events: none;
		}
	}

	.wrap {
		display: table;
		width: 100%;
		height: 100%;
	}

	.wrap-cell {
		display: table-cell;
		vertical-align: middle;
		white-space: nowrap;

		&:last-child {
			text-align: right;
		}

		&:not(:last-child):not(:first-child) {
			text-align: center;
		}
	}

	@media screen and (max-width: 1750px) {
		&__label {
			display: none;
		}

		&__progress {
			margin-right: 5px;
		}
	}

	@media screen and (max-width: 1450px) {
		&__progress {
			width: 130px;
			margin-left: 15px;
		}

		&__graph__title {
			font-size: 11px;
		}
	}

	@media screen and (max-width: 1350px) {
		padding-left: 400px;

		&__progress {
			width: 100px;
		}
	}

	@media screen and (max-width: @s1) {
		padding-left: 380px;
	}

	@media screen and (max-width: 1240px) {
		padding-left: 360px;

		&__title {
			display: none;
		}

		.ecg-icon {
			display: none;
		}

		&__state {
			margin-left: 0;
		}
	}

	@media screen and (max-width: 1060px) {
		&__header {
			display: none;
		}
	}
}