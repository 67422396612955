@import "../../assets/variables.scss";

.button {
  margin: 10px;
  height: 40px;
  width: 150px;
  border-color: $primary-color-5;
  color: $primary-text-color;
  background-color: transparent;
  border-radius: $border-radius-button;
  border-width: $border-radius-weight;

  &:hover {
    cursor: pointer;
    background-color: $primary-color-5;
    color: white;
  }
  &:active {
    background-color: $primary-color-4;
    min-width: 150px;
    height: 40px;
  }
  @extend %focus-light;
}

.button2 {
  margin: 10px;
  height: 40px;
  width: 150px;
  border-color: gray;
  color: gray;
  background-color: transparent;
  border-radius: $border-radius-button;
  border-width: $border-radius-weight;

  &:hover {
    cursor: pointer;
    background-color: gray;
    color: white;
  }
  &:active {
    background-color: gray;
    min-width: 150px;
    height: 40px;
  }
  @extend %focus-light;
}
