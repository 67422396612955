@import "../../assets/variables.scss";
.mobileHidden {
  display: none;
}

.rightPanel {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.text {
  max-width: 25em;
  text-align: center;
  padding-bottom: $base-padding;
  font-size: 14pt;
  color: white;
  padding-left: $base-padding;
}

.questionnaire {
  padding-bottom: $base-padding * 4;
  padding-top: $base-padding;
  height: 200px;
  color: $primary-text-color;
  width: 100%;
}
.buttonContainer {
  margin-bottom: 10px;
}

.questionnaireContainer {
  height: 100vh;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.scoreIndicator {
  width: 40px;
  height: 40px;
  // background-color: $primary-color-5;
  // border: 3px $primary-color-3;
  color: #0ff;
  border: 2px solid currentcolor;
  background-color: transparent;
  font-size: 13px;
  line-height: 38px;
  padding: 0 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.paratext {
  color: $primary-text-color;  
  font-weight: 500;
}

.slideContainer {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
}

.slider {
  margin-top: 15px;
  width: 80%;
  -webkit-appearance: none;
  appearance: none;
  height: 7px;
  // background: #d3d3d3;
  border-radius: 25px;
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  @extend %focus-light;
}

.slider:focus {
  @extend %focus-light;
}

// .slider::-webkit-slider-thumb {
//   -webkit-appearance: none;
//   appearance: none;
//   width: 25px;
//   height: 25px;
//   background: $primary-color-5;
//   cursor: pointer;
//   border-radius: 50%;
// }

// .slider::-moz-range-thumb {
//   width: 25px;
//   height: 25px;
//   cursor: pointer;
// }

@media only screen and (min-width: 768px) {
  .mobileHidden {
    display: unset;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .questionnaire {
    padding-bottom: $base-padding * 4;
    padding-top: $base-padding;
    height: 200px;
    color: $primary-text-color;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: left;
  }

  .slideContainer {
    flex-direction: row-reverse;
  }

  .questionContainer {
    min-width: 70%;
  }

  .containerMainPage {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .rightPanel {
    max-width: 30%;
    position: fixed;
    right: 0;
  }

  .text {
    padding-right: 100px;
    max-width: 40em;
    text-align: left;
    padding-left: $base-padding;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .rightPanel {
    width: 30%;
    padding-right: 5px;
  }

  .questionnaire {
    width: 70%;
    padding-left: $base-padding;
  }

  .buttonContainer {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}


input[type=range]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  background: rgb(254,0,109);
  background: -moz-linear-gradient(90deg, rgba(254,0,109,1) 0%, rgba(126,0,255,1) 40%, rgba(0,255,210,1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(254,0,109,1) 0%, rgba(126,0,255,1) 40%, rgba(0,255,210,1) 100%);
  background: linear-gradient(90deg, rgba(254,0,109,1) 0%, rgba(126,0,255,1) 40%, rgba(0,255,210,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fe006d",endColorstr="#00ffd2",GradientType=1);
  height: 7px;
}

input[type=range]:focus {
  outline: none;
}

input[type=range]::-moz-range-track {
  -moz-appearance: none;
  background: rgb(254,0,109);
  background: -moz-linear-gradient(90deg, rgba(254,0,109,1) 0%, rgba(126,0,255,1) 40%, rgba(0,255,210,1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(254,0,109,1) 0%, rgba(126,0,255,1) 40%, rgba(0,255,210,1) 100%);
  background: linear-gradient(90deg, rgba(254,0,109,1) 0%, rgba(126,0,255,1) 40%, rgba(0,255,210,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fe006d",endColorstr="#00ffd2",GradientType=1);
  height: 7px;
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: 1px solid;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  max-width: 80px;
  position: relative;
  bottom: 11px;
  background-color: #6435f6;
  cursor: -webkit-grab;

  -webkit-transition: border 1000ms ease;
  transition: border 1000ms ease;
}

input[type=range]::-moz-range-thumb {
  -moz-appearance: none;
  border: 1px solid;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  max-width: 80px;
  position: relative;
  bottom: 11px;
  background-color: #6435f6;
  cursor: -moz-grab;
  -moz-transition: border 1000ms ease;
  transition: border 1000ms ease;
}