@font-face {
  font-family: 'Avenir Next';
  src: url('../../fonts/avenir/AvenirNext-Heavy.woff2') format('woff2'),
    url('../../fonts/avenir/AvenirNext-Heavy.woff') format('woff'),
    url('../../fonts/avenir/AvenirNext-Heavy.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('../../fonts/avenir/AvenirNext-Italic.woff2') format('woff2'),
    url('../../fonts/avenir/AvenirNext-Italic.woff') format('woff'),
    url('../../fonts/avenir/AvenirNext-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('../../fonts/avenir/AvenirNext-Regular.woff2') format('woff2'),
    url('../../fonts/avenir/AvenirNext-Regular.woff') format('woff'),
    url('../../fonts/avenir/AvenirNext-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next Demi';
  src: url('../../fonts/avenir/AvenirNext-DemiBoldItalic.woff2') format('woff2'),
    url('../../fonts/avenir/AvenirNext-DemiBoldItalic.woff') format('woff'),
    url('../../fonts/avenir/AvenirNext-DemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('../../fonts/avenir/AvenirNext-DemiBold.woff2') format('woff2'),
    url('../../fonts/avenir/AvenirNext-DemiBold.woff') format('woff'),
    url('../../fonts/avenir/AvenirNext-DemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next Ultra';
  src: url('../../fonts/avenir/AvenirNext-UltraLightItalic.woff2') format('woff2'),
    url('../../fonts/avenir/AvenirNext-UltraLightItalic.woff') format('woff'),
    url('../../fonts/avenir/AvenirNext-UltraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('../../fonts/avenir/AvenirNext-HeavyItalic.woff2') format('woff2'),
    url('../../fonts/avenir/AvenirNext-HeavyItalic.woff') format('woff'),
    url('../../fonts/avenir/AvenirNext-HeavyItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('../../fonts/avenir/AvenirNext-BoldItalic.woff2') format('woff2'),
    url('../../fonts/avenir/AvenirNext-BoldItalic.woff') format('woff'),
    url('../../fonts/avenir/AvenirNext-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('../../fonts/avenir/AvenirNext-Medium.woff2') format('woff2'),
    url('../../fonts/avenir/AvenirNext-Medium.woff') format('woff'),
    url('../../fonts/avenir/AvenirNext-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next Ultra';
  src: url('../../fonts/avenir/AvenirNext-UltraLight.woff2') format('woff2'),
    url('../../fonts/avenir/AvenirNext-UltraLight.woff') format('woff'),
    url('../../fonts/avenir/AvenirNext-UltraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('../../fonts/avenir/AvenirNext-BoldItalic_1.woff2') format('woff2'),
    url('../../fonts/avenir/AvenirNext-BoldItalic_1.woff') format('woff'),
    url('../../fonts/avenir/AvenirNext-BoldItalic_1.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('../../fonts/avenir/AvenirNext-MediumItalic.woff2') format('woff2'),
    url('../../fonts/avenir/AvenirNext-MediumItalic.woff') format('woff'),
    url('../../fonts/avenir/AvenirNext-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('../../fonts/avenir/AvenirNext-Bold.woff2') format('woff2'),
    url('../../fonts/avenir/AvenirNext-Bold.woff') format('woff'),
    url('../../fonts/avenir/AvenirNext-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next Ultra';
  src: url('../../fonts/avenir/AvenirNext-UltraLightItalic_1.woff2') format('woff2'),
    url('../../fonts/avenir/AvenirNext-UltraLightItalic_1.woff') format('woff'),
    url('../../fonts/avenir/AvenirNext-UltraLightItalic_1.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir Next Demi';
  src: url('../../fonts/avenir/AvenirNext-DemiBold_1.woff2') format('woff2'),
    url('../../fonts/avenir/AvenirNext-DemiBold_1.woff') format('woff'),
    url('../../fonts/avenir/AvenirNext-DemiBold_1.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('../../fonts/avenir/AvenirNext-HeavyItalic_1.woff2') format('woff2'),
    url('../../fonts/avenir/AvenirNext-HeavyItalic_1.woff') format('woff'),
    url('../../fonts/avenir/AvenirNext-HeavyItalic_1.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('../../fonts/avenir/AvenirNext-Regular_1.woff2') format('woff2'),
    url('../../fonts/avenir/AvenirNext-Regular_1.woff') format('woff'),
    url('../../fonts/avenir/AvenirNext-Regular_1.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('../../fonts/avenir/AvenirNext-Medium_1.woff2') format('woff2'),
    url('../../fonts/avenir/AvenirNext-Medium_1.woff') format('woff'),
    url('../../fonts/avenir/AvenirNext-Medium_1.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('../../fonts/avenir/AvenirNext-Bold_1.woff2') format('woff2'),
    url('../../fonts/avenir/AvenirNext-Bold_1.woff') format('woff'),
    url('../../fonts/avenir/AvenirNext-Bold_1.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next Ultra';
  src: url('../../fonts/avenir/AvenirNext-UltraLight_1.woff2') format('woff2'),
    url('../../fonts/avenir/AvenirNext-UltraLight_1.woff') format('woff'),
    url('../../fonts/avenir/AvenirNext-UltraLight_1.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('../../fonts/avenir/AvenirNext-MediumItalic_1.woff2') format('woff2'),
    url('../../fonts/avenir/AvenirNext-MediumItalic_1.woff') format('woff'),
    url('../../fonts/avenir/AvenirNext-MediumItalic_1.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('../../fonts/avenir/AvenirNext-Heavy_1.woff2') format('woff2'),
    url('../../fonts/avenir/AvenirNext-Heavy_1.woff') format('woff'),
    url('../../fonts/avenir/AvenirNext-Heavy_1.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('../../fonts/avenir/AvenirNext-Italic_1.woff2') format('woff2'),
    url('../../fonts/avenir/AvenirNext-Italic_1.woff') format('woff'),
    url('../../fonts/avenir/AvenirNext-Italic_1.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir Next Demi';
  src: url('../../fonts/avenir/AvenirNext-DemiBoldItalic_1.woff2') format('woff2'),
    url('../../fonts/avenir/AvenirNext-DemiBoldItalic_1.woff') format('woff'),
    url('../../fonts/avenir/AvenirNext-DemiBoldItalic_1.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}


.f-avn-m { font-family: 'Avenir Next'; font-weight: 500; }
.f-avn-r { font-family: 'Avenir Next'; font-weight: normal; }
