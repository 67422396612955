.user {
	background-color: rgba(3, 15, 32, .77);
	font-size: 0;
	height: 50px;
	position: relative;
	z-index: 1;
	padding-left: 5px;

	figure {
		white-space: nowrap;
		line-height: 50px;
	}

	&__logo {
		width: 100%;
		max-width: 40px;
	}

	dropdown {
		display: block;
		line-height: 1;
	}

	figcaption {
		padding-left: 20px;
		padding-right: 100px;
	}

	.dropdown__item, .dropdown__selected {
		font-size: 13px;
		line-height: 13px;
	}
}