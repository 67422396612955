.menu-btn {
    width: 24px;
    height: 16px;
    position: relative;
    z-index: 1;
    transform: rotate(0deg);
    border: 10px solid transparent;
    box-sizing: content-box;
    cursor: pointer;
    margin: 4px 0;

    span, &:before, &:after {
        background-color: @dark-blue;
        display: block;
        content: '';
        position: absolute;
        height: 2px;
        width: 100%;
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        transition: @duration @easing;
    }

    &:before, &:after {
        top: ~'calc(50% - 1px)';
    }

    span {
        &:first-child {
            top: 0px;
        }
        &:last-child {
            top: ~'calc(100% - 2px)';
        }
    }

    &.is-active {
        span {
            top: ~'calc(50% - 1px)';
            width: 0%;
            left: 50%;
            opacity: 0;
        }

        &:before {
            transform: rotate(-45deg);
        }
        &:after {
            transform: rotate(45deg);
        }

        span, &:before, &:after {
            background-color: @active-blue;
        }
    }


    &:hover {
        span, &:before, &:after {
            background-color: @active-blue;
        }
    }
}