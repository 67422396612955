.score {
	svg {
		width: 100%;
		max-width: 100%;
	}

	&__wrapper {
		position: absolute;
		top: 0;
		left: 0;
		padding: 25px;
		width: 100%;
		height: 100%;

		&:after {
			display: inline-block;
			content: '';
			position: relative;
			height: 100%;
			vertical-align: middle;
		}
	}

	&__content {
		font-size: 0;
		max-width: 100%;
		width: 100%;
		padding: 0 3px;
	}

	&__item {
		//display: block;
		font-size: 16px;
		max-width: 100%;
		line-height: 1;

		&:first-child:not(:last-child) {
			padding-right: 7px;
			//margin-bottom: 10px;

			/*.score__value, 
			.score__value-reveal {
				font-size: 45px;
				font-weight: 600;
			}*/
		}

		&:not(:first-child) {
			border-left: 1px solid #c2c2c2;
			padding-left: 7px;
		}
	}

	&__label-wrapper {
		color: currentcolor;
		position: relative;
		
		&:before {
			content: '';
			margin-right: 4px;
			display: inline-block;
			width: 8px;
			height: 8px;
			background-color: currentcolor;
			border-radius: 2px;
			vertical-align: top;
			position: relative;
			top: 2px;
			box-shadow: 0 0 3px;
			transition: color .25s @easing;
		}
	}
	
	&__label {
		font-size: 10px;
		line-height: 1.2;
		display: inline;
		color: #fff;
		vertical-align: top;
		padding-bottom: 5px;
		letter-spacing: 1px;
	}

	&__value {
		color: #fff;
		font-size: 35px;
		line-height: 1;
		max-width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		position: relative;

		&-reveal {
			display: inline-block;
			content: attr(data-full-val);
			font-size: 14px;
			line-height: 20px;
			margin-top: -11px;
			padding: 0 5px;
			min-width: 100%;
			position: absolute;
			left: 50%;
			top: 50%;
			text-align: center;
			background-color: @dark-blue;
			border-radius: 3px;
			transform: translateX(-50%);
			opacity: 0;
			transition: opacity @duration @easing;
			color: #fff;

			&:hover {
				//background: #071224;
				opacity: 1;
			}
		}
	}

	&__progress {
		width: 34px;
		display: inline-block;
		line-height: 32px;
		font-size: 8px;
		text-align: center;
		color: @active-green;
		border: 2px solid currentcolor;
		border-radius: 50%;
		box-shadow: 0 0 3px;
		font-weight: 600;

		&--red {
			color: #b7185f;
		}

		&--big {
			font-size: 10px;
			width: 44px;
			line-height: 42px;
		}
	}
}
