.radar-glow-circle {
	display: none !important;
}

/*=============================
=            #star            =
=============================*/
.galaxy {
	transition: opacity @duration @easing;

	text {
		pointer-events: none;
		text-transform: uppercase;
		letter-spacing: 0.7px;
	}

	&--hidden {
		opacity: 0;
		z-index: 1;
	}

	&-tip-trigger {
		display: none;
		position: fixed;
		pointer-events: none;
	}
}
@hoverColor: #08b1ff;
.star {
	&__dummy {
		pointer-events: all;
		opacity: 0;
		transition: opacity .25s ease;
	}
	
	&.is-preview {
		.star__dummy {
			stroke-width: 10px;
		}

		&:hover .star__dummy,
		&.is-focused .star__dummy {
			opacity: 1;
		}
	}

	&__arc {

		&:not(.is-preview) {
			transition: fill 0.25s ease;
		}

		fill: #1a3356;
	}

	&__label {
		fill: #98ADCD;
		transition: fill 0.25s ease;
		user-select: none;
	}

	&__triangle  {
		transition: fill-opacity 0.25s ease;
		fill-opacity: 0;
		fill: currentcolor;
	}

	&__slice:not(.is-preview):hover, &__slice:not(.is-preview).is-focused {

		.star__arc {
			fill: currentcolor;
		}

		.star__triangle {
			fill-opacity: 1;
		}

		.star__label {
			fill: #fff;
		}
	}

	&-guide {
		pointer-events: none;
		fill: none;
	}
}


/*=====  End of #star  ======*/



/*================================
=            Star tip            =
================================*/

.star-tip {
	opacity: 0;
	pointer-events: none;

	&__text {
		fill: #fff;
		font-size: 14px;
		line-height: 14px;

		&--dummy {
			display: inline-block;
			visibility: hidden;
			position: fixed;
			z-index: -999;
			pointer-events: none;
		}
	}

	&__container {
		stroke-width: 1px;
	}
}

/*=====  End of Star tip  ======*/



/*==================================
=            Star label            =
==================================*/

.star-label {

	&__text, &-dummy {
		fill: #0b9ce2;
		font-size: 12px;
		line-height: 12px;
		text-transform: uppercase;
		direction: inline-block;
	}

	&-dummy {
		visibility: hidden;
		position: absolute;
		z-index: -999;
		top: 0;
		left: 0;
	}

	&__rect {
		fill: #071c33;
		stroke: #213c67;
		stroke-width: 1px; 
	}

	&__circle {
		stroke: #0b9ce2;
		stroke-width: 1px;
		fill: none;
	}

	&__qa {
		fill: #0b9ce2;
		font-size: 14px;
		line-height: 14;
		text-anchor: middle;
	}

	@media screen and (max-width: @s2) {
		display: none !important;
	}
}

/*=====  End of Star label  ======*/




.js-gallaxy {
	position: fixed;
	z-index: -1;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}