.shared {
	&__container {
		width: 100%;
		max-width: 1030px;
		margin: 0 auto;
		position: relative;
	}

	&__header {
		padding-top: 70px;
		padding-bottom: 100px;
	}

	&--fluid {
		.shared__container {
			max-width: none;
			padding: 0 40px;
		}

		.row {
			max-width: none;
		}
	}

	// business health page
	&__bi-health {

		.shared__header {
			padding-top: 30px;
			padding-bottom: 50px;

			&-content:before {
				color: @active-green;
			}
		}
	}
}


@import '_quick-wins.less';
@import '_milestones.less';
@import '_resources.less';
@import '_bi-health.less';


/*----------  Style list scroll  ----------*/
.shared-list-container {
	.ps-scrollbar-y-rail {
		opacity: 0.6;
	}
}
